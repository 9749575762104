import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Grid, MenuItem } from '@mui/material';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import PlayerFilter from '../players-management/player-filter';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import { useCookies } from 'react-cookie';
import { useParams, useSearchParams } from 'react-router-dom';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import Checkbox from '@mui/material/Checkbox';



const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from
          to
        }
        success
      }
    }
  `

const CREATE_EXISTING_PLAYER_QUERY = gql`mutation AddExistingPlayer($playerId: [Int]!, $requestEventId: Int) {
    addExistingPlayer(playerId: $playerId, requestEventId: $requestEventId) {
      errors
      success
      message
    }
  }`



type ChildRef = {
    refetchData: (data: any) => void;
};



function ExistingPlayersList({  handleEventUsers,requestId, onSave, onClose}: any) {

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const childRef = useRef<ChildRef | null>(null);
    const [filterData, setFilterData]: any = React.useState({});
    const [requestEvents, setRequestEvents] = useState<any>([]);
    const [selectedSeason, setSelectedSeason]: any = React.useState(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [initialised, setInitialised] = useState(false);
    const [eventPlayer, setEventPlayer] = useState<any>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [eventEvaluator, setEventEvaluator] = useState<any>([]);
    const [addPlayer] = useMutation(CREATE_EXISTING_PLAYER_QUERY);
    const pageMeta = useContext(PageMetaContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { refetch: latesteventfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });


    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Submitted Players");
        }
    }, [pageMeta]);


    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText,
            requestEventId: filterData?.requestEventId,
            associationId: filterData ? filterData.associationId : null,
            isSubmitted: true
        });
    };


    useEffect(() => {
        getLatestEvents();

    }, [])

    const getLatestEvents = async () => {
        const { data } = await latesteventfetch({
            search: "",
            page: null,
            pageSize: null
        });

        let reqId: any = null;

        if (data?.getRequestEvents.requestEvents.length > 0) {
            reqId = data?.getRequestEvents.requestEvents[0].id;
            setSelectedSeason(data?.getRequestEvents?.requestEvents[0]);
            setRequestEvents(data?.getRequestEvents.requestEvents);
        }


        let club = searchParams.get("club");
        let association = searchParams.get("association");
        setTimeout(() => {
            
            setFilterData({
                requestEventId: reqId,
                clubId: club,
                associationId: association
            });
            setInitialised(true)
        }, 1000)

        return data;
    }


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {

            setPage(1)
        }
        setSearchText(event.target.value)
    };


    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)


    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            let reqId = filters.filters["requestEventId"] ?? "";

            setFilterData(filters.filters);
            let selectedSeason = requestEvents.filter((event: any) => event['id'].toString() == reqId.toString());
            if (selectedSeason.length > 0) {
                setSelectedSeason(selectedSeason[0]);
            }
        }
    };



    const handleEventEvalator = (user: any, checked: boolean) => {
        const isSelected = eventPlayer.includes(user.id);

        if (checked) {
            setEventEvaluator([...eventEvaluator, user]);
        } else {
            setEventEvaluator(eventEvaluator.filter((id: any) => id !== user));
        }

    }


    const handleSubmit = async () => {

        let hasError = false;

        try {

            const variables = {
                playerId: eventEvaluator.map((player: any) => parseInt(player.id)),
                requestEventId: parseInt(requestId),

            };

            const { data } = await addPlayer({
                variables: variables
            });

            if (data.addExistingPlayer.errors?.length > 0) {
                console.error('Error:', data.addExistingPlayer.errors);
                const errorIndex = data.addExistingPlayer.errors.length-1
                enqueueSnackbar(data.addExistingPlayer.errors[errorIndex], { variant: "error" });

            } else {
                enqueueSnackbar(t("Player added successfully"), { variant: "success" });
                onSave();
            }

        } catch (error) {
            console.error('Mutation error:', error);
        }
    }

    const ActionBarConfig: WidgetProps[] = [
        {
            type: "filter-menu",
            children: <PlayerFilter filterData={filterData} onFilterChange={handleFilterChange} />,
            sx: {
                order: 1
            }
        },
        {
            type: "search-input",
            placeholder: "Search",
            onChange: onSearchTextChange,
            responsive: {
                xs: 12,
                sm: "auto"
            },
            sx: {
                order: {
                    xs: 3,
                    sm: 2
                }
            }
        },


    ]



    const TableConfig: TableColumn[] =
        [
            {
                label: "Select",
                align: "left",
                accessor: (row: any) => <Checkbox onChange={(event: any) => handleEventEvalator(row, event.target.checked)} checked={eventEvaluator.includes(row)} />,
                
            },

            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Date Of Birth",
                align: "left",
                accessor: (row: any) => row.dateOfBirth
            },
            {
                label: "Street",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.playerAddress} minWidth={300} lineClamp={2} />
            },
            {
                label: "Post Code",
                align: "left",
                accessor: (row: any) => row.postCode
            },
            {
                label: "City",
                align: "left",
                accessor: (row: any) => row.city
            },
            {
                label: "Contact Person Name",
                align: "left",
                accessor: (row: any) => row.contactPersonName
            },
            {
                label: "Relationship",
                align: "left",
                accessor: (row: any) => t(row.relationship)
            },
            {
                label: "Telephone",
                align: "left",
                accessor: (row: any) => row.telephone
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row.emailAddressImpFatherOrMother
            },
            {
                label: "Nationality",
                align: "left",
                accessor: (row: any) => row.nationality
            },
            {
                label: "Association",
                align: "left",
                accessor: (row: any) => row.Association?.associationName
            },
            {
                label: "Club",
                align: "left",
                accessor: (row: any) => row.Club?.clubName
            },
            {
                label: "Strong Foot",
                align: "left",
                accessor: (row: any) => t(row.strongFoot)
            },
            {
                label: "Goal Keeper",
                align: "left",
                accessor: (row: any) => t(row.goalKeeper)
            }
        ];




    const QUERY = `query GetPlayerList($clubId: ID, $requestEventId: ID, $associationId: ID, $isSubmitted: Boolean, $search: String, $page: Int, $pageSize: Int) {
            getPlayerList(clubId: $clubId, requestEventId: $requestEventId, associationId: $associationId, isSubmitted: $isSubmitted, search: $search, page: $page, pageSize: $pageSize) {
              errors
              players {
                Association {
                    associationName
                    id
                }
                Club {
                    clubName
                    id
                }
                RequestEvent {
                    from
                    id
                    ownerId
                    to
                  }
                contactPersonName
                RequestEventId
                createdAt
                dateOfBirth
                emailAddressImpFatherOrMother
                lastName
                goalKeeper
                id
                nationality
                playerAddress
                firstName
                postCode
                city
                relationship
                strongFoot
                telephone
                updatedAt
              }
              success
              totalPlayers
            }
          }
          `



    if (!filterData.requestEventId && !initialised) {
        return <div></div>
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <div>
            <TablePageHoc actions={ActionBarConfig} columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY}

                params={{
                    search: searchText,
                    requestEventId:filterData?.requestEventId,
                    associationId: filterData ? filterData.associationId : null,
                    isSubmitted: true

                }}
                sourceAccessor={(data) => data.getPlayerList.players}
                countAccessor={(data) => data?.getPlayerList?.totalPlayers}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />

            <Divider sx={{ mx: -24, mb: 20, mt: 20 }} />
            <Grid container justifyContent={"flex-end"}>
                <Grid item xs={"auto"}>
                    <Button type="button" variant="text" color="secondary" onClick={handleClose}>{t('Cancel')}</Button>
                </Grid>
                <Grid item xs={"auto"}>
                    {/* {editMode ? <Button type="submit" variant="contained" color="primary">{t('Save')}</Button> : <Button type="submit" variant="contained" color="primary">{t('Next')}</Button>} */}
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} >{t('Save')}</Button>
                </Grid>
            </Grid>


        </div>
    )
}

export default ExistingPlayersList