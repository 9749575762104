import { TextFieldProps, TextFieldVariants, TextField as TF } from '@mui/material'
import { MuiTelInput, MuiTelInputProps, matchIsValidTel } from 'mui-tel-input';
import React, { useEffect, useState, useRef } from 'react'






export default function MuiTelephoneInput<Variant extends TextFieldVariants>(props: {
    variant?: Variant;
    readonly?: boolean;
    onChange?: any
} & Omit<MuiTelInputProps, 'variant'>) {



    const { ...TFProps } = props



    const overrideOnChange = (event: any, value: any) => {

        if (props.onChange) {
            props.onChange({
                target: {
                    value: event
                }
            })
        }
    }

    const overrideKeyDown = (event: any) => {
        let value = (event?.target?.value ?? "");
        if(value){
            value = value.trim();
        }

        if (value === "" && event?.code === "Space") {
            event.preventDefault();
            return;
        }

        if (props.onKeyDown) {
            props.onKeyDown(event)
        }
    }



    return (
        <MuiTelInput  {...{ ...TFProps, onChange: overrideOnChange, onKeyDown: overrideKeyDown, autoComplete: 'off', error: props.error ?? false, helperText: props.helperText ?? " " }} onlyCountries={['CH', 'DE']} defaultCountry={'CH'} />
    )
}
