import React, { useState, useEffect, useRef } from 'react'
import { FormArray, FormField, FormGroup, FormGroupRef } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Divider, Grid, IconButton, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import EventSlotGeneration from './add-event-slot';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import dayjs from 'dayjs';
import 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';



const CREATE_EVENT_QUERY = gql`mutation Mutation($title: String!, $eventLayoutId: Int!, $createdUserId: Int!, $slots: [SOCCER_SlotRequest]!, $createEventId: ID) {
    createEvent(title: $title, eventLayoutId: $eventLayoutId, createdUserId: $createdUserId, slots: $slots, id: $createEventId) {
      success
      errors
      event {
        title
      }
    }
  }
  
  `


const GET_EVENT_LAYOUT = gql`query Query($search: String, $page: Int, $pageSize: Int, $layoutId: Int) {
    getAllEventLayouts(search: $search, page: $page, pageSize: $pageSize, layoutId: $layoutId) {
      errors
      success
      totalLayouts
      layouts {
        title
        id
      }
    }
  }`




const GET_EVENT_DETAILS = gql`
query GetEventDetail($eventId: ID) {
    getEventDetail(eventId: $eventId) {
      success
      event {
        title
        id
        User {
          firstName
          lastName
        }
        eventSlots {
          title
          startTime
          location
          id
          endTime
        }
      }
      errors
    }
  }`


const DELETE_EVENT_SLOT_QUERY = gql`mutation Mutation($deleteOneEventSlotId: ID!) {
    deleteOneEventSlot(id: $deleteOneEventSlotId) {
      success
      errors
    }
  }`


function EventGeneration({ event, onSave, onClose, handleNext, handleRefetch }: any) {

    const [createEvent] = useMutation(CREATE_EVENT_QUERY);
    const [layoutData, setLayoutData] = useState<any>(null);
    const [value, setValue] = React.useState(0)
    const { refetch: layoutRefetch } = useQuery(GET_EVENT_LAYOUT, { skip: true });
    const { refetch: getEventDetailsFetch } = useQuery(GET_EVENT_DETAILS, { skip: true });
    const [resetField, setResetField] = useState<Function | undefined>(undefined);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false)
    const [deleteEventSlot] = useMutation(DELETE_EVENT_SLOT_QUERY);
    const [deleteSlotId, setDeleteSlotId] = useState<any>(null);
    const [slotTitle, setSlotTitle] = useState<any>(null);
    const [slotIndex, setSlotIndex] = useState<any>(null);
    const { t } = useTranslation();
    const [tabsData, setTabsData]: any = useState([]);
    const [showCloseIcon, setShowCloseIcon] = React.useState(false);
    const [deleteRow, setDeleteRow]: any = useState();




    useEffect(() => {
        getEventLayoutList();
        if (event != null) {
            getEventDetails();
        } else {
            setTabsData([
                { label: `${t("Slot")} 1`, content: () => <EventSlotGeneration index={0} /> }
            ])
        }
    }, [event])



    const formRef = useRef<FormGroupRef>(null);

    const formArrayRef = useRef<FormGroupRef>(null);


    const getEventLayoutList = async () => {
        const { data } = await layoutRefetch({
            search: "",
            page: null,
            pageSize: null,

        });
        setLayoutData(data);
        return data;
    }




    const getEventDetails = async () => {
        const { data } = await getEventDetailsFetch({
            eventId: event?.eventId
        });


        if (data?.getEventDetail?.event?.eventSlots) {
            const existingSlots = data?.getEventDetail?.event?.eventSlots?.map((slot: any, index: number) => ({
                label: `${t("Slot")} ${index + 1}`,
                content: () => <EventSlotGeneration index={index} slot={slot} />
            }));

            setTabsData(existingSlots);
        }

        return data;
    }



    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const { enqueueSnackbar } = useSnackbar();

    const handleAddTab = () => {

        const formData = formRef?.current?.getValues();



        if (formData && formData.slots) {
            const hasEmptySlot = formData.slots.some((slot: any) => {

                return Object.keys(slot).some(key => key !== 'id' && !slot[key]);
            });

        

            if (hasEmptySlot) {

                enqueueSnackbar(t("Please fill out all the fields in the existing slots before adding a new one."), { variant: "error" });
                return;
            }
        }

        const newTab = {
            label: `${t("Slot")} ${tabsData.length + 1}`,
            content: () => <EventSlotGeneration index={tabsData.length} />
        };

        setTabsData([...tabsData, newTab]);

        setTimeout(() => {
            setValue(tabsData.length)
        }, 10)

    };


    const handleTabDeleteAtIndex = (index: number) => {
        if (value >= index) {
            setValue((currentValue) => {
                if (currentValue === 0 && tabsData.length > 1) {
                    return 0;
                } else if (currentValue > 0) {
                    return currentValue - 1;
                }
                return 0;
            });
        }

        formArrayRef?.current?.removeItem?.(index.toString());
        const dataTabs = [...tabsData.filter((_: any, i: number) => i !== index)];
        setTabsData(dataTabs.map((tab: any, index: number) => ({
            label: `Slot ${index + 1}`,
            content: tab.content
        })));
    }

    const deleteSlot = (currentSlot: any, index: number) => {
        const variables = {
            deleteOneEventSlotId: currentSlot['id']
        }
        return async () => {
            const { data } = await deleteEventSlot({
                variables: variables
            });


            if (data.deleteOneEventSlot?.errors?.length > 0) {
                enqueueSnackbar(data.deleteOneEventSlot.errors[0], { variant: "error" });
            } else {
                handleTabDeleteAtIndex(index);
                enqueueSnackbar(t("Event slot deleted successfully"), { variant: "success" });
            }
        }
    }

    const handleDeleteTab = async (indexToDelete: any) => {

        // formRef?.current?.getValues()

        const formArrayData = formArrayRef?.current?.getValues();
        const currentItem = formArrayData?.[indexToDelete];

        if (currentItem?.['id']) {
            setDeleteRow(deleteSlot(currentItem, indexToDelete));
            setSlotTitle(currentItem?.['slotTitle'])
        } else {
            handleTabDeleteAtIndex(indexToDelete);
        }

    }




    const handleSubmit = async (data: any, callback: Function) => {

        const formData: any = formRef?.current?.getValues();



        if (formData && formData.slots) {
            const hasEmptySlot = formData.slots.some((slot: any) => {
                return Object.values(slot).some(value => !value);
            });


            const isValidSlot = (newSlot: any, existingSlot: any) => {
                const newStartTime = newSlot.startTime.$d;
                const newEndTime = newSlot.endTime.$d;
                const existingStartTime = existingSlot.startTime.$d;
                const existingEndTime = existingSlot.endTime.$d;

                
                if (
                    (newStartTime >= existingStartTime && newStartTime < existingEndTime) ||
                    (newEndTime > existingStartTime && newEndTime <= existingEndTime)
                ) {
                    return false; 
                }

                
                if (newStartTime <= existingStartTime && newEndTime >= existingEndTime) {
                    return false; 
                }

                return true; 
            };

            const isValidNewSlot = (newSlot: any, existingSlots: any) => {
                return !existingSlots.some((existingSlot: any) => !isValidSlot(newSlot, existingSlot));
            };

            
            for (let i = 0; i < formData.slots.length; i++) {
                const newSlot = formData.slots[i];

            
                const isValid = isValidNewSlot(newSlot, formData.slots.slice(0, i));
                if (!isValid) {
                    enqueueSnackbar(t("A slot with the same timing has already been fixed. Please create the slot in another time slot."), { variant: "error" });
                    return;
                }


            }






            const duplicateSlotTitle = formData.slots.some((slot: any, index: number) => {
                for (let i = index + 1; i < formData.slots.length; i++) {
                    const nextSlot = formData.slots[i];
                    if (slot.slotTitle === nextSlot.slotTitle) {
                        return true; // Duplicate slot title found
                    }
                }
                return false;
            });

        

            const invalidStartTime = formData.slots.some((slot: any) => {
                if (slot) {

                    if (dayjs(slot.startTime).isBefore(dayjs(new Date()))) {
                        return true;
                    }
                }
                return false;
            });


            const invalidEndTime = formData.slots.some((slot: any) => {
                if (slot) {

                    if (dayjs(slot.endTime).isBefore(dayjs(slot.startTime))) {
                        return true;
                    }
                }
                return false;
            });

            if (formData && formData.slots) {
                const hasEmptySlot = formData.slots.some((slot: any) => {

                    return Object.keys(slot).some(key => key !== 'id' && !slot[key]);
                });

            

                if (hasEmptySlot) {

                    enqueueSnackbar(t("Please fill out all the fields in the existing slots before submitting."), { variant: "error" });
                    return;
                }
            }

            if (invalidStartTime) {
                enqueueSnackbar(t("The event start time cannot be in the past. Please select an appropriate start time."), { variant: "error" });
                return;
            }

            if (invalidEndTime) {
                enqueueSnackbar(t("The event end time cannot be before the start time. Please select an appropriate end time."), { variant: "error" });
                return;
            }



            if (duplicateSlotTitle) {
                enqueueSnackbar(t("Please provide another slot title , as it is been already assigned for the slot"), { variant: "error" });
                return;
            }




            let createdEvent: any;

            const variables = {
                createEventId: data.id,
                title: data.title,
                eventLayoutId: parseInt(data.eventLayoutId),
                createdUserId: decodedTokenData?.id,
                slots: data.slots.map((slot: any) => ({
                    ...slot,
                    startTime: dayjs(slot.startTime).format('YYYY-MM-DD HH:mm:ss Z'),
                    endTime: dayjs(slot.endTime).format('YYYY-MM-DD HH:mm:ss Z')
                }))
            }

            try {
                const { data } = await createEvent({
                    variables: variables
                });
                if (data.createEvent.errors?.length > 0) {
                    console.error('Error:', data.createEvent.errors);
                    const errorIndex = data.createEvent.errors?.length - 1
                    enqueueSnackbar(data.createEvent.errors[errorIndex], { variant: "error" });
                }
                else {

                    enqueueSnackbar(t("Event created successfully"), { variant: "success" });
                    onSave();

                }

            } catch (error) {
                console.error('Mutation error:', error);
            }

        }
    };


    const onConfirmrModalClose = () => {
        setDeleteConfirmModal(false)
    }

    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)} ref={formRef}>
                <Grid container spacing={12}>


                    <Grid item xs={12} sm={12}>
                        <FormField name='id' value={event?.eventId ?? undefined} validateOnChange={false}><></></FormField>
                        <FormField name='title' value={event?.title ?? ""} validator={(value: string) => {
                            if (!value) return "Event title is required."
                            return null
                        }} validateOnChange={false}>
                            <TextField id="title"
                                label={t("Event Name")}
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}

                            />
                        </FormField>
                    </Grid>

                    <Grid item xs={12} >
                        <FormField name='eventLayoutId' value={event?.layoutId ?? ""} validator={(value: string, data) => {
                            if (!value) return "Event layout selection is required.";
                            return null
                        }} validateOnChange={false}>
                            <TextField id="eventLayoutId"
                                label={t("Layout type")}
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}

                                onChange={(e: any) => {

                                }}
                            >
                                {layoutData ? layoutData?.getAllEventLayouts?.layouts?.map((layout: any) => (
                                    <MenuItem key={"layout" + layout.id} value={layout.id} >
                                        {layout.title}
                                    </MenuItem>
                                )) : <MenuItem></MenuItem>}


                            </TextField>
                        </FormField>
                    </Grid>

                    <Grid item sm={12}>
                        <Divider sx={{ mx: -24, mb: 20 }} />
                    </Grid>


                    <Grid item sm={8}>

                        <Typography component="p" sx={{ 'fontSize': '16px', 'marginBottom': '0px', 'textTransform': 'uppercase', 'paddingTop': '9px' }}>
                            {t("slots")}
                        </Typography>
                    </Grid>

                    <Grid item sm={4} textAlign={"right"} marginLeft={"auto"}>
                        <Button variant="contained" color="primary" onClick={handleAddTab}>{t('Add')}</Button>
                    </Grid>

                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="aiotrix tabs hoc" variant='scrollable'>
                                {tabsData.map((tab: any, index: number) => (
                                    // <Tab label={t(tab.label)} />
                                    <Tab key={'tab' + index} label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {t(tab.label)}
                                        {
                                            tabsData.length > 1 && value == index && <IconButton
                                                onClick={() => handleDeleteTab(index)} // Assuming you have a function to handle tab deletion
                                                size="small"
                                                aria-label="close"
                                            >
                                                <HighlightOffOutlinedIcon sx={{ height: '25px', width: '25px', marginLeft: '3px' }} />
                                            </IconButton>
                                        }
                                    </Box>} />
                                ))}
                            </Tabs>
                        </Box>
                        <FormArray name='slots' ref={formArrayRef}>
                            {
                                tabsData.map((tab: any, index: number) => (
                                    <div key={`slots${index}`} hidden={value != index}>
                                        {tab.content()}
                                    </div>
                                ))
                            }
                        </FormArray>
                    </Box>

                    <Divider sx={{ mx: -24, mb: 20 }} />

                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={"auto"}>
                            <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                        </Grid>
                        <Grid item xs={"auto"}>
                            <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </FormGroup>
            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => onConfirmrModalClose()} onConfirm={() => deleteRow(deleteSlotId)} title={t('Are you sure?')} children={`${t('Do you want to delete the event slot')} ${slotTitle}? ${t('Once deleted, this action cannot be undone!')}`} />
        </div >
    )
}

export default EventGeneration