import { createSlice } from '@reduxjs/toolkit'
import { uid } from 'uid'

export const App = createSlice({
  name: 'App',
  initialState: {
    applicationLoading: true,
    pageLoading: true,
    sidebarOpen: window.innerWidth > 576,
    theme: 'dark',
    toasts:[],
  } as any,
  reducers: {
    openSidebar: (state) => {
      return {
        ...state,
        sidebarOpen: true
      }
    },
    closeSidebar: (state) => {
      return {
        ...state,
        sidebarOpen: false
      }
    },
    toggleSidebar: (state) => {
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      }
    },
    setTheme: (state, action) => {
      return {
        ...state,
        theme: action.payload
      }
    },

    stopAppLoading: (state) => {
      return {
        ...state,
        applicationLoading: false
      }
    },
    startAppLoading: (state) => {
      return {
        ...state,
        applicationLoading: true
      }
    },

    stopPageLoading: (state) => {
      return {
        ...state,
        pageLoading: false
      }
    },
    startPageLoading: (state) => {
      return {
        ...state,
        pageLoading: true
      }
    },
    addToast:(state,actions)=>{
      const messages:any[] = [
        ...state.toasts,
        {
          ...actions.payload,
          id:uid(),
        }
      ]
      return {
        ...state,
        toasts:messages
      }
    },
    removeToast:(state,actions)=>{
      const messages:any[] = [...state.toasts.filter((item:any)=>item.id != actions.payload.id)]
      return {
        ...state,
        toasts:messages
      }
    }

  },
})
export const { openSidebar, closeSidebar, toggleSidebar, setTheme, stopAppLoading, startAppLoading, addToast, removeToast, stopPageLoading, startPageLoading } = App.actions
export default App.reducer