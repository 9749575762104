import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import { Box, Button, ButtonGroup, Checkbox, Divider, Grid, MenuItem } from '@mui/material';
import AddAssociationPlayer from "../association-player-management/add-assnevent-players";
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import { useCookies } from 'react-cookie';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { responsiveString } from '../../../shared/utils/util-functions';
import { getWidget } from '../../../shared/widgets';
import EditPlayer from "./slot-editplayers-list";
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import PlayerEvaluation from './player-evaluation';
import SlotPlayerFilter from './slot-player-filter';
import OptionEvaluvatorList from './option-evaluators';
import SlotEditDetails from './slot-edit-details';


const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from
          to
        }
        success
      }
    }
  `

const SUBMIT_EVALUATION = gql`
mutation Mutation($slotId: Int!) {
    submitEvaluation(slotId: $slotId) {
      message
      success
      errors
    }
  }`

const GET_EVENT_SLOT_PLAYERS = gql`query GetAllSlotPlayers($search: String, $page: Int, $pageSize: Int, $slotId: Int) {
    getAllSlotPlayers(search: $search, page: $page, pageSize: $pageSize, slotId: $slotId) {
      errors
      success
      totalEventPlayers
      players {
        firstName
        slotPlayerId
        id
        lastName
        nationality
        playerAddress
        postCode
        relationship
        strongFoot
        telephone
        goalKeeper
        dateOfBirth
        associationName
      }
    }
  }
`


const GET_EVENT_DETAILS = gql`
query GetEventDetail($eventId: ID) {
    getEventDetail(eventId: $eventId) {
      success
      errors
      event {
        title
        id
        EventLayout {
          title
          id
          layoutOptions {
            optionTitle
            id
            subOptions {
              optionTitle
              id
            }
          }
        }
      }
    }
  }`;



const DELETE_SLOT_PLAYER_QUERY = gql`
mutation Mutation($deleteOneSlotPlayerId: ID!) {
    deleteOneSlotPlayer(id: $deleteOneSlotPlayerId) {
      success
      errors
    }
  }
`


const CHECK_CLUB_ADMIN = gql`
query CheckSlotEvaluator($checkSlotEvaluatorId: Int!, $slotId: Int!) {
    checkSlotEvaluator(id: $checkSlotEvaluatorId, slotId: $slotId) {
      errors
      success
      user {
        firstName
      }
    }
  }`


const CHECK_SLOT_STATUS = gql`
query Query($slotId: Int!) {
    checkEventSlot(slotId: $slotId) {
      message
      success
      errors
    }
  }`


const UPDATE_SLOT_DETAILS = gql`mutation Mutation($eventId: Int!, $slotId: Int!, $jerseyNumber: Int!, $playerId: Int!, $status: Boolean!, $slotPlayerId: Int) {
    updateSlotPlayerDetails(eventId: $eventId, slotId: $slotId, jerseyNumber: $jerseyNumber, playerId: $playerId, status: $status, slotPlayerId: $slotPlayerId) {
      success
      message
      errors
    }
  }`

const CHECK_EVENT_USER = gql`query CheckEventSlotUser($eventId: Int!) {
    checkEventSlotUser(eventId: $eventId) {
      success
      message
      errors
    }
  }`

type ChildRef = {
    refetchData: (data: any) => void;
};



function EventSlotListPlayersPage({ handleNext, handleEventUsers, event }: any) {

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const childRef = useRef<ChildRef | null>(null);
    const [filterData, setFilterData]: any = React.useState({});
    const [requestEventId, setRequestEventId] = useState(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [eventPlayer, setEventPlayer] = useState<any>([]);
    const [eventDetails, setEventDetails] = useState<any>(null);
    const [playerDetail, setPlayerDetail] = useState<any>(null);
    const [playerDetailModal, setPlayerDetailModal] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const pageMeta = useContext(PageMetaContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { refetch: latesteventfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });
    const { refetch: slotplayersfetch } = useQuery(GET_EVENT_SLOT_PLAYERS, { skip: true });
    const { refetch: getEventDetailsFetch } = useQuery(GET_EVENT_DETAILS, { skip: true });
    const { refetch: getSlotEvaluator } = useQuery(CHECK_CLUB_ADMIN, { skip: true });
    const { refetch: getSlotStatus } = useQuery(CHECK_SLOT_STATUS, { skip: true });
    const { refetch: getEventUserStatus } = useQuery(CHECK_EVENT_USER, { skip: true });
    const [updateSlotDetails] = useMutation(UPDATE_SLOT_DETAILS);
    const [eventSlotPlayers, setEventSlotPlayers] = useState<any>([]);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [optionDetailModal, setOptionDetailModal] = React.useState(false);
    const [eventAssociationPlayerListModal, setEventAssociationPlayerListModal] = React.useState(false);
    const [slotDetailModal, setSlotDetailModal] = React.useState(false);
    const [deleteOnePlayer] = useMutation(DELETE_SLOT_PLAYER_QUERY);
    const [submitEvaluation] = useMutation(SUBMIT_EVALUATION);
    const [optionId, setOptionId] = useState<any>(null);
    const [playerId, setPlayerId] = useState<any>(null);
    const [params, setParams]: any = useState({ "slotId": "", "eventId": "" })
    const [clubAdminAssigned, setClubAdminAssigned] = useState<any>(false);
    const [submitConfirmModal, setSubmitConfirmModal] = React.useState(false);
    const [slotStatus, setSlotStatus] = React.useState(null);
    const [adminAssigned, setAdminAssigned] = React.useState(null);




    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName(`Slot Players`);
        }
    }, [pageMeta]);

    useEffect(() => {

        setParams({ "slotId": searchParams.get('slotId') ?? "", "eventId": searchParams.get('eventId') ?? "" })
        const slotId = searchParams.get('slotId') ?? "";
        const eventId = searchParams.get('eventId') ?? "";

        if (slotId !== "") {
            getPlayersData(slotId);
        }


        getLatestEvents();

    }, [])


    useEffect(() => {
        checkSlotEvaluator();
        checkSlotResponse();
        checkEventUser();
    }, [params])



    useUpdateEffect(() => {

        getEventDetails();
        handleRefetch();

    }, [params])



    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText])





    const getEventDetails = async () => {

        const { data } = await getEventDetailsFetch({
            eventId: params['eventId']
        });
        setEventDetails(data.getEventDetail.event);
    }




    const checkSlotEvaluator = async () => {


        const { data } = await getSlotEvaluator({
            slotId: parseInt(params['slotId']),
            checkSlotEvaluatorId: parseInt(decodedTokenData.id)
        });
        setClubAdminAssigned(data?.checkSlotEvaluator?.success);

    }


    const checkSlotResponse = async () => {

        const { data } = await getSlotStatus({
            slotId: parseInt(params['slotId']),
        });

        setSlotStatus(data?.checkEventSlot?.success);

    }


    const checkEventUser = async () => {

        const { data } = await getEventUserStatus({
            eventId: parseInt(params['eventId']),
        });

        setAdminAssigned(data?.checkEventSlotUser?.success);

    }


    const getPlayersData = async (slotId: any) => {

        try {
            const { data } = await slotplayersfetch({
                search: null,
                page: null,
                pageSize: null,
                slotId: parseInt(slotId)

            });
            setEventSlotPlayers(data?.getAllSlotPlayers?.players);

            return data;
        } catch (error) {
            console.error("Error fetching player data:", error);
        }

    }



    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText,
            slotId: parseInt(params["slotId"]),
            associationId: filterData ? filterData.associationId : null,
        });
    };


    const getPlayerData = () => {
        handleEventUsers(eventPlayer)
    }




    const getLatestEvents = async () => {
        const { data } = await latesteventfetch({
            search: "",
            page: null,
            pageSize: null
        });

        let reqId: any = null;

        if (data?.getRequestEvents.requestEvents.length > 0) {
            reqId = data?.getRequestEvents.requestEvents[0].id;
            setRequestEventId(reqId);
        }
        return data;
    }





    const onSearchTextChange = (event: any) => {
        // if (searchText !== event.target.value) {

        //     setPage(1)
        // }
        setSearchText(event.target.value)
    };




    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;
        const variables = {
            deleteOneSlotPlayerId: selectedUser?.slotPlayerId
        }

        try {
            const { data } = await deleteOnePlayer({
                variables: variables
            });
            setSelectedUser(null);

            if (data.deleteOneSlotPlayer?.errors?.length > 0) {
                const deleteIndex = data.deleteOneSlotPlayer?.errors?.length
                enqueueSnackbar(data.deleteOneSlotPlayer.errors[deleteIndex - 1], { variant: "error" });
            } else {
                enqueueSnackbar(t("Player removed successfully"), { variant: "success" });
                handleRefetch()
            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }

    }

    const evaluationSubmit = async () => {
        setSubmitConfirmModal(false);

        const variables = {
            slotId: parseInt(params["slotId"])
        }

        try {
            const { data } = await submitEvaluation({
                variables: variables
            });


            if (data.submitEvaluation?.errors?.length > 0) {
                const deleteIndex = data.submitEvaluation?.errors?.length
                enqueueSnackbar(data.submitEvaluation.errors[deleteIndex - 1], { variant: "error" });
            } else {
                enqueueSnackbar(t("Player evaluation submiited successfully"), { variant: "success" });
                handleRefetch()
                navigate(`/admin/event-management`)
            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }

    }


    const onAddModalClose = () => {
        setAddModalOpen(false)
        setEventAssociationPlayerListModal(false)
        handleRefetch();
    }

    const onEvaluationModalClose = () => {
        setPlayerDetailModal(false)
        handleRefetch();
    }

    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            // setPage(1)
            setFilterData(filters.filters);
        }
    };

    const handlePlayerOption = (player: any, option: any, e: any) => {
        e.stopPropagation();
        setOptionId(option.id)
        setPlayerDetail(player)
        {
            decodedTokenData.roles !== 'Evaluator' && setOptionDetailModal(true)
        }


    }


    const handlePlayerSubOption = (player: any, so: any, e: any) => {
        e.stopPropagation();
        setOptionId(so.id)
        setPlayerDetail(player)
        {
            decodedTokenData.roles !== 'Evaluator' && setOptionDetailModal(true)
        }


    }


    const onOptionEvaluatorsModalClose = () => {
        setOptionDetailModal(false)
        setSlotDetailModal(false)
        setSelectedUser(null)
        handleRefetch()
        setPlayerDetail(null)
        setOptionId(null)
    }

    const handleEventPlayer = async (player: any, checked: boolean, event: any) => {


        event.stopPropagation();
        const variables = {
            eventId: parseInt(params["eventId"]),
            slotId: parseInt(params["slotId"]),
            playerId: player.id,
            jerseyNumber: parseInt(player.slotPlayerJerseyNumber),
            status: checked,
            slotPlayerId: player.slotPlayerId
        }


        try {
            const { data } = await updateSlotDetails({
                variables: variables
            });

            if (data.updateSlotPlayerDetails.errors?.length > 0) {
                console.error('Error:', data.updateSlotPlayerDetails.errors);
                const errorIndex = data.updateSlotPlayerDetails.errors.length
                enqueueSnackbar(data.updateSlotPlayerDetails.errors[errorIndex - 1], { variant: "error" });

            } else {

                handleRefetch();
            }
        } catch (error) {
            console.error('Mutation error:', error);

        }

    }




    const ActionBarConfig = useMemo(() => {
        const config = [

            ...(decodedTokenData?.roles !== 'Evaluator' && !slotStatus && adminAssigned ? (
                [
                    {
                        type: "button",
                        label: t(responsiveString({ xs: "Edit", sm: "Edit Players" })),
                        onClick: () => {
                            if (decodedTokenData.roles !== 'AssociationAdmin' && decodedTokenData.roles !== 'Admin') {
                                setAddModalOpen(true);
                            } else {
                                setEventAssociationPlayerListModal(true)
                            }

                        },
                        sx: {
                            order: {
                                xs: 2,
                                sm: 3
                            }
                        }
                    }
                ]

            ) : []),

            ...(decodedTokenData?.roles === 'ClubAdmin' ? (
                [
                    {
                        type: "filter-menu",
                        children: <SlotPlayerFilter filterData={filterData} onFilterChange={handleFilterChange} />,
                        sx: {
                            order: 1
                        }
                    }
                ]
            ) : []),

            {
                type: "search-input",
                placeholder: "Search",
                onChange: onSearchTextChange,
                responsive: {
                    xs: 12,
                    sm: "auto"
                },
                sx: {
                    order: {
                        xs: 3,
                        sm: 2
                    }
                }
            },
            ...(decodedTokenData?.roles === 'Evaluator' ? (
                [
                    {
                        type: "button",
                        label: "Submit",
                        variant: 'contained',
                        onClick: () => setSubmitConfirmModal(true),
                        sx: {
                            order: {
                                xs: 3,
                                sm: 4
                            }
                        }
                    }

                ]
            ) : []

            ),


        ]
        return config;
    }, [decodedTokenData, filterData, slotStatus, adminAssigned])


    const TableConfig = useMemo(() => {
        const config = [
            ...(decodedTokenData?.roles !== 'Evaluator' && !slotStatus && adminAssigned ? (
                [
                    {
                        label: "Action",
                        align: "left",
                        width: 130,
                        accessor: (row: any) => {
                            return <>
                                <ActionMenu
                                    button={{
                                        type: "icon-button",
                                        icon: <MoreVertOutlined />,
                                        size: 'small',
                                        color: 'primary',
                                        sx: { color: 'white' }
                                    }}
                                    children={
                                        [
                                            <MenuItem onClick={() => {
                                                setSelectedUser(row);
                                                setSlotDetailModal(true);
                                                // setDeleteConfirmModal(true)
                                            }}>{t('Edit')}</MenuItem>,
                                            <MenuItem onClick={() => {
                                                setSelectedUser(row);
                                                setDeleteConfirmModal(true)
                                            }}>{t('Delete')}</MenuItem>,

                                        ]
                                    }
                                />
                            </>
                        }
                    }
                ]
            ) : []),
            {
                label: "NR",
                align: "center",
                accessor: (row: any) => row?.slotPlayerJerseyNumber
            },
            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
            },
            ...(decodedTokenData?.roles !== 'Evaluator' ? (
                [

                    {
                        label: "Last Name",
                        align: "left",
                        accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
                    },

                ]
            ) : []),

            {
                label: "Date Of Birth",
                align: "left",
                accessor: (row: any) => row.dateOfBirth
            },
            ...(decodedTokenData?.roles !== 'Evaluator' ? (
                [
                    {
                        label: "Association",
                        align: "left",
                        accessor: (row: any) => row?.associationName
                    }

                ]
            ) : []),

            {
                label: "Strong Foot",
                align: "left",
                accessor: (row: any) => t(row.strongFoot)
            },
            {
                label: "Goal Keeper",
                align: "left",
                accessor: (row: any) => t(row.goalKeeper)
            },
            ...(decodedTokenData?.roles !== 'Evaluator' && !slotStatus && adminAssigned ? (
                [


                    {
                        label: "Status",
                        align: "left",
                        accessor: (row: any) => <Checkbox onChange={(event: any) => handleEventPlayer(row, event.target.checked, event)} checked={row?.slotPlayerStatus === '1'} onClick={(e: any) => e.stopPropagation()} />,

                    },

                ]
            ) : []),
            ...(decodedTokenData?.roles !== 'Evaluator' && slotStatus ? (
                [


                    {
                        label: "Status",
                        align: "left",
                        accessor: (row: any) => row?.slotPlayerStatus === '1' ? t("Available") : t("Not Available"),

                    },

                ]
            ) : []),

            {
                label: "Layout Type",
                align: "left",
                accessor: (row: any) => {
                    if (eventDetails) {
                        const evaluationSummary: { [key: string]: number } = {};
                        row.optionCounts.map((option: any) => (evaluationSummary[option.optionId] = option.count))
                       
                        let eventLayoutOptions = [...eventDetails.EventLayout.layoutOptions];
                        eventLayoutOptions.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id));
                        const buttons = eventLayoutOptions.map((option: any) => {
                            if (option.subOptions.length <= 0) {
                                return <Button key={option.id} type='button' variant='text' color="primary" size='small' onClick={(e) => handlePlayerOption(row, option, e)}>{`${option.optionTitle} : `}{evaluationSummary[option.id] ?? 0}</Button>;
                            } else {
                                return <ButtonGroup key={option.id} variant="outlined" aria-label="Basic button group" size='small'>
                                    <Button type='button' disabled variant='text' color="primary" size='small'>{`${option.optionTitle} : `}</Button>
                                    {
                                        option.subOptions.map((so: any) => {
                                            return <Button type='button' variant='text' color="primary" size='small' onClick={(e) => handlePlayerSubOption(row, so, e)}>{`${so.optionTitle} : `}{evaluationSummary[so.id] ?? 0}</Button>
                                        })
                                    }
                                </ButtonGroup>
                            }
                        })
                        if (buttons.length)
                            return <div onClick={e => e.stopPropagation()}>{buttons}</div>

                    }
                    return <></>;
                }
            },
            ...(decodedTokenData?.roles === 'Evaluator' ? (
                [

                    {
                        label: "Comment",
                        align: "left",
                        accessor: (row: any) => <EllipsisBox content={row?.optionCounts[0]?.comment} minWidth={250} lineClamp={4} />
                    }

                ]
            ) : []),

        ]
        return config
    }, [decodedTokenData, eventDetails, slotStatus, adminAssigned])





    const QUERY = `query GetAllSlotPlayers($search: String, $page: Int, $pageSize: Int, $slotId: Int, $status: Boolean, $associationId:Int) {
        getAllSlotPlayers(search: $search, page: $page, pageSize: $pageSize, slotId: $slotId, status:$status, associationId:$associationId) {
          errors
          success
          totalEventPlayers
          players {
            firstName
            slotPlayerId
            id
            lastName
            nationality
            playerAddress
            postCode
            relationship
            strongFoot
            telephone
            goalKeeper
            dateOfBirth
            associationName
            slotPlayerJerseyNumber
            slotPlayerStatus
            optionCounts: optionCounts {
                userId
                count
                optionId
                comment
            }
            selfCounts: selfCounts{
                count
                optionId
                comment
                id
            }
          }
        }
      }
      `

    if (!params["slotId"] || !eventDetails) {
        return <></>
    }


    return (
        <div>
            <TablePageHoc columns={TableConfig as TableColumn[]} actions={ActionBarConfig as WidgetProps[]} ref={childRef} query={QUERY}

                params={{
                    search: searchText,
                    slotId: parseInt(params["slotId"]),
                    associationId: filterData ? filterData.associationId : null,
                }}
                sourceAccessor={(data) => data?.getAllSlotPlayers?.players}
                countAccessor={(data) => data?.getAllSlotPlayers?.totalEventPlayers}

                detailPointer={decodedTokenData.roles === 'Evaluator' || clubAdminAssigned === true && !slotStatus ? "pointer" : undefined}
                onRowClick={decodedTokenData.roles === 'Evaluator' || clubAdminAssigned === true && !slotStatus ? (row) => {

                    if (row.slotPlayerStatus == 1) {
                        setPlayerDetail(row);
                        setPlayerDetailModal(true);
                    } else {
                        enqueueSnackbar(t("Player has not played in this slot for evaluation."), { variant: "error" });
                    }

                } : undefined}
                pagination={{
                    enable: true,
                    limit: 100,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Add Players",
                        modalOpen: addModalOpen,
                        onClose: () => onAddModalClose(),
                        children: <EditPlayer users={eventSlotPlayers} onSave={() => onAddModalClose()} onClose={onAddModalClose} requestEventId={requestEventId} slotId={parseInt(params["slotId"])} eventId={parseInt(params["eventId"])} />,
                        size: 'lg'
                    })
            }

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Add Players",
                        modalOpen: eventAssociationPlayerListModal,
                        onClose: () => onAddModalClose(),
                        children: <AddAssociationPlayer onSave={() => onAddModalClose()} onClose={onAddModalClose} slotId={parseInt(params["slotId"])} eventId={parseInt(params["eventId"])} requestEventId={requestEventId} />,
                        size: 'lg'
                    })
            }

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Evaluate Player",
                        modalOpen: playerDetailModal,
                        onClose: () => onEvaluationModalClose(),
                        children: <PlayerEvaluation player={playerDetail} onSave={() => onEvaluationModalClose()} onClose={onEvaluationModalClose} eventDetails={eventDetails} />,
                        size: 'md'
                    })
            }

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Evaluators",
                        modalOpen: optionDetailModal,
                        onClose: () => onOptionEvaluatorsModalClose(),
                        children: <OptionEvaluvatorList player={playerDetail} onSave={() => onOptionEvaluatorsModalClose()} onClose={onOptionEvaluatorsModalClose} slotId={parseInt(params["slotId"])} optionId={optionId} />,
                        size: 'lg'
                    })
            }



            {
                getWidget(
                    {
                        type: "modal",
                        title: "Edit Player Details",
                        modalOpen: slotDetailModal,
                        onClose: () => onOptionEvaluatorsModalClose(),
                        children: <SlotEditDetails slot={selectedUser} onSave={() => onOptionEvaluatorsModalClose()} onClose={onOptionEvaluatorsModalClose} slotId={parseInt(params["slotId"])} eventId={parseInt(params["eventId"])} />,
                        size: 'sm'
                    })
            }




            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the player')} ${selectedUser?.firstName}? ${t('Once deleted, this action cannot be undone!')}`} />
            <ConfirmModal modalOpen={submitConfirmModal} onClose={() => setSubmitConfirmModal(false)} onConfirm={() => evaluationSubmit()} title={t('Are you sure?')} children={`${t('Do you want to submit the players evaluation?')} ${t('Once submitted, this action cannot be undone!')}`} />
        </div>
    )
}

export default EventSlotListPlayersPage