import moment from "moment";

export function splitCamelCase(str:string) {
    return str
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2') // Matches a lowercase followed by an uppercase
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2'); // Matches two consecutive uppercase characters followed by a lowercase
  }

  export function parseDate(dateString:string) {
    if(!dateString) return null;
    const [day, month, year]:any = dateString.split(".");
    if(!day || !month || !year) return null;
    return moment(new Date(year, month - 1, day));
}

export const checkPassword = (value:string) => {
  
    let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()_\-\+={[}\]:;"'<,>.?\\|])(?=\S+$)/;
                              
    if(!passwordRegex.test(value)) return "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character.";
    return null
}

export const responsiveString = (str: any) => {
  if (str instanceof Object) {
    const width = window.innerWidth;

    if (width >= 1920 && str.xl) {
      str = str.xl;
    } else if (width >= 1280 && str.lg) {
      str = str.lg;
    } else if (width >= 960 && str.md) {
      str = str.md;
    } else if (width >= 600 && str.sm) {
      str = str.sm;
    } else if (width >= 0 && str.xs) {
      str = str.xs;
    } else {
      // Fallback logic
      str = str.sm || str.xs || "";  // Modify the fallback as per your need
    }
  }

  return str;
};