import React, { useEffect } from 'react'
import style from './VerificationEmail.module.scss';
import logo from '../../../assets/images/logo.svg'
import CenterPage from '../../../shared/hoc/reset-password/center-page.hoc'
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { addToast, stopPageLoading } from '../../../store/reducers/AppSlice';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function VerificationEmailPage() {
  const { enqueueSnackbar } = useSnackbar();

  const VERIFY_OTP = gql`
  mutation VerifyOtp($key: String!, $email: String!, $otp: String!) {
    verifyOtp(key: $key, email: $email, otp: $otp) {
      email
      errors
      key
      success
    }
  }
    `

  const RESEND_OTP = gql`
  mutation Mutation($email: String!, $key: String!) {
    resendOtp(email: $email, key: $key) {
      success
      key
      errors
      email
    }
  }
  `

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { key, email } = useParams();

  const [verifyOtp] = useMutation(VERIFY_OTP);
  const [resendOtp] = useMutation(RESEND_OTP);

  const {t} = useTranslation();

  useEffect(()=>{
    dispatch(stopPageLoading())
},[])




  const verify = async (data: any) => {
    let res = await verifyOtp({ variables: { key: key, email: email, otp: data.otp } })
    if (res.data.verifyOtp.success) {
      navigate(`/reset-password/${res.data.verifyOtp.key}/set-password`)
    } else {
      enqueueSnackbar(t("The entered OTP is incorrect. Please try again."), { variant: "error" });
    }
  }

  const resendOTP = async (data: any) => {
    const res = await resendOtp({
      variables: {
        key: key, email: email
      }
    })
    navigate(`/reset-password/${res.data?.resendOtp?.key}/${res.data?.resendOtp?.email}/verify`)
  }

  return (
    <div>
      <CenterPage
        logo={<img src={logo} className={style.logo} />}
        variant='verification-email'
        verifyOTP={verify}
        width='430px'
        resendOTP={resendOTP} />
    </div>
  )
}