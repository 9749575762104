import React, { useEffect } from 'react'
import style from './SetPassword.module.scss'
import logo from '../../../assets/images/logo.svg'
import CenterPage from '../../../shared/hoc/reset-password/center-page.hoc'
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import { useTranslation } from 'react-i18next';

export default function SetPasswordPage() {

    const UPDATE_PASSWORD = gql`
    mutation ResetPassword($key: String!, $newPassword: String!, $confirmPassword: String!) {
      resetPassword(key: $key, newPassword: $newPassword, confirmPassword: $confirmPassword) {
        key
        success
        email
        errors
      }
    }
    
    `

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { key } = useParams();

    const dispatch = useDispatch();

    const [updatePassword] = useMutation(UPDATE_PASSWORD)

    const {t} = useTranslation();


    const setPassword = async (data: any) => {
        let res = await updatePassword({ variables: { key: key, newPassword: data.password, confirmPassword: data.confirmPassword } })
        if(res.data.resetPassword.success){
          navigate('/reset-password/success')
          enqueueSnackbar(t("Password reset successful"), { variant: "success" });
        }else{
          enqueueSnackbar(res.data.resetPassword.errors[0], { variant: "error" });
        }
    }

    useEffect(()=>{
      dispatch(stopPageLoading())
  },[])

    return (<div>
        <CenterPage
            logo={<img src={logo} className={style.logo} alt='' />}
            variant='set-password'
            setPassword={setPassword} />
    </div>)
}