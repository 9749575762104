import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { MenuItem } from '@mui/material'
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { useState } from "react";
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { getWidget } from '../../../shared/widgets';
import AddUser from './add-user';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import FilterUser from '../filter-user/FilterUser';
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString, splitCamelCase } from '../../../shared/utils/util-functions';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';


const DELETE_USER_QUERY = gql`mutation DeleteOneUser($deleteOneUserId: ID!) {
  deleteOneUser(id: $deleteOneUserId) {
    success
    errors
    user {
      firstName
    }
  }
}`

type ChildRef = {
  refetchData: (data: any) => void;
  handleDeleterowPagination: () => void
};


export default function UserListPage() {

  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [selectedUser, setSelectedUser]: any = React.useState(null);
  const [editMode, setEditMode]: any = React.useState(false);
  const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
  const [filterData, setFilterData]: any = React.useState({});
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = React.useState(1);
  const childRef = useRef<ChildRef | null>(null);
  const pageMeta = useContext(PageMetaContext);
  const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteUser] = useMutation(DELETE_USER_QUERY);

  const { t } = useTranslation();

  useEffect(() => {
    if (pageMeta) {
      const { setPageName, setBreadcrumbs } = pageMeta;
      setPageName("Users");
    }
  }, [pageMeta]);



  const handleRefetch = () => {
    childRef.current?.refetchData({
      search: searchText ?? "",
      role: filterData?.role,
      clubId: decodedTokenData.roles !== 'FVRZAdmin' ? decodedTokenData.ClubId : filterData?.clubId,
      associationId: filterData?.associationId,
      packageId: filterData?.packageId
    });
  };

  const onSearchTextChange = (event: any) => {
    if (searchText !== event.target.value) {
      setPage(1)
    }
    setSearchText(event.target.value)
  }

  useUpdateEffect(() => {
    handleRefetch();
  }, [filterData, searchText], 300)



  const handleFilterChange = (filters: any) => {
    if (filters.submit) {
      setPage(1)
      setFilterData(filters.filters);
    }
  };


  const deleteRow = async () => {
    setDeleteConfirmModal(false);
    if (!selectedUser) return;
    const variables = {
      deleteOneUserId: selectedUser?.id
    }


    try {
      const { data } = await deleteUser({
        variables: variables
      });
      setSelectedUser(null);

      if (data.deleteOneUser?.errors?.length > 0) {
        enqueueSnackbar(data.deleteOneUser.errors[0], { variant: "error" });
      } else {


        childRef?.current?.handleDeleterowPagination()
        enqueueSnackbar(t("User deleted successfully"), { variant: "success" });

      }
    } catch (error) {
      setSelectedUser(null);
      console.error('Mutation error:', error);
    }

  }


  const ActionBarConfig = useMemo(() => {
    return [
      ...((decodedTokenData.roles !== "SuperAdmin") ? [
        (
          {
            type: "filter-menu",
            children: <FilterUser filterData={filterData} onFilterChange={handleFilterChange} />,
            sx: {
              order: 1
            }
          }
        )
      ] : []),
      {
        type: "search-input",
        placeholder: "Search",
        onChange: onSearchTextChange,
        responsive: {
          xs: 12,
          sm: "auto"
        },
        sx: {
          order: {
            xs: 3,
            sm: 2
          }
        }
      },
      {
        type: "button",
        label: t(responsiveString({ xs: "Add", sm: "Add User" })),
        startIcon: <Add />,
        onClick: () => {
          setAddModalOpen(true);
          setEditMode(false);
        },
        sx: {
          order: {
            xs: 2,
            sm: 3
          }
        }
      },
    ]
  }, [decodedTokenData,filterData])


  const TableConfig = useMemo(() => {
    return [

      {
        label: "Action",
        align: "left",
        width: 130,
        accessor: (row: any) => {
          return <>
            <ActionMenu
              button={{
                type: "icon-button",
                icon: <MoreVertOutlined />,
                size: 'small',
                color: 'primary',
                sx: { color: 'white' }
              }}
              children={
                [
                  <MenuItem onClick={() => {
                    setSelectedUser(row);
                    setAddModalOpen(true)
                    setEditMode(true)
                  }}>{t('Edit')}</MenuItem>,
                  <MenuItem onClick={() => {
                    setSelectedUser(row);
                    setDeleteConfirmModal(true)
                  }}>{t('Delete')}</MenuItem>,
                ]
              }
            />
          </>
        }
      }
      ,
      {
        label: "First Name",
        align: "left",
        accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
      },
      {
        label: "Last Name",
        align: "left",
        accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
      },
      {
        label: "Email",
        align: "left",
        accessor: (row: any) => row.email
      },
      {
        label: "Contact Number",
        align: "left",
        accessor: (row: any) => row.contactNumber
      },
      {
        label: "Role",
        align: "left",
        accessor: (row: any) => t(splitCamelCase(row.role))
      },
      ...((decodedTokenData.roles === "ClubAdmin" || decodedTokenData.roles === "FVRZAdmin") ? [
        (
          {
            label: "Club",
            align: "left",
            accessor: (row: any) => row.Club?.clubName
          }
        )
      ] : []),

      ...((decodedTokenData.roles !== "Admin" && decodedTokenData.roles !== "SuperAdmin"  ) ? [
        (
          {
            label: "Association",
            align: "left",
            accessor: (row: any) => row.Association?.associationName
          }
        )
      ] : []),
    ];
  }, [decodedTokenData])

  const QUERY = `query Query($clubId: Int, $associationId: Int, $role: [String], $search: String, $page: Int, $pageSize: Int) {
  getAllUsers(clubId: $clubId, associationId: $associationId, role: $role, search: $search, page: $page, pageSize: $pageSize) {
    success
    errors
    totalUsers
    users {
      role
      lastName
      id
      email
      designation
      contactNumber
      Club {
        clubName
        id
      }
      firstName
      Association {
        id
        associationName
      }
    }
  }
}`

  const onAddUserModalClose = (submitted?: boolean) => {
    setAddModalOpen(false);
    setSelectedUser(null);
    if (submitted) {
      handleRefetch();
    }
  }

  return (
    <div>
      <TablePageHoc actions={ActionBarConfig as WidgetProps[]}
        columns={TableConfig as TableColumn[]}
        ref={childRef}
        query={QUERY}
        params={{
          search: searchText,
          role: filterData?.role,
          // clubId:filterData?.clubId,
          clubId: decodedTokenData.roles !== 'FVRZAdmin' ? decodedTokenData.ClubId : filterData?.clubId,
          associationId: filterData?.associationId,
          packageId: filterData?.packageId
        }}
        sourceAccessor={(data) => data.getAllUsers.users}
        countAccessor={(data) => data.getAllUsers.totalUsers}
        pagination={{
          enable: true,
          page: page,
          onChange: (page) => {
            setPage(page)
          }
        }} />

      {
        getWidget(
          {
            type: "modal",
            title: selectedUser ? "Edit User" : "Add User",
            modalOpen: addModalOpen,
            onClose: () => onAddUserModalClose(),
            children: <AddUser users={selectedUser} editMode={editMode} onSave={() => onAddUserModalClose(true)} onClose={onAddUserModalClose} />,
          })
      }

      <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the user')} ${selectedUser?.email}? ${t('Once deleted, this action cannot be undone!')}`} />
    </div>
  )
}
