import React, { useEffect, useState } from 'react';
import AppBar from '../../widgets/admin-layout/AppBar';
import SideBar from '../../widgets/admin-layout/SideBar';
import style from './AdminLayout.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumbs } from '../../widgets/breadcrumbs/breadcrumbs';
import { LayoutProps } from '../../types/admin-layout';
import { Box, Paper, Typography } from '@mui/material';
import classNames from 'classnames';
import { closeSidebar, setTheme } from '../../../store/reducers/AppSlice';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { BreadcrumbType, PageMetaProvider } from './AdminLyout.provider';
import { useTranslation } from 'react-i18next'


function AdminProtectedRoute({ children }: any) {
    const isLogged = useSelector((state: any) => state.auth.isLoggedIn)
    const location = useLocation();
    const [redirectPath, setRedirectPath]:any = useState(null);

    useEffect(() => {
        let redirectPath = '/login';
        if(!isLogged) {
            const urlWithQueryParams = `${location.pathname}${location.search}`;
            const queryParams = new URLSearchParams(location.search);
            const passwordReset = queryParams.get('password-reset')
            if(passwordReset) {
                redirectPath = `/reset-password/${passwordReset}/set-password`
            }
            localStorage.setItem('redirectPath', urlWithQueryParams);
            setRedirectPath(redirectPath);
        } else {
            localStorage.removeItem('redirectPath');
        }

    }, [isLogged])

    
    return (
        <>
            {

                isLogged ? (
                    children
                ) : (
                    redirectPath && <Navigate to={redirectPath} />
                )
            }
        </>
    )
}


function AdminLayoutHoc(props: LayoutProps) {
    const isOpen = useSelector((state: any) => state.app.sidebarOpen)
    const theme = useSelector((state: any) => state.app.theme)
    const [pageName, setPageName] = useState("");
    const [breadcrumbs, setBreadcrumbs] = useState(Array<BreadcrumbType>());

    const dispatch = useDispatch()
    const {t} = useTranslation()
    const data = { ...props, isOpen }
    useEffect(() => {
        if (isOpen) {
            document.documentElement.style.setProperty('--sideBarWidth', '250px');
        } else {
            if (window.innerWidth <= 578) {
                document.documentElement.style.setProperty('--sideBarWidth', '0px');
            } else {
                document.documentElement.style.setProperty('--sideBarWidth', '80px');
            }
        }
    }, [isOpen]);



    return (
        <PageMetaProvider value={{ pageName, setPageName, breadcrumbs, setBreadcrumbs }}>
            <AdminProtectedRoute>
                <div className={style.adminLayout}>
                    <div className={style.appBar}>
                        <AppBar {...data} />
                    </div>
                    <div className={style.contentWrapper}>
                        <div onClick={(any) => dispatch(closeSidebar())} className={classNames(style.sideBarOverlay, { [style.active]: isOpen })}></div>
                        <Box sx={{ bgcolor: 'background.default' }} className={style.sideBar}>
                            <SideBar {...data} />
                        </Box>
                        <Paper className={style.contentSection}>
                            <div className={style.contentHeading}>
                                {
                                    pageName && (
                                        <Typography variant='h6' color={'text.secondary'}>{t(pageName)}</Typography>
                                    )
                                }
                                {
                                    breadcrumbs.length > 0 && (
                                        <Breadcrumbs />
                                    )
                                }
                            </div>
                            <Box sx={{ bgcolor: 'background.default' }} className={style.contentArea}>
                                <Outlet />
                            </Box>
                        </Paper>
                    </div>
                </div>
            </AdminProtectedRoute>
        </PageMetaProvider>
    );
}

export default AdminLayoutHoc;
