import React from 'react'
import { Typography } from '@mui/material';
import styled from '@emotion/styled';


const Sul = styled('ul')(({ theme }) => ({
    listStyleType: 'none',
    padding: 0,
    display: 'flex',
    margin: '0 -15px',

}));

const Sli = styled('li')(({ theme }:any) => ({
    position: 'relative',
    '&:not(:last-child)': {
        "&::after": {
            content: '"/"',
            color: theme.palette.text.primary,
            position: 'absolute',
            right: '-3px',
            top: '50%',
            transform: 'translate(0, -50%)',
        },

        'a': {
            color: theme.palette.primary?.[600],
        }
    },
}));

const Sa = styled('a')(({ theme }:any) => ({
    textDecoration: 'none',
    color: theme.palette.text.primary,
    padding: '5px 15px',
}));


export const Breadcrumbs = () => {
    return (

        <Sul>
            <Sli>
                <Sa href="#">
                    <Typography component="span" variant="body1">
                        Home
                    </Typography>
                </Sa>
            </Sli>
            <Sli>
                <Sa href="#">Layout</Sa>
            </Sli>
        </Sul>
    )
}
