import React, { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextFieldProps, TextFieldVariants } from '@mui/material'
import { useTranslation } from 'react-i18next';

const PasswordField = <Variant extends TextFieldVariants>(props: {
    label?: string,
    variant?: Variant;
    wrapperSx?: any,
    helperText?: string,
    inputSx?: any
} & Omit<TextFieldProps, 'variant'>) => {
    const { t } = useTranslation();
    const { onChange, className, label, fullWidth, size, wrapperSx, inputSx, error, helperText } = props
    const [showPassword, setShowPassword] = useState(false);
    


    const overrideOnChange = (event: any) => {
        if (onChange) {
            onChange(event)
        }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);




    return (
        <FormControl variant="outlined" className={className} fullWidth={fullWidth} size={size} sx={wrapperSx}>
            {
                label && <InputLabel error={props.error} htmlFor="outlined-adornment-password">{ t(label ?? "")}</InputLabel>
            }
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                sx={inputSx}
                error={error ?? false}
                label={label && label}
                placeholder={props.placeholder}
                
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                onChange={overrideOnChange}
            />
            <FormHelperText error={error}>{helperText ?? " "}</FormHelperText>
        </FormControl>


    )
}

export default PasswordField