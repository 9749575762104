import React, { useState } from 'react'
import { Button, TextField, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ForgotPasswordProps } from '../../../types/center-page';
import { FormField, FormGroup } from '../../../utils/form-generator';
import { useTranslation } from 'react-i18next'
import validator from 'validator';

export default function ForgotPasswordHoc({ onEvent }: ForgotPasswordProps) {

  const {t} = useTranslation();

  const handleSendCode = (data:any) => {
    onEvent?.('send-code', { email: data.email })
  }

  return (
    <FormGroup onSubmit={handleSendCode}>
      <div>

        <Typography variant="h3" component="div" gutterBottom color="primary" sx={{ mb: 25 }}>
          {t('Forgot Password ?')}
        </Typography>
        <Typography variant="subtitle1" component="p" gutterBottom sx={{ mb: 25 }}>
          {t('You can receive the verification code by your email to reset your Password')}
        </Typography>
     
        <FormField name='email'  validator={(value: string, ...data) => {
          if (!value) return "Email is required."
          if (!validator.isEmail(value)) {
            return 'Enter a valid email address.'
        }
          return null
        }} validateOnChange={false}>
          <TextField id="firstName"
            label={t("Enter a user email")}
            variant="outlined"
            sx={{ mb: 10 }}
            fullWidth={true}
          />
        </FormField>
        <Button variant="contained" sx={{ mt: 30 }} type="submit">{t('SEND CODE')}</Button>
        <Typography variant="subtitle1" component="p" gutterBottom sx={{ mt: 30 }}>
          {t('Back to')} <Link href="#" underline="hover" color="primary" sx={{ fontSize: '18px' }} onClick={() => onEvent?.('sign-in')}>{t('Sign In')}</Link>
        </Typography>
      </div>
    </FormGroup>
  )
}
