import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Grid, MenuItem } from '@mui/material';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { getWidget } from '../../../shared/widgets';
import { useDispatch, useSelector } from 'react-redux';
import { responsiveString, splitCamelCase } from '../../../shared/utils/util-functions';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import AddEvaluator from "./add-event-evaluators";


const CREATE_EVENT_SLOT_EVALUATOR = gql`
mutation CreateEventSlotUser($slotId: Int!, $userId: Int!) {
    createEventSlotUser(slotId: $slotId, userId: $userId) {
      success
      errors
      slotUser {
        id
      }
    }
  }
`

const GET_EVENT_SLOT_EVALUATORS = gql`query GetAllSlotUsers($search: String, $page: Int, $pageSize: Int, $slotId: Int) {
    getAllSlotUsers(search: $search, page: $page, pageSize: $pageSize, slotId: $slotId) {
      errors
      success
      totalEventUsers
      users {
        id
        firstName
        email
        lastName
        role
        designation
        contactNumber
      }
    }
  }
`

const DELETE_SLOT_USER_QUERY = gql`
mutation DeleteOneSlotEvaluator($deleteOneSlotEvaluatorId: ID!) {
    deleteOneSlotEvaluator(id: $deleteOneSlotEvaluatorId) {
      success
      errors
    }
  }
`

const SEND_EMAIL_QUERY = gql`
mutation ShareEvent($userId: Int, $slotId: Int, $eventId: Int) {
    shareEvent(userId: $userId, slotId: $slotId, eventId: $eventId) {
      success
      errors
    }
  }`



type ChildRef = {
    refetchData: (data: any) => void;
};



function SlotEvaluvatorList({ onClose, editMode, slotId, modalOpen, onAddClose, event }: any) {

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const childRef = useRef<ChildRef | null>(null);
    const [filterData, setFilterData]: any = React.useState({});
    const [eventSlotEvaluators, setEventSlotEvaluators] = useState<any>([]);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [eventEvaluator, setEventEvaluator] = useState<any>([]);
    const [createEventEvalvator] = useMutation(CREATE_EVENT_SLOT_EVALUATOR);
    const [deleteOneEvaluator] = useMutation(DELETE_SLOT_USER_QUERY);
    const [sendEmai] = useMutation(SEND_EMAIL_QUERY);
    const { refetch: slotusersRefetch } = useQuery(GET_EVENT_SLOT_EVALUATORS, { skip: true });
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [emailConfirmModal, setEmailConfirmModal] = React.useState(false);
    const pageMeta = useContext(PageMetaContext);
    const { t } = useTranslation();
    const [selectedUser, setSelectedUser]: any = React.useState(null);
    const { enqueueSnackbar } = useSnackbar();




    useEffect(() => {
        getUserData();
    }, [])


   

    const getUserData = async () => {
        const { data } = await slotusersRefetch({
            search: null,
            page: null,
            pageSize: null,
            slotId: slotId

        });

        setEventSlotEvaluators(data?.getAllSlotUsers?.users);

        return data;
    }


    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText,
            slotId: slotId
        });
    };

    const onAddModalClose = () => {
        onClose();
        onAddClose();
        setAddModalOpen(false)
    }

    const sendEmail = async () => {
        setEmailConfirmModal(false);
        if (!selectedUser) return;


        const variables = {
            slotId: slotId,
            eventId: event.eventId,
            userId: selectedUser?.id
        }

        try {
            const { data } = await sendEmai({
                variables: variables
            });


            if (data.shareEvent?.errors?.length > 0) {
                enqueueSnackbar(data.shareEvent.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(t("Email sent successfully"), { variant: "success" });
                handleRefetch()
            }
        } catch (error) {
            console.error('Mutation error:', error);
        }

    }



    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;
        const variables = {
            deleteOneSlotEvaluatorId: selectedUser?.slotEvaluatorId
        }

        try {
            const { data } = await deleteOneEvaluator({
                variables: variables
            });
            setSelectedUser(null);

            if (data.deleteOneSlotEvaluator?.errors?.length > 0) {
                enqueueSnackbar(data.deleteOneSlotEvaluator.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(t("Evaluator deleted successfully"), { variant: "success" });
                handleRefetch()
            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }

    }




    const TableConfig = useMemo(() => {
        const config = [
            ...(editMode !== true ? (
                [
                    {
                        label: "Action",
                        align: "left",
                        width: 130,
                        accessor: (row: any) => {
                            return <>
                                <ActionMenu
                                    button={{
                                        type: "icon-button",
                                        icon: <MoreVertOutlined />,
                                        size: 'small',
                                        color: 'primary',
                                        sx: { color: 'white' }
                                    }}
                                    children={
                                        [
                                            <MenuItem onClick={() => {
                                                setEmailConfirmModal(true)
                                                setSelectedUser(row)
                                            }}>{t('Send Email')}</MenuItem>,
                                            <MenuItem onClick={() => {
                                                setSelectedUser(row);
                                                setDeleteConfirmModal(true)
                                            }}>{t('Delete')}</MenuItem>
                                        ]
                                    }
                                />
                            </>
                        }
                    },
                ]
            ) : []),
            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row.email
            },
            {
                label: "Contact Number",
                align: "left",
                accessor: (row: any) => row.contactNumber
            },
            {
                label: "Role",
                align: "left",
                accessor: (row: any) => t(splitCamelCase(row.role))
            },
        ]
        return config

    }, [])




    const QUERY = `query GetAllSlotUsers($search: String, $page: Int, $pageSize: Int, $slotId: Int) {
        getAllSlotUsers(search: $search, page: $page, pageSize: $pageSize, slotId: $slotId) {
          errors
          success
          totalEventUsers
          users {
            id
            firstName
            email
            lastName
            role
            designation
            contactNumber
            slotEvaluatorId
          }
        }
      }
      `

    return (
        <div>
            <TablePageHoc columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY}

                params={{
                    search: searchText,
                    slotId: slotId
                }}
                sourceAccessor={(data) => data.getAllSlotUsers.users}
                countAccessor={(data) => data?.getAllSlotUsers?.totalEventUsers}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Add Evaluators",
                        modalOpen: modalOpen,
                        onClose: () => onAddModalClose(),
                        children: <AddEvaluator users={eventSlotEvaluators} filterData={filterData} editMode={editMode} onSave={() => onAddModalClose()} onClose={onAddModalClose} slotId={slotId}  onAddClose={onAddClose} />,
                        size: 'lg'
                    })
            }

            <Divider sx={{ mx: -24, mb: 20, mt: 20 }} />
            <Grid container justifyContent={"flex-end"}>
                <Grid item xs={"auto"}>
                    <Button type="button" variant="text" color="secondary" onClick={onClose}>{t('Close')}</Button>
                </Grid>

            </Grid>

            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to remove the evaluator')} ${selectedUser?.firstName}? ${t('Once deleted, this action cannot be undone!')}`} />
            <ConfirmModal modalOpen={emailConfirmModal} onClose={() => setEmailConfirmModal(false)} onConfirm={() => sendEmail()} title={t('Are you sure?')} children={`${t('Do you want to send an invite link to the assigned evaluator?')}`} />
        </div>
    )
}

export default SlotEvaluvatorList