import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import { getWidget } from '..';
import { ActionBarPropsBase } from '../../types/widget-types';




function ActionbarWidget({actions}: ActionBarPropsBase) {



    return (
        <Box sx={{ py: 15 }}>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                {/* <Grid item xs="auto"> */}
                <Grid container spacing={{xs: 12, lg: 20}} alignItems={"center"} justifyContent={"flex-end"}>
                    {
                        actions && actions.map((action, index) => (
                            <Grid item  key={index} sx={{...(action.sx ?? {})}} {...(action.responsive ?? {xs:"auto"})}>
                                {
                                    getWidget(action)
                                }
                            </Grid>
                        ))
                    }
                    
                </Grid>
                {/* </Grid> */}

            </Grid>
        </Box>
    )
}

export default ActionbarWidget