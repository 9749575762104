import React, { useContext, useEffect, useState } from 'react'
import BasicTabs from '../../../shared/hoc/tabs/Tabs'
import ClubListPage from '../clubs-list-page/ClubListPage';
import AssociationListPageTab from '../associations-list-page/AssociationListPage';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';



function AssocationListPage() {

    const {t} = useTranslation();
    
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)


    return (
        <div>
            {
                decodedTokenData?.roles == 'FVRZAdmin' ? (
                    <BasicTabs tabsData={[
                        {
                            label: 'Clubs', content: () => (
                                <div>
                                    <ClubListPage/>
                                </div>
                            )
                        },
                        {
                            label: 'Associations', content: () => (
                                <div>
                                    <AssociationListPageTab/>
                                </div>
                            )
                        },
                    ]} />

                ) : (
                    <AssociationListPageTab/>
                )
            }
        </div>
    ) 
}

export default AssocationListPage