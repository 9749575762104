import React, { useEffect, useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField';
import Grid from '@mui/material/Grid';
import { Button, Divider, InputLabel, MenuItem } from '@mui/material';
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import validator from 'validator';
import { uid as uniqueId } from 'uid';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../store/reducers/AppSlice';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import DateField from '../../../shared/widgets/date-field/DateField';
import { parseDate } from '../../../shared/utils/util-functions';
import moment from 'moment';
import CountryCodeDropdown from '../../../shared/widgets/country-code/CountryCodeDropdown';
import MuiTelephoneInput from '../../../shared/widgets/tel-input/MuiTelInput';
import { matchIsValidTel } from 'mui-tel-input'
import Countries from "./../../../shared/utils/country-code.json";


const CREATE_PLAYER_QUERY = gql`mutation AddAssociationPlayer($firstName: String!, $lastName: String!, $dateOfBirth: String!, $playerAddress: String!, $postCode: String!, $city: String!, $contactPersonName: String!, $relationship: String!, $telephone: String!, $emailAddressImpFatherOrMother: String!, $nationality: String!, $goalKeeper: String!, $seasonId: Int!, $strongFoot: String, $associationId: Int) {
  addAssociationPlayer(firstName: $firstName, lastName: $lastName, dateOfBirth: $dateOfBirth, playerAddress: $playerAddress, postCode: $postCode, city: $city, contactPersonName: $contactPersonName, relationship: $relationship, telephone: $telephone, emailAddressImpFatherOrMother: $emailAddressImpFatherOrMother, nationality: $nationality, goalKeeper: $goalKeeper, seasonId: $seasonId, strongFoot: $strongFoot, associationId: $associationId) {
    success
    errors
  }
}`


const UPDATE_PLAYER_QUERY = gql`mutation UpdateOneAssociationPlayer($updateOneAssociationPlayerId: ID!, $firstName: String, $lastName: String, $dateOfBirth: String, $playerAddress: String, $city: String, $postCode: String, $contactPersonName: String, $relationship: String, $telephone: String, $nationality: String, $emailAddressImpFatherOrMother: String, $goalKeeper: String, $strongFoot: String) {
    updateOneAssociationPlayer(id: $updateOneAssociationPlayerId, firstName: $firstName, lastName: $lastName, dateOfBirth: $dateOfBirth, playerAddress: $playerAddress, city: $city, postCode: $postCode, contactPersonName: $contactPersonName, relationship: $relationship, telephone: $telephone, nationality: $nationality, emailAddressImpFatherOrMother: $emailAddressImpFatherOrMother, goalKeeper: $goalKeeper, strongFoot: $strongFoot) {
      success
      errors
    }
  }`


function AddAssociationPlayer({ player, onSave, seasonId, onClose }: any) {

    const { t } = useTranslation();
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [resetField, setResetField] = useState<Function | undefined>(undefined);


    const [createPlayer] = useMutation(CREATE_PLAYER_QUERY);
    const [updatePlayer] = useMutation(UPDATE_PLAYER_QUERY);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [countries, setCountries] = useState(Countries);




    const handleSubmit = async (newPlayer: any, callback: Function) => {

        let clubId = parseInt(newPlayer.clubId ?? player?.Club?.id);
        let associationId = parseInt(newPlayer.associationId ?? player?.Association?.id);

        if (decodedTokenData.roles != 'SuperAdmin') {
            clubId = parseInt(decodedTokenData.ClubId);
        }
        if (decodedTokenData.roles == 'AssociationAdmin') {
            associationId = parseInt(decodedTokenData.AssociationId);
        }

        if (player) {
            const variables = {
                updateOneAssociationPlayerId: player.id,
                firstName: newPlayer.firstName,
                lastName: newPlayer.lastName,
                dateOfBirth: newPlayer.dateOfBirth,
                contactPersonName: newPlayer.contactPersonName,
                relationship: newPlayer.relationship,
                telephone: newPlayer.telephone,
                emailAddressImpFatherOrMother: newPlayer.emailAddressImpFatherOrMother,
                playerAddress: newPlayer.address,
                postCode: newPlayer.postCode,
                city: newPlayer.city,
                nationality: newPlayer.nationality,
                goalKeeper: newPlayer.goalKeeper,
                strongFoot: newPlayer.strongFoot,
                seasonId:parseInt(seasonId)
            }

            try {
                const { data } = await updatePlayer({
                    variables: variables
                });

                if (data.updateOneAssociationPlayer?.errors?.length > 0) {
                    enqueueSnackbar(data.updateOneAssociationPlayer.errors[0], { variant: "error" });
                } else {

                    enqueueSnackbar(t("Player updated successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
            }

        } else {
            const variables = {
                firstName: newPlayer.firstName,
                lastName: newPlayer.lastName,
                dateOfBirth: newPlayer.dateOfBirth,
                contactPersonName: newPlayer.contactPersonName,
                relationship: newPlayer.relationship,
                telephone: newPlayer.telephone,
                emailAddressImpFatherOrMother: newPlayer.emailAddressImpFatherOrMother,
                playerAddress: newPlayer.address,
                postCode: newPlayer.postCode,
                city: newPlayer.city,
                nationality: newPlayer.nationality,
                goalKeeper: newPlayer.goalKeeper,
                strongFoot: newPlayer.strongFoot,
                seasonId:parseInt(seasonId)
            }

            try {
                const { data } = await createPlayer({
                    variables: variables
                });

                if (data.addAssociationPlayer?.errors?.length > 0) {
                    enqueueSnackbar(data.addAssociationPlayer.errors[0], { variant: "error" });
                    callback();
                } else {

                    enqueueSnackbar(t("Player created successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }

        }

    };


    return (
        <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
            <Grid container spacing={12}>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="firstName" value={player?.firstName ?? ""} validator={(value: string) => {
                        if (!value) return "First name is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="Name" label={t("First Name")} variant="outlined" sx={{ mb: 10 }} fullWidth={true} />
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="lastName" value={player?.lastName ?? ""} validator={(value: string) => {
                        if (!value) return "Last name is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="Name" label={t("Last Name")} variant="outlined" sx={{ mb: 10 }} fullWidth={true} />
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    {/* <InputLabel htmlFor="Name">{t("Date Of Birth")}</InputLabel> */}
                    <FormField name="dateOfBirth" value={player?.dateOfBirth ?? ''} validator={(value: string) => {
                        if (!value) return "Date of birth is required."
                        let date = parseDate(value);
                        if (!date) return "Invalid Date"
                        return null
                    }} validateOnChange={false}>
                        <DateField id="Name" label={t("Date Of Birth")} maxDate={moment(new Date())} sx={{ mb: 10 }} fullWidth={true} />

                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="contactPersonName" value={player?.contactPersonName ?? ""} validator={(value: string) => {
                        if (!value) return "Contact person name is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="Name" label={t("Contact Person Name")} variant="outlined" sx={{ mb: 10 }} fullWidth={true} />
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="relationship" value={player?.relationship ?? ""} validator={(value: string) => {
                        if (!value) return "Select a relationship"
                        return null
                    }} validateOnChange={false}>
                        <TextField id="associations"
                            label={t("Relationship")}
                            select
                            variant="outlined"
                            fullWidth={true}
                        >
                            <MenuItem value="Father" >
                                {t('Father')}
                            </MenuItem>

                            <MenuItem value="Mother" >
                                {t('Mother')}
                            </MenuItem>
                        </TextField>
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>

                    <FormField name="telephone" value={player?.telephone ?? ""} validator={(value: string) => {
                        if (!value) return "Telephone number is required.";
                        const newValue = matchIsValidTel(value)

                        if (!newValue) {
                            return "Contact number must be 5-15 digits long"
                        }
                        return null
                    }} validateOnChange={false}>
                        <MuiTelephoneInput
                            id="telephone"
                            label={t("Telephone")}
                            variant="outlined"
                            sx={{ mb: 10 }}
                            fullWidth={true}
                        />
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormField name="emailAddressImpFatherOrMother" value={player?.emailAddressImpFatherOrMother ?? ""} validator={(value: string) => {
                        if (!value) return "Email address is required."
                        if (!validator.isEmail(value)) {
                            return 'Enter a valid email address'
                        }
                        return null
                    }} validateOnChange={false}>
                        <TextField id="Name" label={t("Parent's Email Address")} variant="outlined" sx={{ mb: 10 }} fullWidth={true} />
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <FormField name="address" value={player?.playerAddress ?? ""} validator={(value: string) => {
                        if (!value) return "Address is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="Name" label={t("Street")} variant="outlined" sx={{ mb: 10 }} fullWidth={true} />
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="postCode" value={player?.postCode ?? ""} validator={(value: string) => {
                        if (!value) return "Postcode is  required."
                        if (!validator.isNumeric(value)) {
                            return 'Enter a valid postcode.'
                        }
                        return null
                    }} validateOnChange={false}>
                        <TextField id="Name" label={t("Postcode")} variant="outlined" sx={{ mb: 10 }} fullWidth={true} />
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="city" value={player?.city ?? ""} validator={(value: string) => {
                        if (!value) return "City is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="Name" label={t("City")} variant="outlined" sx={{ mb: 10 }} fullWidth={true} />
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="nationality" value={player?.nationality ?? ""} validator={(value: string) => {
                        if (!value) return "Nationality is required."
                        return null
                    }} validateOnChange={false}>

                        <TextField id="nationality"
                            label={t("Nationality")}
                            select
                            variant="outlined"
                            fullWidth={true}
                        >
                            {countries ? countries.countries.map((country: any) => (
                                <MenuItem key={"country" + country.value} value={country.value}>
                                    {country.label}
                                </MenuItem>
                            )) : <MenuItem></MenuItem>}
                        </TextField>
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="goalKeeper" value={player?.goalKeeper ?? ""} validator={(value: string) => {
                        if (!value) return "Goal keeper selection is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="goalKeeper"
                            label={t("Position only if goalkeeper")}
                            select
                            variant="outlined"
                            fullWidth={true}
                        >
                            <MenuItem value="Yes" >
                                {t('Yes')}
                            </MenuItem>

                            <MenuItem value="No" >
                                {t('No')}
                            </MenuItem>

                        </TextField>
                    </FormField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormField name="strongFoot" value={player?.strongFoot ?? ""} validator={(value: string) => {
                        // if (!value) return "Strong foot selection is required."
                        return null
                    }} validateOnChange={false}>
                        <TextField id="associations"
                            label={t("Strong Foot")}
                            select
                            variant="outlined"
                            fullWidth={true}
                        >
                            <MenuItem value="Right" >
                                {t('Right')}
                            </MenuItem>
                            <MenuItem value="Left" >
                                {t('Left')}
                            </MenuItem>
                        </TextField>
                    </FormField>
                </Grid>
                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </FormGroup>
    )
}

export default AddAssociationPlayer