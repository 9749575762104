import React, { useEffect } from 'react'
import SubmittedPlayersList from '../submitted-players-list/SubmittedPlayersList';
import { useSelector } from 'react-redux';
import AssociationPlayersList from '../association-player-management/AssociationPlayersList';




function SubmiitedList() {
    
    return (
        <div>
            <AssociationPlayersList />
        </div>
    )
}

export default SubmiitedList

