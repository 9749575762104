import { TextFieldProps, TextFieldVariants,InputAdornment,IconButton, TextField as TF } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';



export default function TextField<Variant extends TextFieldVariants>(props: {
  variant?: Variant;
  readonly?: boolean;

} & Omit<TextFieldProps, 'variant'>) {


  const {...TFProps } = props

  // const inputRef = useRef<HTMLInputElement | null>(null);

  // // Function to clear the input when it receives focus
  // const handleInputFocus = () => {
  //     if (inputRef.current) {
  //         inputRef.current.value = '';
  //     }
  // }


  const overrideOnChange = (event: any) => {
    if (props.onChange) {
      props.onChange(event)
    }
  }

  const overrideKeyDown = (event: any) => {
    let value = (event?.target?.value ?? "");
    if(value){
      value = value.trim();
  }

    if (value === "" && event?.code === "Space") {
      event.preventDefault();
      return;
    }

    if (props.onKeyDown) {
      props.onKeyDown(event)
    }
  }

  const handlePaste = (event: any) => {
    const pastedData = (event.clipboardData || (window as any).clipboardData).getData('text');

    if (pastedData.startsWith(' ')) {
      event.preventDefault();
    }
  }


  return (
    <TF
      {...{ ...TFProps, onChange: overrideOnChange, onKeyDown: overrideKeyDown, autoComplete: 'off', onPaste: handlePaste, error: props.error ?? false, helperText: props.helperText ?? " " }}
      SelectProps={
        {
          readOnly: props.readonly ?? false,
          
          MenuProps: {
            
            PaperProps:{
              className: "custom_menu_drop"
            },
            MenuListProps: {
              sx: {
                // backgroundColor: '#242431 !important',
                maxHeight: "200px",
              },
            },
          }
        }
      }
      autoComplete='off'
      // InputProps={showIcon ? { // Conditionally render icon based on showIcon prop
      //   endAdornment: (
      //     <InputAdornment position="end">
      //       <IconButton onClick={() => console.log('Add icon clicked')}>
      //         <AddCircleOutlineIcon />
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      // } : undefined}

    // inputRef={inputRef}
    // onFocus={handleInputFocus}
    />
  )
}
