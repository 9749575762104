import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { SearchInputPropsBase } from '../../types/widget-types';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#2a2b35',
    '&:hover': {
        backgroundColor: '#353743',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    border: `1px solid ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 15),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})); 

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(9, 20, 10, 40),
        fontSize: '1.6rem',
        lineHeight: '2.2rem',
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '220px',
        },
    },
}));



function SearchInput({placeholder, onChange, onSubmit}:SearchInputPropsBase) {

    const [value, setValue] = React.useState('');

    const handleChange = (event:any) => {
        setValue(event.target.value);
        onChange?.(event);
    }

    const handleSubmit = (event:any) => {
        event.preventDefault();
        onSubmit?.(value);
    }
    return (
        <form onSubmit={handleSubmit}>
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder={placeholder}
                    value={value}
                    onChange={(event) => handleChange(event)}
                    inputProps={{ 'aria-label': placeholder }}
                />
            </Search>
        </form>
    )
}

export default SearchInput