import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import PasswordField from '../../../shared/widgets/password/password'
import { Button, Divider, Grid, MenuItem } from '@mui/material'
import { gql, useMutation, useQuery } from '@apollo/client'
import { uid as uniqueId } from 'uid'
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { checkPassword, parseDate } from '../../../shared/utils/util-functions'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import MuiTelephoneInput from '../../../shared/widgets/tel-input/MuiTelInput'
import DateField from '../../../shared/widgets/date-field/DateField'
import moment from 'moment'
import Countries from "./../../../shared/utils/country-code.json";


const CREATE_USER_QUERY = gql`mutation CreateCustomer($gender: String!, $firstName: String!, $email: String!, $contactNumber: String!, $associationId: Int!, $dateOfBirth: String!, $relation: String!, $language: String!, $lastName: String) {
  createCustomer(gender: $gender, firstName: $firstName, email: $email, contactNumber: $contactNumber, associationId: $associationId, dateOfBirth: $dateOfBirth, relation: $relation, language: $language, lastName: $lastName) {
    success
    errors
  }
}`


const UPDATE_USER_QUERY = gql`mutation UpdateOneCustomer($updateOneCustomerId: ID, $gender: String, $firstName: String, $lastName: String, $email: String, $contactNumber: String, $dateOfBirth: String, $relation: String, $language: String) {
  updateOneCustomer(id: $updateOneCustomerId, gender: $gender, firstName: $firstName, lastName: $lastName, email: $email, contactNumber: $contactNumber, dateOfBirth: $dateOfBirth, relation: $relation, language: $language) {
    success
    errors
  }
}`



type ChildRef = {
    refetchData: (data: any) => void;
};

export default function AddUser({ latestAssociationId, users, onSave, onClose }: any) {


    const { t } = useTranslation();

    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedRelation, setSelectedRelation] = useState(null);
    const [resetField, setResetField] = useState<Function | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const [createUser] = useMutation(CREATE_USER_QUERY);
    const [updateUser] = useMutation(UPDATE_USER_QUERY);
    const [countries, setCountries] = useState(Countries);
    const [association, setAssociation]: any = useState(null);

    const onCloseUser = () => {
        onClose();
    }

    const gender = [
        {
            value: 'Male',
            label: 'Male'
        },
        {
            value: 'Women',
            label: 'Women'
        }
    ]

    const relations = [
        {
            value: 'President',
            label: 'President'
        },
        {
            value: 'Chief Financial Officer',
            label: 'Chief Financial Officer'
        },
        {
            value: 'Head of Marketing',
            label: 'Head of Marketing'
        },
        {
            value: 'Active manager',
            label: 'Active manager'
        },
        {
            value: 'Head of Sport',
            label: 'Head of Sport'
        },
        {
            value: 'Head of Juniors',
            label: 'Head of Juniors'
        }
    ]

    const languages = [
        {
            value: 'CH',
            label: 'CH'
        },
        {
            value: 'DE',
            label: 'DE'
        }
    ]



    const handleSubmit = async (user: any, callback: Function) => {

        if (users) {
            const variables = {
                updateOneCustomerId: users.id,
                gender: user.gender,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                contactNumber: user.contactNumber,
                dateOfBirth: user.dateOfBirth,
                relation: user.relation,
                language: user.language
            }

            try {
                const { data } = await updateUser({
                    variables: variables
                });

                if (data.updateOneCustomer.errors?.length > 0) {
                    enqueueSnackbar(data.updateOneCustomer.errors[0], { variant: "error" });
                } else {

                    enqueueSnackbar(t("User updated successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
            }
        } else {

            let newAssId = latestAssociationId ? parseInt(latestAssociationId) : parseInt(association);
            const variables = {
                gender: user.gender,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                contactNumber: user.contactNumber,
                associationId: newAssId,
                dateOfBirth: user.dateOfBirth,
                relation: user.relation,
                language: user.language
            }
            try {
                const { data } = await createUser({
                    variables: variables
                });

                if (data.createCustomer.errors?.length > 0) {
                    console.error('Error:', data.createCustomer.errors);
                    const errorIndex = data.createCustomer.errors?.length;
                    enqueueSnackbar(data.createCustomer.errors[errorIndex-1], { variant: "error" });
                    callback();
                } else {
                    enqueueSnackbar(t("User created successfully"), { variant: "success" });
                    onSave()
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }
        }

    };



    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>

                <FormField name='firstName' value={users?.firstName ?? ""} validator={(value: string, ...data) => {
                    if (!value) return "First name is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="firstName"
                        label={t("First Name")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>
                <FormField name='lastName' value={users?.lastName ?? ""} validator={(value: string) => {
                    return null
                }} validateOnChange={false}>
                    <TextField id="lastName"
                        label={t("Last Name")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>
                <FormField name='email' value={users?.email ?? ""} validator={(value: string) => {
                    if (!value) {
                        return 'Email is required.'
                    }
                    if (!validator.isEmail(value)) {
                        return 'Enter a valid email address.'
                    }
                    return null
                }} validateOnChange={false}>
                    <TextField id="email"
                        label={t("Email")}
                        autoComplete='off'
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>
                <Grid container spacing={12}>

                    <Grid item xs={12} sm={6} md={6}>
                        <FormField name='gender' value={users?.gender ?? selectedGender} validator={(value: string, data: any) => {
                            if (!value) return "Gender selection is required.";
                            return null
                        }} validateOnChange={false}>
                            <TextField id="gender"
                                label={t("Gender")}
                                variant="outlined"
                                select
                                sx={{ mb: 10 }}
                                fullWidth={true}
                                onChange={(e: any) => {
                                    setSelectedGender(e.target.value)
                                }}
                            >
                                {gender.map((option) => (
                                    <MenuItem key={option.value} value={option.value} >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}

                            </TextField>
                        </FormField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField name="dateOfBirth" value={users?.dateOfBirth ?? ''} validator={(value: string) => {
                            if (!value) return "Date of birth is required."
                            let date = parseDate(value);
                            if (!date) return "Invalid Date"
                            return null
                        }} validateOnChange={false}>
                            <DateField id="Name" label={t("Date Of Birth")} maxDate={moment(new Date())} sx={{ mb: 10 }} fullWidth={true} />

                        </FormField>
                    </Grid>

                </Grid>

                <FormField name='contactNumber' value={users?.contactNumber ?? ""} validator={(value: string) => {
                    if (!value) return "Contact number is required."
                    const newValue = matchIsValidTel(value)
                    if (!newValue) {
                        return "Contact number must be 5-15 digits long"
                    }

                    return null
                }} validateOnChange={false}>

                    <MuiTelephoneInput
                        id="contactNumber"
                        label={t("Contact Number")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>
   
                <FormField name="language" value={users?.language ?? ""} validator={(value: string) => {
                    if (!value) return "Language is required."
                    return null
                }} validateOnChange={false}>

                    <TextField id="language"
                        label={t("Language")}
                        select
                        variant="outlined"
                        fullWidth={true}
                        sx={{ mb: 10 }}
                    >
                        {languages ? languages.map((country: any) => (
                            <MenuItem key={"country" + country.value} value={country.value}>
                                {country.label}
                            </MenuItem>
                        )) : <MenuItem></MenuItem>}
                    </TextField>
                </FormField>
                <FormField name='relation' value={users?.relation ?? selectedRelation} validator={(value: string, data: any) => {
                    if (!value) return "Relation selection is required.";
                    return null
                }} validateOnChange={false}>
                    <TextField id="relation"
                        label={t("Relation")}
                        variant="outlined"
                        select
                        sx={{ mb: 10 }}
                        fullWidth={true}
                        onChange={(e: any) => {
                            setSelectedRelation(e.target.value)
                        }}
                    >
                        {relations.map((option) => (
                            <MenuItem key={option.value} value={option.value} >
                                {t(option.label)}
                            </MenuItem>
                        ))}

                    </TextField>
                </FormField>
                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" color="secondary" onClick={onCloseUser} >{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    )
}
