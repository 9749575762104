import React, { useEffect, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import TextField from '../../../shared/widgets/text-field/TextField';
import { Button, Divider, Grid, MenuItem } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import DateField from '../../../shared/widgets/date-field/DateField';
import { parseDate } from '../../../shared/utils/util-functions';


const CREATE_ASSOCIATION_QUERY = gql`mutation CreateCustomerAssociation($associationName: String!, $subcategoryId: Int!, $categoryId: Int!, $packageId: Int!, $startDate: String!, $endDate: String!) {
  createCustomerAssociation(associationName: $associationName, subcategoryId: $subcategoryId, categoryId: $categoryId, packageId: $packageId, startDate: $startDate, endDate: $endDate) {
    success
    errors
    association {
      id
    }
  }
}
  `

const GET_CATEGORIES_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int) {
  getAllCategories(search: $search, page: $page, pageSize: $pageSize) {
    totalCategories
    success
    categories {
      categoryName
      id
    }
    errors
  }
}
  `

const GET_SUBCATEGORIES_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int, $categoryId: Int) {
  getAllSubCategories(search: $search, page: $page, pageSize: $pageSize, categoryId: $categoryId) {
    errors
    totalSubCategories
    success
    subcategories {
      subcategoryName
      id
      Category {
        categoryName
        id
      }
    }
  }
}`

const UPDATE_ASSOCIATION_QUERY = gql`
mutation Mutation($updateCustomerAssociationId: ID, $associationName: String, $categoryId: Int, $subcategoryId: Int, $packageId: Int, $startDate: String, $endDate: String) {
  updateCustomerAssociation(id: $updateCustomerAssociationId, associationName: $associationName, categoryId: $categoryId, subcategoryId: $subcategoryId, packageId: $packageId, startDate: $startDate, endDate: $endDate) {
    success
    errors
  }
}
`

const GET_PACKAGES_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int) {
    getAllPackages(search: $search, page: $page, pageSize: $pageSize) {
      errors
      success
      totalPackages
      packages {
        packageName
        id
        eventLimit
        cost
      }
    }
  }
    `


function Addassociation({ association, onSave, onClose, handleNext }: any) {

    const [resetField, setResetField] = useState<Function | undefined>(undefined);
    const [selectedCategory, setSelectedCategory]: any = useState(association?.Category?.id);
    const [selectedSubCategory, setSelectedSubCategory]: any = useState(null);
    const [packagesData, setPackagesData] = useState<any>(null);
    const [categoryData, setCategoryData] = useState([])
    const [createAssociation] = useMutation(CREATE_ASSOCIATION_QUERY);
    const { loading: categoryLoading, error: categoryError, data: categoriesData } = useQuery(GET_CATEGORIES_QUERY, {
        variables: {
            search: null, page: null
        }
    });
    const [updateAssociation] = useMutation(UPDATE_ASSOCIATION_QUERY);
    const { refetch: categoryRefetch } = useQuery(GET_SUBCATEGORIES_QUERY, { skip: true });
    const { refetch: packageRefetch } = useQuery(GET_PACKAGES_QUERY, { skip: true });
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getSubCategories();
        getPackageData();
    }, [selectedCategory])

    const getPackageData = async () => {
        const { data } = await packageRefetch({
            search: "",
            page: null,
            pageSize: null,

        });
        setPackagesData(data);
        return data;
    }


    const getSubCategories = async () => {
        const { data } = await categoryRefetch({
            search: "",
            page: null,
            pageSize: null,
            categoryId: parseInt(selectedCategory)
        });
        setCategoryData(data?.getAllSubCategories?.subcategories);
        return data;
    }

    const handleSubmit = async (newAssociation: any, callback: Function) => {

        if (association) {

            const variables = {
                updateCustomerAssociationId: association?.Association?.id,
                associationName: newAssociation.associationName,
                subcategoryId: parseInt(newAssociation.subcategoryId),
                categoryId: parseInt(newAssociation.categoryId),
                packageId:parseInt(newAssociation.packageId),
                startDate:newAssociation.startDate,
                endDate:newAssociation.endDate
            }
            try {
                const { data } = await updateAssociation({
                    variables: variables
                });

                if (data.updateCustomerAssociation.errors?.length > 0) {
                    enqueueSnackbar(data.updateCustomerAssociation.errors[0], { variant: "error" });

                } else {
                    enqueueSnackbar(t("Association updated successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
            }

        } else {

            const variables = {
                associationName: newAssociation.associationName,
                subcategoryId: parseInt(selectedSubCategory),
                categoryId: parseInt(selectedCategory),
                packageId:parseInt(newAssociation.packageId),
                startDate:newAssociation.startDate,
                endDate:newAssociation.endDate
            }
            try {
                const { data } = await createAssociation({
                    variables: variables
                });


                if (data.createCustomerAssociation.errors?.length > 0) {
                    console.error('Error:', data.createCustomerAssociation.errors);
                    enqueueSnackbar(data.createCustomerAssociation.errors[0], { variant: "error" });
                    callback();
                } else {
                    enqueueSnackbar(t("Association created successfully"), { variant: "success" });
                    handleNext(data.createCustomerAssociation.association);
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }

        }


    };


    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <FormField name='associationName' value={association?.Association?.associationName ?? ""} validator={(value: string) => {
                    if (!value) return "Association name is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="associationName"
                        label={t("Company Name")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>

                <FormField name='categoryId' value={association?.Category?.id ?? ""} validator={(value: string) => {
                    if (!value) return "Category selection is required.";
                    return null
                }} validateOnChange={false}>
                    <TextField id="role"
                        label={t("Category")}
                        select
                        variant="outlined"
                        sx={{ mb: 10 }}
                        readonly={(association?.Club?.id ?? "") != ""}
                        fullWidth={true}
                        onChange={(e: any) => {
                            setSelectedCategory(e.target.value);
                        }}
                    >
                        {!categoryLoading && !categoryError ? categoriesData.getAllCategories.categories.map((category: any) => (
                            <MenuItem key={"category" + category.id} value={category.id}>
                                {category.categoryName}
                            </MenuItem>
                        )) : <MenuItem>{t('Loading')}</MenuItem>}
                    </TextField>
                </FormField>

                {
                    selectedCategory && <FormField name='subcategoryId' value={association?.Subcategory?.id ?? ""} validator={(value: string) => {
                        if (!value) return "Category selection is required.";
                        return null
                    }} validateOnChange={false}>
                        <TextField id="role"
                            label={t("Sub Category")}
                            select
                            variant="outlined"
                            sx={{ mb: 10 }}
                            fullWidth={true}
                            onChange={(e: any) => {
                                setSelectedSubCategory(e.target.value);
                            }}
                        >
                            {categoryData ? categoryData?.map((category: any) => (
                                <MenuItem key={"subcategory" + category.id} value={category.id}>
                                    {category.subcategoryName}
                                </MenuItem>
                            )) : <MenuItem>{t('Loading')}</MenuItem>}
                        </TextField>
                    </FormField>
                }
                <FormField name='packageId' value={association?.Package?.id ?? ""} validator={(value: string, data) => {
                    if (!value) return "Package selection is required.";
                    return null
                }} validateOnChange={false}>
                    <TextField id="packageId"
                        label={t("Package")}
                        select
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    >
                        {packagesData ? packagesData?.getAllPackages?.packages?.map((plan: any) => (
                            <MenuItem key={"package" + plan.id} value={plan.id} >
                                {plan.packageName}
                            </MenuItem>
                        )) : <MenuItem></MenuItem>}
                    </TextField>
                </FormField>

                <FormField name="startDate" value={association?.startDate ?? ''} validator={(value: string) => {
                    if (!value) return "Start date is required."
                    let date = parseDate(value);
                    if (!date) return "Invalid Date"
                    return null
                }} validateOnChange={false}>
                    <DateField id="Name" label={t("Start date")} sx={{ mb: 10 }} fullWidth={true} />
                </FormField>

                <FormField name="endDate" value={association?.endDate ?? ''} validator={(value: string) => {
                    if (!value) return "End date is required."
                    let date = parseDate(value);
                    if (!date) return "Invalid Date"
                    return null
                }} validateOnChange={false}>
                    <DateField id="Name" label={t("End date")} sx={{ mb: 10 }} fullWidth={true} />
                </FormField>


                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    )
}

export default Addassociation