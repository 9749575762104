import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { ButtonProps, FilterMenuPropsBase, IconButtonProps, WidgetProps } from '../../../shared/types/widget-types';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { getWidget } from '..';
import { MenuItem } from '@mui/material';

interface ActionMenuPropsBase {
    button: ButtonProps | IconButtonProps;
    children: any[];
}
interface ActionMenuProps extends ActionMenuPropsBase {
}

export default function ActionMenu({ button, children }: ActionMenuPropsBase) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };


  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {
          getWidget({
            ...button,
            onClick:handleClick
          })
        }
      </Box>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        
        {
            children.map(menuItem => menuItem)
        }
      </Menu>
    </React.Fragment>
  );
}