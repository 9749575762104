import React, { useContext, useEffect, useRef } from 'react'
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { Button, ButtonGroup, MenuItem, Typography } from '@mui/material'
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { useState } from "react";
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { getWidget } from '../../../shared/widgets';
import AddLayout from './add-layout';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import FilterUser from '../filter-user/FilterUser';
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString, splitCamelCase } from '../../../shared/utils/util-functions';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};


const DELETE_LAYOUT_QUERY = gql`
mutation DeleteOneEventLayout($deleteOneEventLayoutId: ID!) {
    deleteOneEventLayout(id: $deleteOneEventLayoutId) {
      success
      errors
    }
  }`


export default function LayoutListPage() {

    const [deleteLayout] = useMutation(DELETE_LAYOUT_QUERY);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [selectedUser, setSelectedUser]: any = React.useState(null);
    const [editMode, setEditMode]: any = React.useState(false);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [filterData, setFilterData]: any = React.useState({});
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(1);
    const childRef = useRef<ChildRef | null>(null);
    const pageMeta = useContext(PageMetaContext);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);


    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Layout");
        }

        dispatch(stopPageLoading());
    }, [pageMeta]);


    useEffect(() => {
        const checkloader = location.pathname.includes('layout-management');

        if (checkloader) {

            dispatch(stopPageLoading());
        }

    })



    const handleRefetch = () => {

        childRef.current?.refetchData({
            search: searchText ?? "",

        });
    };


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }

    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)



    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };




    const ActionBarConfig: WidgetProps[] = [

        {
            type: "search-input",
            placeholder: "Search",
            onChange: onSearchTextChange,
            responsive: {
                xs: 12,
                sm: "auto"
            },
            sx: {
                order: {
                    xs: 3,
                    sm: 2
                }
            }
        },
        {
            type: "button",
            label: t(responsiveString({ xs: "Create", sm: "Create Layout" })),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true);
                setEditMode(false);
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 3
                }
            }
        },

    ]


    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;

        const variables = {
            deleteOneEventLayoutId: selectedUser?.id,
        }

        try {
            const { data } = await deleteLayout({
                variables: variables
            });
            setSelectedUser(null);

            if (data.deleteOneEventLayout.errors?.length > 0) {
                console.error('Error:', data.deleteOneEventLayout.errors);
                const errorIndex = data.deleteOneEventLayout.errors?.length;

                enqueueSnackbar(data.deleteOneEventLayout.errors[errorIndex - 1], { variant: "error" });
            } else {

                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Layout deleted successfully"), { variant: "success" });
                // handleRefetch()
            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }


    }






    const TableConfig: TableColumn[] = [

        {
            label: "Action",
            align: "left",
            width: 130,
            accessor: (row: any) => {
                const isReadOnly = row?.Category?.id;
                return <>
                    <ActionMenu
                        button={{
                            type: "icon-button",
                            icon: <MoreVertOutlined />,
                            size: 'small',
                            color: 'primary',
                            sx: { color: 'white' }
                        }}
                        children={
                            [
                                <MenuItem onClick={() => {
                                    setSelectedUser(row);
                                    setAddModalOpen(true)
                                    setEditMode(true)
                                }} disabled={decodedTokenData.roles !== 'SuperAdmin' && isReadOnly}>{t('Edit')}</MenuItem>,
                                <MenuItem onClick={() => {
                                    setSelectedUser(row);
                                    setDeleteConfirmModal(true)
                                }} disabled={decodedTokenData.roles !== 'SuperAdmin' && isReadOnly}>{t('Delete')}</MenuItem>
                            ]
                        }
                    />
                </>
            }
        }
        ,

        {
            label: "Layout",
            align: "left",
            accessor: (row: any) => row.title
        },

        {
            label: "Layout Type",
            sx: {

                'paddingLeft': '40px'

            },
            align: "left",

            accessor: (row: any) => {

                let eventLayoutOptions = [...row.layoutOptions];
                eventLayoutOptions.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id));


                const buttons = eventLayoutOptions.map((option: any) => {

                    if (option.subOptions.length <= 0) {
                        return <Button type='button' variant='text' style={{ 'color': '#D6D0D0' }} size='small' sx={{ 'marginLeft': '-20px' }}>{`${option.optionTitle} `}</Button>

                    } else {
                        return <ButtonGroup variant="outlined" aria-label="Basic button group" size='small' sx={{ 'marginRight': '25px', 'marginLeft': '-5px' }}>
                            <Button type='button' disabled variant='text' color="primary" size='small'  >{`${option.optionTitle} `}</Button>
                            {
                                option.subOptions.map((so: any) => {
                                    return <Button type='button' variant='text' style={{ 'color': '#D6D0D0' }} size='small'>{`${so.optionTitle} `}</Button>
                                })
                            }
                        </ButtonGroup>
                    }
                })
                if (buttons.length)
                    return <>{buttons}</>
                return <></>;
            }


        }
        ,





    ]

    const QUERY = `query GetAllEventLayouts($layoutId: Int, $pageSize: Int, $page: Int, $search: String) {
  getAllEventLayouts(layoutId: $layoutId, pageSize: $pageSize, page: $page, search: $search) {
    errors
    success
    totalLayouts
    layouts {
      title
      Category {
        id
      }
      Subcategory {
        id
      }
      id
      layoutOptions {
        optionTitle
        id
        subOptions {
          optionTitle
          id
        }
      }
    }
  }
}`


    const onAddUserModalClose = (submitted?: boolean) => {
        setAddModalOpen(false);
        setDeleteConfirmModal(false);
        setSelectedUser(null);
        handleRefetch();
        if (submitted) {
            handleRefetch();
        }
    }




    return (
        <div>
            <TablePageHoc actions={ActionBarConfig}
                columns={TableConfig}
                ref={childRef}
                query={QUERY}
                params={{
                    search: searchText,
                }}
                sourceAccessor={(data) => data.getAllEventLayouts.layouts}
                countAccessor={(data) => data.getAllEventLayouts.totalLayouts}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />

            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedUser ? "Edit Layout" : "Create Layout",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: <AddLayout layout={selectedUser} editMode={editMode} onSave={() => onAddUserModalClose(true)} onClose={onAddUserModalClose} />,
                        size: 'md'
                    })
            }

            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => onAddUserModalClose(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the layout')} ${selectedUser?.title}? ${t('Once deleted, this action cannot be undone!')}`} />
        </div>
    )
}
