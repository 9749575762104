import React, { useEffect, useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';


const GET_CATEGORIES_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int) {
  getAllCategories(search: $search, page: $page, pageSize: $pageSize) {
    totalCategories
    success
    categories {
      categoryName
      id
    }
    errors
  }
}
  `

const GET_SUB_CATEGORIES_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int, $categoryId: Int) {
  getAllSubCategories(search: $search, page: $page, pageSize: $pageSize, categoryId: $categoryId) {
    errors
    totalSubCategories
    success
    subcategories {
      subcategoryName
      id
      Category {
        categoryName
        id
      }
    }
  }
}`


function FilterCustomer({ filterData, onFilterChange, onClose }: any) {

    const { t } = useTranslation();
    const [resetForm, setResetForm] = useState<any>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [category, setCategory] = useState<any>(null);
    const [categoryData, setCategoryData] = useState<any>(null);
    const [subcategoryData, setSubcategoryData] = useState<any>(null);

    const { refetch: categoryRefetch } = useQuery(GET_CATEGORIES_QUERY, { skip: true });
    const { refetch: subcategoryRefetch } = useQuery(GET_SUB_CATEGORIES_QUERY, { skip: true });


    useEffect(() => {
        getCategoryData();
        getSubCategoryData();
    }, [category])

    const getCategoryData = async () => {
        const { data } = await categoryRefetch({
            search: "",
            page: null,
            pageSize: null,

        });
        setCategoryData(data);
        return data;
    }

    const getSubCategoryData = async () => {
        const { data } = await subcategoryRefetch({
            search: "",
            page: null,
            pageSize: null,
            categoryId: parseInt(category)
        });
        setSubcategoryData(data);
        return data;
    }

    const handleSubmit = async (user: any, callback: Function) => {

        const filters = {
            categoryId: parseInt(user.categoryId) || null,
            subcategoryId: parseInt(user.subcategoryId) || null,
        };

        onFilterChange({
            submit: true,
            filters: filters,
            callback
        });

        onClose();
    };


    return (
        
        <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetForm(() => resetForm)}>
            <Box sx={{ width: '300px', padding: '8px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <FormField name='categoryId' value={filterData?.categoryId ?? ""}>
                    <TextField id="categoryId"
                        label={t("Category")}
                        select
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        {categoryData ? categoryData.getAllCategories.categories.map((category: any) => (
                            <MenuItem key={"category" + category.id} value={category.id}>
                                {category.categoryName}
                            </MenuItem>
                        )) : <MenuItem></MenuItem>}
                    </TextField>
                </FormField>

                {
                    category && <FormField name='subcategoryId' value={filterData?.subcategoryId ?? ""}>
                        <TextField id="subcategoryId"
                            label={t("Sub category")}
                            select
                            variant="outlined"
                            sx={{ mb: 10 }}
                            fullWidth={true}
                        >
                            {subcategoryData ? subcategoryData.getAllSubCategories.subcategories.map((category: any) => (
                                <MenuItem key={"subcategory" + category.id} value={category.id}>
                                    {category.subcategoryName}
                                </MenuItem>
                            )) : <MenuItem></MenuItem>}
                        </TextField>
                    </FormField>
                }

                <Grid sx={{ alignSelf: 'end' }}>
                    <Button type="button" variant="text" onClick={() => {
                        resetForm();
                        onFilterChange({
                            submit: true,
                            filters: {
                                role: null,
                                categoryId: null,
                                subcategoryId: null,
                            },
                        });
                        
                    }} color="primary">{t('Clear')}</Button>
                    <Button type="submit" variant="contained" color="primary">{t('Submit')}</Button>
                </Grid>
            </Box>
        </FormGroup>
    )
}

export default FilterCustomer