import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import style from './pagination.module.scss'
import { PaginationWidgetPropsBase } from '../../types/widget-types';





export default function PaginationRounded({
  totalItems,
  itemsPerPage,
  currentPage,
  onChange
}: PaginationWidgetPropsBase) {

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onChange(value);
  };

  return (
    <div className={style.paginationWrapper}>
      <Pagination count={Math.ceil(totalItems / itemsPerPage)} page={currentPage} onChange={handlePageChange}  variant="outlined" shape="rounded" color='secondary'/>
    </div>
  );
}

