import React from 'react'
import { Button, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import style from './successpage.module.scss';
import { SuccessPageProps } from '../../../types/center-page';
import { useTranslation } from 'react-i18next'

function SuccessPagehoc({ onEvent }: SuccessPageProps) {

  const { t } = useTranslation();


  return (
    <div className={style.wrapper}>
      <i className="fa-regular fa-circle-check"></i>
      <Typography variant="h3" component="div" gutterBottom color="primary" sx={{mb:12}}>
        {t('Successful Passsword Changed!')}
      </Typography>
      <Typography variant="subtitle1" component="p" gutterBottom sx={{ mb: 25 }}>
        {t('Your profile password has been changed!')}<br />{t('Please login with your new password')}
      </Typography>
      <Button variant="contained" onClick={() => onEvent?.('sign-in')}>{t('Sign IN')}</Button>
    </div>
  )
}

export default SuccessPagehoc;