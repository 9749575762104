import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TablePropsBase } from '../../types/widget-types';
import { uid as uniqueId } from 'uid';
import { useTranslation } from 'react-i18next'




export default function DataTable({ columns, dataSource, onRowClick, detailPointer }: TablePropsBase) {

  const { t } = useTranslation();


  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="">
        <TableHead>
          <TableRow >
            {
              columns.map((column, index) => (
                <TableCell key={uniqueId(index)} align={column.align} sx={{ width: column.width }}>{t(column.label)}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource.map((row, index) => (
            <TableRow
              key={uniqueId(index)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: detailPointer ? 'pointer' : 'default', }}
              onClick={() => {
                onRowClick?.(row);
              }}
              
            >
              {
                columns.map((column, ci) => (
                  <TableCell key={"row" + uniqueId(row)} align={column.align} sx={{ width: column.width }}>{column.accessor(row)}</TableCell>
                ))
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}