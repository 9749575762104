import React, { useTransition, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Divider, Grid } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { addToast } from '../../../store/reducers/AppSlice';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { MuiTelInput } from 'mui-tel-input'
import MuiTelephoneInput from '../../../shared/widgets/tel-input/MuiTelInput';


const CREATE_CLUB_QUERY = gql`mutation Mutation($clubName: String!, $clubAddress: String!) {
    createClub(clubName: $clubName, clubAddress: $clubAddress) {
      club {
        clubAddress
        clubName
        id
    
      }
      success
      errors
    }
  }
  `

const UPDATE_CLUB_QUERY = gql`mutation Mutation($updateOneClubId: ID!, $clubName: String!, $clubAddress: String!) {
    updateOneClub(id: $updateOneClubId, clubName: $clubName, clubAddress: $clubAddress) {
      club {
        clubAddress
      }
      success
      errors
    }
  }`



function AddClub({ club, onSave, onClose, handleNext, editMode }: any) {

    const [createClub] = useMutation(CREATE_CLUB_QUERY);
    const [updateClub] = useMutation(UPDATE_CLUB_QUERY);
    const [value, setValue] = React.useState('')

    const handleChange = (newValue: any) => {
        setValue(newValue)
    }

    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [resetField, setResetField] = useState<Function | undefined>(undefined);


    const handleSubmit = async (newClub: any, callback: Function) => {
    

        if (club) {
            

            const variables = {
                updateOneClubId: club.id,
                clubName: newClub.clubName,
                clubAddress: newClub.clubAddress,

            }
            
            try {
                const { data } = await updateClub({
                    variables: variables
                });

                if (data.updateOneClub.errors?.length > 0) {
                    console.error('Error:', data.updateOneClub.errors);
                    enqueueSnackbar(data.updateOneClub.errors[0], { variant: "error" });
                    callback();

                } else {
                    
                    enqueueSnackbar(t("Club updated successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }

        } else {
            

            const variables = {
                clubName: newClub.clubName,
                clubAddress: newClub.clubAddress,

            }
            try {
                const { data } = await createClub({
                    variables: variables
                });

                if (data.createClub.errors?.length > 0) {
                    console.error('Error:', data.createClub.errors);
                    enqueueSnackbar(data.createClub.errors[0], { variant: "error" });
                } else {
                    
                    enqueueSnackbar(t("Club created successfully"), { variant: "success" });
                    onSave();
                    handleNext(data.createClub.club);
                    
                }
            } catch (error) {

                console.error('Mutation error:', error);
            }

        }
    };

    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <FormField name='clubName' value={club?.clubName ?? ""} validator={(value: string) => {
                    if (!value) return "Club name is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="clubName"
                        label={t("Club Name")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>
                <FormField name='clubAddress' value={club?.clubAddress ?? ""} validator={(value: string) => {
                    if (!value) return "Club address is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="clubAddress"
                        label={t("Club Address")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>


                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        {/* {editMode ? <Button type="submit" variant="contained" color="primary">{t('Save')}</Button> : <Button type="submit" variant="contained" color="primary">{t('Next')}</Button>} */}
                        <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    )
}

export default AddClub