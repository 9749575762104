import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material'
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { useState } from "react";
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { getWidget } from '../../../shared/widgets';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString, splitCamelCase } from '../../../shared/utils/util-functions';
import SlotEvaluvatorList from './slot-evaluator-list';
import SubmittedSlotPlayersList from './slot-players-list';
import DownloadIcon from '@mui/icons-material/Download';
import EventFilter from './filter-event';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment';


const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from
          to
        }
        success
      }
    }
  `


type ChildRef = {
    refetchData: (data: any) => void;
};


export default function LayoutListPage(props: any) {

    const navigate = useNavigate();
    const [selectedRow, setSelectedRow]: any = React.useState(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [filterData, setFilterData]: any = React.useState({});
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(1);
    const childRef = useRef<ChildRef | null>(null);
    const pageMeta = useContext(PageMetaContext);
    const { enqueueSnackbar } = useSnackbar();
    const [hideAction, setHideAction] = React.useState(false);
    const [slotId, setSlotId]: any = React.useState(null);
    const [requestEventId, setRequestEventId]: any = React.useState(null);
    const [eventId, setEventId]: any = React.useState(null);
    const [slotEvaluatorsModal, setSlotEvaluatorsModal] = React.useState(false);
    const [slotPlayersModal, setSlotPlayersModal] = React.useState(false);
    const [addEvaluatorModalOpen, setAddEvaluatorModalOpen] = React.useState(false);
    const [addPlayerModalOpen, setAddPlayerModalOpen] = React.useState(false);
    const [downloadConfirmModal, setDownloadConfirmModal] = React.useState(false);
    const { refetch: latesteventfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });
    const { t } = useTranslation();

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Events");
        }
    }, [pageMeta]);


    useEffect(() => {
        getLatestEvents();
    }, [])


    const getLatestEvents = async () => {
        const { data } = await latesteventfetch({
            search: "",
            page: null,
            pageSize: null
        });

        let reqId: any = null;

        if (data?.getRequestEvents.requestEvents.length > 0) {
            reqId = data?.getRequestEvents.requestEvents[0].id;
            setRequestEventId(reqId);
        }

        setTimeout(() => {

            setFilterData({
                requestEventId: parseInt(reqId),

            });
        }, 1000)
        return data;
    }


    const handleRefetch = () => {


        childRef.current?.refetchData({
            search: searchText,
            seasonId: filterData.requestEventId ? filterData.requestEventId : parseInt(props.season),
            location: filterData?.location,
            associationId: filterData?.associationId
            // userId:decodedTokenData.roles === 'Evaluator' ? decodedTokenData.id : null
        });
    };


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }


    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)



    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };



    const [cookies] = useCookies(['token']);

    const token = cookies.token;


    const download = async () => {
        setDownloadConfirmModal(false);

        try {
            // const BASE_URL = "http://localhost:6767";
            // const BASE_URL = "https://evaluva-api.bixbytessolutions.com";
            const BASE_URL = "https://evalua-live-api.bixbytessolutions.com";
            const response = await fetch(BASE_URL + '/evaluation-download?' + new URLSearchParams({
                slotId: slotId,
                eventId: selectedRow.eventId,

            }), {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.status === 200) {
                enqueueSnackbar(t("Slot player list downloaded successfully"), { variant: "success" });
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                const day = currentDate.getDate().toString().padStart(2, '0');
                const hours = currentDate.getHours().toString();
                const minutes = currentDate.getMinutes().toString();

                const currentDateTime = `${month}_${day}_${year}_${hours}:${minutes}`;

                link.download = `${t('Playerlist')}-${currentDateTime}.xlsx`;

                link.target = '_blank';


                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                URL.revokeObjectURL(link.href);
            }
            else if (response.status === 404) {
                enqueueSnackbar(t("No players were found for downloading"), { variant: "error" });

            }
            ;

        } catch (error) {
            console.log('An error occurred while downloading the player list:', error);
        }
    }




    const ActionBarConfig: WidgetProps[] = [
        {
            type: "filter-menu",
            children: <EventFilter filterData={filterData} onFilterChange={handleFilterChange} />,
            sx: {
                order: 1
            }
        },
        {
            type: "search-input",
            placeholder: "Search",
            onChange: onSearchTextChange,
            responsive: {
                xs: 12,
                sm: "auto"
            },
            sx: {
                order: {
                    xs: 3,
                    sm: 2
                }
            }
        }

    ]






    const handleSlotEventEvaluvators = (row: any, e: any) => {
        e.stopPropagation();
        setSelectedRow(row);
        setSlotId(row.slotId);
        setHideAction(true);
        setSlotEvaluatorsModal(true)
    }

    const handleSlotEventPlayers = (row: any, e: any) => {
        // e.stopPropagation();
        setSlotId(row.slotId)
        setEventId(row.eventId)
        setSlotPlayersModal(true)
    }



    const TableConfig = useMemo(() => {
        const config = [
            ...(decodedTokenData.roles !== 'Evaluator' ? (
                [
                    {
                        label: "Action",
                        align: "left",
                        width: 130,
                        accessor: (row: any) => {
                            return <>
                                <ActionMenu
                                    button={{
                                        type: "icon-button",
                                        icon: <MoreVertOutlined />,
                                        size: 'small',
                                        color: 'primary',
                                        sx: { color: 'white' }
                                    }}
                                    children={
                                        [
                                            <MenuItem onClick={() => {
                                                setSelectedRow(row);
                                                setDownloadConfirmModal(true);
                                                setEventId(row.id);
                                                setSlotId(row.slotId)
                                            }}><DownloadIcon />{t('Download')}</MenuItem>
                                        ]
                                    }
                                />
                            </>
                        }
                    },
                ]
            ) : []),

            {
                label: "Event Name",
                align: "left",
                accessor: (row: any) => row?.title
            },
            {
                label: "Slot Name",
                align: "left",
                accessor: (row: any) => row?.slottitle
            },
            {
                label: "Event Start",
                align: "left",
                accessor: (row: any) => moment(new Date(parseInt(row?.startTime))).format("DD.MM.YYYY HH:mm")
            },
            {
                label: "Event End",
                align: "left",
                accessor: (row: any) => moment(new Date(parseInt(row?.endTime))).format("DD.MM.YYYY HH:mm")
            },
            {
                label: "Location",
                align: "left",
                accessor: (row: any) => row?.location
            },
            {
                label: "Layout Type",
                align: "left",
                accessor: (row: any) => row?.layout
            },

            ...(decodedTokenData.roles !== 'Evaluator' ? (
                [
                    {
                        label: "Evaluator",
                        align: "left",
                        accessor: (row: any) => <Button type="submit" variant="text" color="secondary" onClick={(e) => handleSlotEventEvaluvators(row, e)}>{row.userscount}</Button>

                    }
                ]
            ) : [])
            ,
            {
                label: "Player",
                align: "left",
                accessor: (row: any) => <Button type="submit" variant="text" color="secondary" onClick={(e) => handleSlotEventPlayers(row, e)}>{row.owner === 'ClubAdmin' ? row.playerscount : row.assnplayerscount}</Button>
            },
            {
                label: "Created By",
                align: "left",
                accessor: (row: any) => row?.createdBy
            }
        ]
        return config

    }, [decodedTokenData])




    const QUERY = `query GetAllEvents($search: String, $page: Int, $pageSize: Int, $seasonId: Int, $location:String, $associationId: Int) {
        getAllEvents(search: $search, page: $page, pageSize: $pageSize, seasonId: $seasonId, location:$location,  associationId:$associationId) {
          errors
          success
          events {
            title
            eventId
            location
            slottitle
            endTime
            playerscount
            owner
            assnplayerscount
            slotId
            startTime
            userscount
            layout
            createdBy
            layoutId
            seasonId
          }
          totalEvents
        }
      }`






    const onShareEventModalClose = () => {
        // setShareModalOpen(false);
        setAddEvaluatorModalOpen(false);
        setSlotEvaluatorsModal(false);
        setSlotPlayersModal(false);
        setSelectedRow(null);
        handleRefetch();

    }




    return (
        <div>
            <TablePageHoc actions={ActionBarConfig}
                columns={TableConfig as TableColumn[]}
                ref={childRef}
                query={QUERY}
                params={{
                    search: searchText,
                    seasonId: filterData.requestEventId ? filterData.requestEventId : parseInt(props.season),
                    location: filterData?.location,
                    associationId: filterData?.associationId
                    // userId:decodedTokenData.roles === 'Evaluator' ? decodedTokenData.id : null

                }}
                sourceAccessor={(data) => data.getAllEvents.events}
                countAccessor={(data) => data.getAllEvents.totalEvents}
                onRowClick={(row) => {

                    navigate(`/admin/event-management/detail?eventId=${row.eventId}&slotId=${row.slotId}`)
                }}
                detailPointer="pointer"

                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Evaluators",

                        modalOpen: slotEvaluatorsModal,
                        onClose: () => onShareEventModalClose(),
                        children: <SlotEvaluvatorList event={selectedRow} onSave={() => onShareEventModalClose()} onClose={onShareEventModalClose} slotId={slotId} modalOpen={addEvaluatorModalOpen} onAddClose={() => setAddEvaluatorModalOpen(false)} editMode={hideAction} />,
                        size: 'lg'
                    })
            }
            {
                getWidget(
                    {
                        type: "modal",
                        title: "Players",
                        modalOpen: slotPlayersModal,
                        onClose: () => onShareEventModalClose(),
                        children: <SubmittedSlotPlayersList event={selectedRow} onSave={() => onShareEventModalClose()} onClose={onShareEventModalClose} slotId={slotId} eventId={eventId} modalOpen={addPlayerModalOpen} onAddClose={() => setAddPlayerModalOpen(false)} />,
                        size: 'lg'
                    })
            }
            <ConfirmModal modalOpen={downloadConfirmModal} onClose={() => setDownloadConfirmModal(false)} onConfirm={() => download()} title={t('Are you sure?')} children={t('Would you like to download the slot players list now?')} />

        </div>
    )
}
