import React, { useTransition, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';


const CREATE_PACKAGE_QUERY = gql`mutation CreatePackage($packageName: String!, $eventLimit: Int, $cost: Float!, $adminLimit: Int) {
  createPackage(packageName: $packageName, eventLimit: $eventLimit, cost: $cost, adminLimit: $adminLimit) {
    success
    errors
  }
}
  `

const UPDATE_PACKAGE_QUERY = gql`mutation UpdateOnePackage($updateOnePackageId: ID!, $packageName: String!, $eventLimit: Int, $cost: Float!, $adminLimit: Int) {
  updateOnePackage(id: $updateOnePackageId, packageName: $packageName, eventLimit: $eventLimit, cost: $cost, adminLimit: $adminLimit) {
    success
    package {
      packageName
      id
      eventLimit
      cost
    }
    errors
  }
}`



function CreatePackage({ evaluaPackage, onSave, onClose, handleNext, editMode }: any) {

  const [createPackage] = useMutation(CREATE_PACKAGE_QUERY);
  const [updatePackage] = useMutation(UPDATE_PACKAGE_QUERY);
  const [value, setValue] = React.useState('')

  const handleChange = (newValue: any) => {
    setValue(newValue)
  }

  console.log("---",evaluaPackage)
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [resetField, setResetField] = useState<Function | undefined>(undefined);
  const [adminLimit, setAdminLimit] = useState(!evaluaPackage?.adminLimit ? false : true );
  const [eventLimit, setEventLimit] = useState(!evaluaPackage?.eventLimit ? false : true);

  const handleAdminLimit = () => {
    setAdminLimit(!adminLimit)
  }


  const handleEventLimit = () => {
    setEventLimit(!eventLimit)
  }

  const handleSubmit = async (newPackage: any, callback: Function) => {

    if (evaluaPackage) {

      const variables = {
        updateOnePackageId: evaluaPackage.id,
        packageName: newPackage.packageName,
        eventLimit: parseInt(newPackage.eventLimit),
        adminLimit: parseInt(newPackage.adminLimit),
        cost: parseFloat(newPackage.cost)
      }

      try {
        const { data } = await updatePackage({
          variables: variables
        });

        if (data.updateOnePackage.errors?.length > 0) {
          console.error('Error:', data.updateOnePackage.errors);
          enqueueSnackbar(data.updateOnePackage.errors[0], { variant: "error" });
          callback();
        } else {
          enqueueSnackbar(t("Package updated successfully"), { variant: "success" });
          onSave();
        }
      } catch (error) {
        console.error('Mutation error:', error);
        callback();
      }

    } else {


      const variables = {
        packageName: newPackage.packageName,
        eventLimit: parseInt(newPackage.eventLimit),
        adminLimit: parseInt(newPackage.adminLimit),
        cost: parseFloat(newPackage.cost)

      }
      try {
        const { data } = await createPackage({
          variables: variables
        });

        if (data.createPackage.errors?.length > 0) {
          console.error('Error:', data.createPackage.errors);
          enqueueSnackbar(data.createPackage.errors[0], { variant: "error" });
        } else {

          enqueueSnackbar(t("Package created successfully"), { variant: "success" });
          onSave();
          // handleNext(data.createClub.club);

        }
      } catch (error) {

        console.error('Mutation error:', error);
      }

    }
  };

  return (
    <div>
      <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
        <FormField name='packageName' value={evaluaPackage?.packageName ?? ""} validator={(value: string) => {
          if (!value) return "Package name is required."
          return null
        }} validateOnChange={false}>
          <TextField id="packageName"
            label={t("Package Name")}
            variant="outlined"
            sx={{ mb: 10 }}
            fullWidth={true}
          />
        </FormField>
        <FormField name='cost' value={evaluaPackage?.cost ?? ""} validator={(value: string) => {
          if (!value) return "Cost is required."
          return null
        }} validateOnChange={false}>
          <TextField id="cost"
            label={t("Cost")}
            variant="outlined"
            placeholder='CHF'
            sx={{ mb: 10 }}
            fullWidth={true}
          />
        </FormField>
        <FormControlLabel disabled={evaluaPackage?.adminLimit} control={<Checkbox checked={adminLimit}
          onChange={handleAdminLimit} />} label={t("Admin limit")} sx={{ 'marginBottom': '10px' }} />
        <FormControlLabel disabled={evaluaPackage?.eventLimit} control={<Checkbox checked={eventLimit}
          onChange={handleEventLimit} />} label={t("Event limit")} sx={{ 'marginBottom': '10px' }} />

        {
          adminLimit && <FormField name='adminLimit' value={evaluaPackage?.adminLimit ?? ""} validator={(value: string) => {
            if (!value) return "Admin limit is required."
            return null
          }} validateOnChange={false}>
            <TextField id="adminLimit"
              label={t("Admin Limit")}
              variant="outlined"
              sx={{ mb: 10 }}
              fullWidth={true}
            />
          </FormField>
        }

        {
          eventLimit &&
          <FormField name='eventLimit' value={evaluaPackage?.eventLimit ?? ""} validator={(value: string) => {
            if (!value) return "Event limit is required."
            return null
          }} validateOnChange={false}>
            <TextField id="eventLimit"
              label={t("Event Limit")}
              variant="outlined"
              sx={{ mb: 10 }}
              fullWidth={true}
            />
          </FormField>
        }


        <Divider sx={{ mx: -24, mb: 20 }} />
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs={"auto"}>
            <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
          </Grid>
          <Grid item xs={"auto"}>
            {/* {editMode ? <Button type="submit" variant="contained" color="primary">{t('Save')}</Button> : <Button type="submit" variant="contained" color="primary">{t('Next')}</Button>} */}
            <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  )
}

export default CreatePackage

