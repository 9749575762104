import React, { useEffect, useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';




const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from 
          to
        }
        success
      }
    }
  `

const GET_UNIQUE_LOCATIONS = gql`
query GetAllUniqueLocations($search: String, $page: Int, $pageSize: Int) {
    getAllUniqueLocations(search: $search, page: $page, pageSize: $pageSize) {
      errors
      success
      location
    }
  }
`

const GET_ASSOCIATIONS_QUERY = gql`query GetAllAssociations($clubId: Int, $search: String, $page: Int, $pageSize: Int) {
    getAllAssociations(clubId: $clubId, search: $search, page: $page, pageSize: $pageSize) {
      associations {
        associationName
        id
      }
      errors
      success
      totalAssociations
    }
  }`



function EventFilter({ filterData, onFilterChange, onClose }: any) {

    const [resetForm, setResetForm] = useState<any>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [associationData, setAssociationData] = useState<any>(null);
    const [seasonPlayers, setSeasonPlayers] = useState<any>(null);
    const [locations, setLocations] = useState<any>(null);
    const { t } = useTranslation();



    const { refetch: seasonfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });
    const { refetch: associationFetch } = useQuery(GET_ASSOCIATIONS_QUERY, { skip: true });
    const { refetch: locationfetch } = useQuery(GET_UNIQUE_LOCATIONS, { skip: true });



    useEffect(() => {
        
        getSeasonPlayers();
        getUniqueLocations();
        if (decodedTokenData.roles !== 'AssociationAdmin') {

            let clubId = filterData?.clubId ?? decodedTokenData.ClubId;
            if (clubId) {
                getAssociationData(parseInt(clubId))
            }
        }

    }, [])

    

    const getSeasonPlayers = async () => {
        const { data } = await seasonfetch({
            search: "",
            page: null,
            pageSize: null
        });
        setSeasonPlayers(data);
        return data;
    }



    const getUniqueLocations = async () => {
        const { data } = await locationfetch({
            search: "",
            page: null,
            pageSize: null
        });
        setLocations(data);
        return data;
    }

    const getAssociationData = async (clubId: any) => {
        if (!clubId) return;
        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId
        });
        setAssociationData(data);
        return data;
    }




    const handleSubmit = async (user: any) => {

        const filters = {
            location: user.location || null,
            requestEventId: parseInt(user.requestEventId) || null,
            associationId: parseInt(user.associationId) || null,
        };

        
        onFilterChange({
            submit: true,
            filters: filters,
        });

        onClose();

    };

    const extractYear = (timestamp: any) => {
        const date = new Date(parseInt(timestamp));
        return date.getFullYear();
    };

    return (
        <div>

            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetForm((field: string, value?: string) => resetField)}>
                <Box sx={{ width: '300px', padding: '8px 16px', display: 'flex', flexDirection: 'column' }}>


                    <FormField name='location' value={filterData?.location ?? ""}>
                        <TextField id="location"
                            label={t("Location")}
                            select
                            variant="outlined"
                            fullWidth={true}
                            sx={{ mb: 10 }}

                        >
                            {locations ? locations.getAllUniqueLocations.location.map((location: any) => (
                                <MenuItem value={location} >
                                    {location}
                                </MenuItem>
                            )) : <MenuItem></MenuItem>}
                        </TextField>
                    </FormField>

                    {/* {
                        decodedTokenData.roles === 'ClubAdmin' && <FormField name='associationId' value={filterData?.associationId ?? ""}>
                            <TextField id="associations"
                                label={t("Association")}
                                select
                                variant="outlined"
                                fullWidth={true}
                                sx={{ mb: 10 }}
                            // onChange={(e: any) => {
                            //     associationFetch({
                            //         clubId: parseInt(e.target.value)
                            //     })
                            // }}
                            >
                                {associationData ? associationData.getAllAssociations.associations.map((association: any) => (
                                    <MenuItem key={"assoc" + association.id} value={association.id} >
                                        {association.associationName}
                                    </MenuItem>
                                )) : <MenuItem></MenuItem>}
                            </TextField>
                        </FormField>
                    } */}


                    <FormField name='requestEventId' value={filterData?.requestEventId ?? ""}>
                        <TextField id="season"
                            label={t("Season")}
                            select
                            variant="outlined"
                            fullWidth={true}
                            sx={{ mb: 10 }}

                        >
                            {seasonPlayers ? seasonPlayers.getRequestEvents.requestEvents.map((season: any) => (
                                <MenuItem key={"assoc" + season.id} value={season.id} >
                                    {extractYear(season.from)}-{extractYear(season.to)}

                                </MenuItem>
                            )) : <MenuItem></MenuItem>}
                        </TextField>
                    </FormField>
                    <Grid sx={{ alignSelf: 'end' }}>
                        <Button type="button" variant="text" onClick={() => {

                            resetForm('location');
                            resetForm('associationId');


                            let reqId = "";
                            if (seasonPlayers.getRequestEvents.requestEvents.length > 0) {
                                reqId = seasonPlayers.getRequestEvents.requestEvents[0]['id'];
                            }
                            resetForm('requestEventId', reqId);
                            onFilterChange({
                                submit: true,
                                filters: {
                                    location: null,
                                    requestEventId: parseInt(reqId),
                                    associationId: null
                                },
                            });

                        }} color="primary">{t('Clear')}</Button>
                        <Button type="submit" variant="contained" color="primary">{t('Submit')}</Button>
                    </Grid>
                </Box>
            </FormGroup>

        </div>
    )
}

export default EventFilter