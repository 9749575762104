import React, { useTransition, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Divider, Grid, MenuItem } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';



const CREATE_SUB_CATEGORY_QUERY = gql`mutation Mutation($subcategoryName: String!, $categoryId: Int!) {
  createSubCategory(subcategoryName: $subcategoryName, categoryId: $categoryId) {
    success
    errors
  }
}
  `

const UPDATE_SUB_CATEGORY_QUERY = gql`mutation UpdateOneSubCategory($updateOneSubCategoryId: ID!, $subcategoryName: String!, $categoryId: ID!) {
  updateOneSubCategory(id: $updateOneSubCategoryId, subcategoryName: $subcategoryName, CategoryId: $categoryId) {
    errors
    success
  }
}`



const GET_CATEGORY_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int) {
  getAllCategories(search: $search, page: $page, pageSize: $pageSize) {
    totalCategories
    success
    categories {
      categoryName
      id
    }
    errors
  }
}
`

function AddSubCategory({ category, onSave, onClose, handleNext, editMode }: any) {

  const [createSubCategory] = useMutation(CREATE_SUB_CATEGORY_QUERY);
  const [updateSubCategory] = useMutation(UPDATE_SUB_CATEGORY_QUERY);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [resetField, setResetField] = useState<Function | undefined>(undefined);
  const { loading: categoryLoading, error: categoryError, data: categoriesData } = useQuery(GET_CATEGORY_QUERY, {
    variables: {
      search: null, page: null
    }
  });


  const handleSubmit = async (newCategory: any, callback: Function) => {


    if (category) {
      const variables = {
        updateOneSubCategoryId: category.id,
        subcategoryName: newCategory.subcategoryName,
        categoryId:newCategory.categoryId
      }

      try {
        const { data } = await updateSubCategory({
          variables: variables
        });

        if (data.updateOneSubCategory.errors?.length > 0) {
          console.error('Error:', data.updateOneSubCategory.errors);
          enqueueSnackbar(data.updateOneSubCategory.errors[0], { variant: "error" });
          callback();
        } else {
          enqueueSnackbar(t("Category updated successfully"), { variant: "success" });
          onSave();
        }
      } catch (error) {
        console.error('Mutation error:', error);
        callback();
      }

    } else {

      const variables = {
        subcategoryName: newCategory.subcategoryName,
        categoryId:parseInt(newCategory.categoryId)
      }
      try {
        const { data } = await createSubCategory({
          variables: variables
        });

        if (data.createSubCategory.errors?.length > 0) {
          console.error('Error:', data.createSubCategory.errors);
          enqueueSnackbar(data.createSubCategory.errors[0], { variant: "error" });
        } else {
          enqueueSnackbar(t("Category created successfully"), { variant: "success" });
          onSave();
        }
      } catch (error) {
        console.error('Mutation error:', error);
      }

    }
  };

  return (
    <div>
      <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
        <FormField name='subcategoryName' value={category?.subcategoryName ?? ""} validator={(value: string) => {
          if (!value) return "Category name is required."
          return null
        }} validateOnChange={false}>
          <TextField id="categoryName"
            label={t("Category Name")}
            variant="outlined"
            sx={{ mb: 10 }}
            fullWidth={true}
          />
        </FormField>

        <FormField name='categoryId' value={category?.Category?.id ?? ""} validator={(value: string) => {
          if (!value) return "Category selection is required.";
          return null
        }} validateOnChange={false}>
          <TextField id="category"
            label={t("Category")}
            select
            variant="outlined"
            sx={{ mb: 10 }}
            fullWidth={true}
          >
            {!categoryLoading && !categoryError ? categoriesData.getAllCategories.categories.map((category: any) => (
              <MenuItem key={"category" + category.id} value={category.id}>
                {category.categoryName}
              </MenuItem>
            )) : <MenuItem>{t('Loading')}</MenuItem>}
          </TextField>
        </FormField>


        <Divider sx={{ mx: -24, mb: 20 }} />
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs={"auto"}>
            <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
          </Grid>
          <Grid item xs={"auto"}>
            {/* {editMode ? <Button type="submit" variant="contained" color="primary">{t('Save')}</Button> : <Button type="submit" variant="contained" color="primary">{t('Next')}</Button>} */}
            <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  )
}

export default AddSubCategory