import React, { useEffect, useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';




const GET_ASSOCIATIONS_QUERY = gql`query GetAllAssociations($clubId: Int, $search: String, $page: Int, $pageSize: Int) {
    getAllAssociations(clubId: $clubId, search: $search, page: $page, pageSize: $pageSize) {
      associations {
        associationName
        id
      }
      errors
      success
      totalAssociations
    }
  }`




function SlotPlayerFilter({ filterData, onFilterChange, onClose }: any) {

    const [resetForm, setResetForm] = useState<any>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [associationData, setAssociationData] = useState<any>(null);
    const { t } = useTranslation();
    const { refetch: associationFetch } = useQuery(GET_ASSOCIATIONS_QUERY, { skip: true });


    useEffect(() => {
        
        let clubId = decodedTokenData.ClubId;
        if (clubId) {
            getAssociationData(parseInt(clubId))
        }
    }, [])



    const getAssociationData = async (clubId: any) => {
        if (!clubId) return;
        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId
        });
        setAssociationData(data);
        return data;
    }


    const handleSubmit = async (user: any, callback: Function) => {

        const filters = {
            associationId: parseInt(user.associationId) || null,
        };

        onFilterChange({
            submit: true,
            filters: filters,
            callback
        });

        onClose();

    };



    return (
        <div>

            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetForm((field: string, value?: string) => resetField)}>
                <Box sx={{ width: '300px', padding: '8px 16px', display: 'flex', flexDirection: 'column' }}>

                    <FormField name='associationId' value={filterData?.associationId ?? ""}>
                        <TextField id="associationId"
                            label={t("Association")}
                            select
                            variant="outlined"
                            fullWidth={true}
                            sx={{ mb: 10 }}
                        >
                            {associationData ? associationData.getAllAssociations.associations.map((association: any) => (
                                <MenuItem key={"assoc" + association.id} value={association.id} >
                                    {association.associationName}
                                </MenuItem>
                            )) : <MenuItem></MenuItem>}
                        </TextField>
                    </FormField>


                    <Grid sx={{ alignSelf: 'end' }}>
                        <Button type="button" variant="text" onClick={() => {

                            resetForm("associationId");

                            onFilterChange({
                                submit: true,
                                filters: {
                                    associationId: null,
                                },
                            });

                        }} color="primary">{t('Clear')}</Button>
                        <Button type="submit" variant="contained" color="primary">{t('Submit')}</Button>
                    </Grid>
                </Box>
            </FormGroup>

        </div>
    )
}

export default SlotPlayerFilter