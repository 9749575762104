import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { Button, MenuItem } from '@mui/material'
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { useState } from "react";
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { getWidget } from '../../../shared/widgets';
import AddPlayer from './add-event-players';
import AddAssociationPlayer from "../association-player-management/add-assnevent-players";
import AddEvaluator from './add-event-evaluators';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString, splitCamelCase } from '../../../shared/utils/util-functions';
import EventGeneration from './event-generation';
import SlotEvaluvatorList from './slot-evaluator-list';
import EventFilter from './filter-event';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import moment from 'moment';



const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from
          to
        }
        success
      }
    }
  `


const DELETE_EVENT_SLOT_QUERY = gql`mutation Mutation($deleteOneEventSlotId: ID!) {
    deleteOneEventSlot(id: $deleteOneEventSlotId) {
      success
      errors
    }
  }`


const SEND_EMAIL_QUERY = gql`
mutation ShareEvent($userId: Int, $slotId: Int, $eventId: Int) {
    shareEvent(userId: $userId, slotId: $slotId, eventId: $eventId) {
      success
      errors
    }
  }
`


const DELETE_EVENT_QUERY = gql`
mutation Mutation($deleteOneEventId: ID!, $slotId: Int ) {
    deleteOneEvent(id: $deleteOneEventId, slotId: $slotId) {
      success
      errors
      event {
        title
      }
    }
  }
`

type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};


export default function OngoingEventListPage(props: any) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [shareModalOpen, setShareModalOpen] = React.useState(false);
    const [selectedEvent, setSelectedEvent]: any = React.useState(null);
    const [requestEventId, setRequestEventId] = useState(null);
    const [editMode, setEditMode]: any = React.useState(false);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [filterData, setFilterData]: any = React.useState({});
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(1);
    const childRef = useRef<ChildRef | null>(null);
    const pageMeta = useContext(PageMetaContext);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [deleteEventConfirmModal, setDeleteEventConfirmModal] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [slotId, setSlotId]: any = React.useState(null);
    const [eventId, setEventId]: any = React.useState(null);
    const [eventEvaluatorListModal, setEventEvaluatorListModal] = React.useState(false);
    const [eventPlayerListModal, setEventPlayerListModal] = React.useState(false);
    const [eventAssociationPlayerListModal, setEventAssociationPlayerListModal] = React.useState(false);
    const [slotEvaluatorsModal, setSlotEvaluatorsModal] = React.useState(false);
    const [addEvaluatorModalOpen, setAddEvaluatorModalOpen] = React.useState(false);
    const location = useLocation();
    const { refetch: latesteventfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });
    const [deleteEventSlot] = useMutation(DELETE_EVENT_SLOT_QUERY);
    const [deleteEvent] = useMutation(DELETE_EVENT_QUERY);
    const [sendEmai] = useMutation(SEND_EMAIL_QUERY);
    const { t } = useTranslation();




    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Events");
        }
        getLatestEvents();

    }, [pageMeta]);

    useEffect(() => {

        const checkloader = location.pathname.includes('event-management');

        if (checkloader) {
            dispatch(stopPageLoading());
        }

    })






    const getLatestEvents = async () => {
        const { data } = await latesteventfetch({
            search: "",
            page: null,
            pageSize: null
        });

        let reqId: any = null;

        if (data?.getRequestEvents.requestEvents.length > 0) {
            reqId = data?.getRequestEvents.requestEvents[0].id;
            setRequestEventId(reqId);

        }

        setTimeout(() => {
            setFilterData({
                requestEventId: parseInt(reqId),
            });
        }, 1000)
        return data;
    }


    const handleRefetch = () => {

        childRef.current?.refetchData({
            search: searchText,
            seasonId: filterData.requestEventId ? filterData.requestEventId : parseInt(props.season),
            location: filterData?.location,
            associationId: filterData?.associationId

        });
    };


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }

    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)



    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };



    const ActionBarConfig = useMemo(() => {
        const config = [

            {
                type: "filter-menu",
                children: <EventFilter filterData={filterData} onFilterChange={handleFilterChange} />,
                sx: {
                    order: 1
                }
            },
            {
                type: "search-input",
                placeholder: "Search",
                onChange: onSearchTextChange,
                responsive: {
                    xs: 12,
                    sm: "auto"
                },
                sx: {
                    order: {
                        xs: 3,
                        sm: 2
                    }
                }
            },
            ...((decodedTokenData.roles !== 'Evaluator') ? [
                {
                    type: "button",
                    label: t(responsiveString({ xs: "Create", sm: "Create Event" })),
                    startIcon: <Add />,
                    onClick: () => {
                        setAddModalOpen(true);
                        setEditMode(false);
                    },
                    sx: {
                        order: {
                            xs: 2,
                            sm: 3
                        }
                    }
                },

            ] : []),


        ]
        return config;
    }, [decodedTokenData, filterData]);


    const deleteEventRow = async () => {
        setDeleteEventConfirmModal(false);

        if (!selectedEvent) return;
        const variables = {
            deleteOneEventId: selectedEvent?.eventId,
            slotId: selectedEvent?.slotId

        }

        try {
            const { data } = await deleteEvent({
                variables: variables
            });
            setSelectedEvent(null);

            if (data.deleteOneEvent?.errors?.length > 0) {
                const errorIndex = data.deleteOneEvent?.errors?.length;
                enqueueSnackbar(data.deleteOneEvent.errors[errorIndex - 1], { variant: "error" });
            } else {
                enqueueSnackbar(t("Event deleted successfully"), { variant: "success" });
                handleRefetch()
            }
        } catch (error) {
            setSelectedEvent(null);
            console.error('Mutation error:', error);
        }
    }







    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedEvent) return;
        const variables = {
            deleteOneEventSlotId: selectedEvent?.slotId
        }

        try {
            const { data } = await deleteEventSlot({
                variables: variables
            });
            setSelectedEvent(null);

            if (data.deleteOneEventSlot?.errors?.length > 0) {
                const errorIndex = data.deleteOneEventSlot?.errors?.length;
                enqueueSnackbar(data.deleteOneEventSlot.errors[errorIndex - 1], { variant: "error" });
            } else {
                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Event slot deleted successfully"), { variant: "success" });

            }
        } catch (error) {
            setSelectedEvent(null);
            console.error('Mutation error:', error);
        }
    }


    const sendEmail = async () => {

        setShareModalOpen(false);
        if (!selectedEvent) return;

        const variables = {
            slotId: selectedEvent?.slotId,
            eventId: selectedEvent?.eventId,
            userId: null
        }

        try {
            const { data } = await sendEmai({
                variables: variables
            });


            if (data.shareEvent?.errors?.length > 0) {
                enqueueSnackbar(data.shareEvent.errors[0], { variant: "error" });
            } else {
                enqueueSnackbar(t("Email sent successfully"), { variant: "success" });
                handleRefetch()
            }
        } catch (error) {
            console.error('Mutation error:', error);
        }

    }



    const handleEventEvaluvators = (e: any, row: any) => {

        e.stopPropagation();
        setSlotId(row.slotId)
        setEventEvaluatorListModal(true)
    }




    const handleSlotEventEvaluvators = (e: any, row: any) => {
        e.stopPropagation();
        setSelectedEvent(row);
        setSlotId(row.slotId)
        setSlotEvaluatorsModal(true)
    }

    const handleEventPlayers = (e: any, row: any) => {
        e.stopPropagation();
        // setSelectedUser(row);

        setEditMode(true)
        setSlotId(row.slotId)
        setEventId(row.eventId)
        if (decodedTokenData.roles !== 'AssociationAdmin' &&  decodedTokenData.roles !== 'Admin') {
            setEventPlayerListModal(true)
        } else {
            setEventAssociationPlayerListModal(true)
        }


    }



    const TableConfig = useMemo(() => {
        const config = [
            ...(decodedTokenData.roles !== 'Evaluator' ? (
                [
                    {
                        label: "Action",
                        align: "left",
                        width: 130,
                        accessor: (row: any) => {
                            return <>
                                <ActionMenu
                                    button={{
                                        type: "icon-button",
                                        icon: <MoreVertOutlined />,
                                        size: 'small',
                                        color: 'primary',
                                        sx: { color: 'white' }
                                    }}
                                    children={
                                        [

                                            <MenuItem onClick={() => {
                                                setSelectedEvent(row);
                                                setAddModalOpen(true);
                                            }}>{t('Edit')}</MenuItem>,
                                            <MenuItem onClick={() => {
                                                setSelectedEvent(row);
                                                setDeleteEventConfirmModal(true)
                                            }}>{t('Delete Event')}</MenuItem>,
                                            <MenuItem onClick={() => {
                                                setSelectedEvent(row);
                                                setDeleteConfirmModal(true)
                                            }}>{t('Delete Slot')}</MenuItem>,
                                            <MenuItem onClick={() => {
                                                setSelectedEvent(row);
                                                {
                                                    if (row.userscount !== 0) {
                                                        setShareModalOpen(true);
                                                    } else {
                                                        enqueueSnackbar(t("Evaluators are not added to the slot"), { variant: "error" });
                                                    }
                                                }

                                                setSlotId(row.id);
                                            }}>{t('Share')}</MenuItem>
                                        ]
                                    }
                                />
                            </>
                        }
                    }

                ]
            ) : []),

            {
                label: "Event Name",
                align: "left",
                accessor: (row: any) => {
                    return row?.title
                }
            },
            {
                label: "Slot Name",
                align: "left",
                accessor: (row: any) => row?.slottitle
            },
            {
                label: "Event Start",
                align: "left",
                accessor: (row: any) => moment(new Date(parseInt(row?.startTime))).format("DD.MM.YYYY HH:mm")
            },
            {
                label: "Event End",
                align: "left",
                accessor: (row: any) => moment(new Date(parseInt(row?.endTime))).format("DD.MM.YYYY HH:mm")
            },
            {
                label: "Location",
                align: "left",
                accessor: (row: any) => row?.location
            },
            {
                label: "Layout Type",
                align: "left",
                accessor: (row: any) => row?.layout
            },
            ...(decodedTokenData.roles !== 'Evaluator' ? (
                [
                    {
                        label: "Evaluator",
                        align: "center",
                        accessor: (row: any) => {
                            if (row.userscount === 0) {
                                return <Button type="button" variant="text" color="primary" onClick={(e) => handleEventEvaluvators(e, row)}>{t('Add')}</Button>;
                            } else {
                                return <Button type="button" variant="text" color="secondary" onClick={(e) => handleSlotEventEvaluvators(e, row)}>{row.userscount}</Button>;
                            }
                        },

                    },
                ]
            ) : []),

            {
                label: "Player",
                align: "center",
                accessor: (row: any) => {
                    if (row.owner === 'ClubAdmin' ? row.playerscount === 0 : row.assnplayerscount === 0) {
                        return <Button type="button" variant="text" color="primary" onClick={(e) => {
                            if (decodedTokenData.roles !== 'Evaluator') {
                                handleEventPlayers(e, row);
                            }
                        }}>{
                                decodedTokenData.roles !== 'Evaluator' ? t('Add') : <Button type="submit" variant="text" color="secondary">{row.owner === 'ClubAdmin' ? row.playerscount : row.assnplayerscount}</Button>
                            }</Button>;
                    } else {
                        return <Button type="submit" variant="text" color="secondary">{row.owner === 'ClubAdmin' ? row.playerscount : row.assnplayerscount}</Button>;
                    }
                }
            },
            {
                label: "Created By",
                align: "left",
                accessor: (row: any) => row?.createdBy
            }
        ]
        return config

    }, [decodedTokenData])



    const QUERY = `query GetAllUpcomingEvents($search: String, $page: Int, $pageSize: Int, $location: String, $seasonId: Int, $associationId: Int, $eventType: String) {
        getAllUpcomingEvents(search: $search, page: $page, pageSize: $pageSize, location: $location, seasonId: $seasonId, associationId: $associationId , eventType: $eventType) {
          errors
          success
          totalEvents
          events {
            userscount
            title
            startTime
            slottitle
            playerscount
            owner
            assnplayerscount
            slotId
            location
            layoutId
            layout
            eventId
            endTime
            createdBy
          }
        }
      }`

    const onAddUserModalClose = () => {
        setEditMode(false)
        setAddModalOpen(false);
        setShareModalOpen(false);
        setDeleteConfirmModal(false);
        setDeleteEventConfirmModal(false);
        setSelectedEvent(null);
        handleRefetch();
    }




    const onShareEventModalClose = () => {
        setEditMode(false);
        setEventEvaluatorListModal(false);
        setEventAssociationPlayerListModal(false);
        setAddEvaluatorModalOpen(false);
        setEventPlayerListModal(false);
        setSlotEvaluatorsModal(false);
        setSelectedEvent(null);
        handleRefetch();

    }


    return (
        <div>
            <TablePageHoc actions={ActionBarConfig as WidgetProps[]}
                columns={TableConfig as TableColumn[]}
                ref={childRef}
                query={QUERY}
                params={{
                    search: searchText,
                    seasonId: filterData.requestEventId ? filterData.requestEventId : parseInt(props.season),
                    location: filterData?.location,
                    associationId: filterData?.associationId,

                }}
                sourceAccessor={(data) => data.getAllUpcomingEvents.events}
                countAccessor={(data) => data.getAllUpcomingEvents.totalEvents}
                onRowClick={(row) => {

                    navigate(`/admin/event-management/detail?eventId=${row.eventId}&slotId=${row.slotId}`)
                }}
                detailPointer="pointer"
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Add Players",
                        modalOpen: eventPlayerListModal,
                        onClose: () => onShareEventModalClose(),
                        children: <AddPlayer onSave={() => onShareEventModalClose()} onClose={onShareEventModalClose} slotId={slotId} eventId={eventId} requestEventId={requestEventId} editMode={editMode} />,
                        size: 'lg'
                    })
            }

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Add Players",
                        modalOpen: eventAssociationPlayerListModal,
                        onClose: () => onShareEventModalClose(),
                        children: <AddAssociationPlayer onSave={() => onShareEventModalClose()} onClose={onShareEventModalClose} slotId={slotId} eventId={eventId} requestEventId={requestEventId} editMode={editMode} />,
                        size: 'lg'
                    })
            }


            {
                getWidget(
                    {
                        type: "modal",
                        title: "Add Evaluators",
                        modalOpen: eventEvaluatorListModal,
                        onClose: () => onShareEventModalClose(),
                        children: <AddEvaluator onSave={() => onShareEventModalClose()} onClose={onShareEventModalClose} slotId={slotId} />,
                        size: 'lg'
                    })
            }

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Evaluators",
                        action: <Button type="button" variant="contained" onClick={() => setAddEvaluatorModalOpen(true)}>{t(responsiveString({ xs: "Add", sm: "Add Evaluators" }))}</Button>,
                        modalOpen: slotEvaluatorsModal,
                        onClose: () => onShareEventModalClose(),
                        children: <SlotEvaluvatorList event={selectedEvent} onSave={() => onShareEventModalClose()} onClose={onShareEventModalClose} slotId={slotId} modalOpen={addEvaluatorModalOpen} onAddClose={() => setAddEvaluatorModalOpen(false)} />,
                        size: 'lg'
                    })
            }

            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedEvent ? "Edit Event" : "Create Event",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: <EventGeneration event={selectedEvent} onSave={() => onAddUserModalClose()} onClose={onAddUserModalClose} editMode={editMode} />,
                        size: 'md'
                    })
            }


            <ConfirmModal modalOpen={deleteEventConfirmModal} onClose={() => onAddUserModalClose()} onConfirm={() => deleteEventRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the event')} ${selectedEvent?.title}? ${t('Once deleted, this action cannot be undone!')}`} />
            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => onAddUserModalClose()} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the event slot')} ${selectedEvent?.slottitle}? ${t('Once deleted, this action cannot be undone!')}`} />
            <ConfirmModal modalOpen={shareModalOpen} onClose={() => onAddUserModalClose()} onConfirm={() => sendEmail()} title={t('Are you sure?')} children={t('Do you want to send an email to all the assigned evaluators?')} />
        </div>
    )
}
