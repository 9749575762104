import React, { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';

function GuestPage() {
    const isLogged = useSelector((state: any) => state.auth.isLoggedIn)
    const dispatch = useDispatch();

    let path = "/admin/dashboard";
    let redirectPath = localStorage.getItem('redirectPath');
      if(redirectPath) {
        path = redirectPath as string;
      }

    useEffect(()=>{
        dispatch(stopPageLoading())
    },[])

    return (
        <>
            {
                isLogged ? (
                    <Navigate to={path} />
                ) : (
                    <Outlet />
                )

            }
        </>
    )
}

export default GuestPage