import React, { useEffect, useRef, useState } from 'react'
import { FormArray, FormField, FormGroup, FormGroupRef } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Divider, Grid, MenuItem, Typography } from '@mui/material'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import { useSelector } from 'react-redux'




interface LayoutData {
    id: number;
    title: string;
    options: LayoutOption[];
}

interface LayoutOption {
    id: number;
    title: string;
    options: LayoutOption[];
}


const CREATE_EVENT_LAYOUT = gql`
mutation Mutation($title: String!, $subcategoryId: Int, $categoryId: Int, $createEventLayoutId: ID, $options: [SOCCER_EventLayoutOptionRequest]) {
  createEventLayout(title: $title, subcategoryId: $subcategoryId, categoryId: $categoryId, id: $createEventLayoutId, options: $options) {
    success
    errors
    layout {
      id
    }
  }
}
`

const DELETE_LAYOUT_OPTION_QUERY = gql`
mutation Mutation($deleteOneLayoutOptionId: ID!) {
    deleteOneLayoutOption(id: $deleteOneLayoutOptionId) {
      success
      errors
    }
  }`

const GET_CATEGORIES_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int) {
    getAllCategories(search: $search, page: $page, pageSize: $pageSize) {
      totalCategories
      success
      categories {
        categoryName
        id
      }
      errors
    }
  }
    `

const GET_SUBCATEGORIES_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int, $categoryId: Int) {
    getAllSubCategories(search: $search, page: $page, pageSize: $pageSize, categoryId: $categoryId) {
      errors
      totalSubCategories
      success
      subcategories {
        subcategoryName
        id
        Category {
          categoryName
          id
        }
      }
    }
  }`


export default function AddLayout({ layout, onSave, onClose }: any) {

    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [evaluationOptions, setEvaluationOptions]: any = useState([]);
    const [deleteLayoutOption] = useMutation(DELETE_LAYOUT_OPTION_QUERY);
    const [resetField, setResetField]: any = useState();
    const [createLayout] = useMutation(CREATE_EVENT_LAYOUT);
    const [selectedCategory, setSelectedCategory]: any = useState(layout?.Category?.id);
    const [categoryData, setCategoryData] = useState([])
    const [selectedSubCategory, setSelectedSubCategory]: any = useState(null);
    const { loading: categoryLoading, error: categoryError, data: categoriesData } = useQuery(GET_CATEGORIES_QUERY, {
        variables: {
            search: null, page: null
        }
    });
    const { refetch: categoryRefetch } = useQuery(GET_SUBCATEGORIES_QUERY, { skip: true });
    const formRef = useRef<FormGroupRef>(null);
    const formArrayRef = useRef<FormGroupRef>(null);

    useEffect(() => {
        getSubCategories();

    }, [selectedCategory])

    const getSubCategories = async () => {
        const { data } = await categoryRefetch({
            search: "",
            page: null,
            pageSize: null,
            categoryId: parseInt(selectedCategory)
        });
        setCategoryData(data?.getAllSubCategories?.subcategories);
        return data;
    }


    useEffect(() => {
        if (layout) {
            let options: any[] = [];
            layout.layoutOptions.forEach((option: any) => {
                options.push(optionField(option));
            })
            options.sort((a, b) => parseInt(a.id) - parseInt(b.id));

            setEvaluationOptions(options);
        } else {
            addOption();
        }
    }, [])



    const addOption = () => {
        const newEvaluationOptions: any[] = [...evaluationOptions];
        newEvaluationOptions.push(optionField());
        setEvaluationOptions(newEvaluationOptions);
    }


    const removeOption = async (index: number, option: any) => {

        formArrayRef?.current?.removeItem?.(index.toString());

        if (option) {

            if (option.id === null) {

                const newEvaluationOptions: any[] = [...evaluationOptions];
                newEvaluationOptions.splice(index, 1);
                setEvaluationOptions(newEvaluationOptions);
            } else {
                const variables = {
                    deleteOneLayoutOptionId: option.id,
                }



                try {
                    const { data } = await deleteLayoutOption({
                        variables: variables
                    });
                    if (data.deleteOneLayoutOption.errors?.length > 0) {
                        console.error('Error:', data.deleteOneLayoutOption.errors);
                        const errorIndex = data.deleteOneLayoutOption.errors?.length;

                        enqueueSnackbar(data.deleteOneLayoutOption.errors[errorIndex - 1], { variant: "error" });
                    } else {

                        enqueueSnackbar(t("Event Layout Option Deleted"), { variant: "success" });
                        const newEvaluationOptions: any[] = [...evaluationOptions];
                        newEvaluationOptions.splice(index, 1);
                        setEvaluationOptions(newEvaluationOptions);
                        // onSave();
                    }

                } catch (error) {
                    console.error('Mutation error:', error);
                }
            }

        }

        if (index === 0) {

            enqueueSnackbar(t("Event Layout Option cannot be Deleted"), { variant: "error" });
            return;
        }


    }


    const addSubOption = (index: number) => {

        const newEvaluationOptions: any[] = [...evaluationOptions];
        newEvaluationOptions[index].options.push(optionField());
        setEvaluationOptions(newEvaluationOptions);
    }


    const removeSubOption = async (index: number, si: number, subOption: any, arrayRef: any) => {


        arrayRef?.current?.removeItem?.(si.toString());
        if (subOption) {

            if (subOption.id === null) {
                const newEvaluationOptions: any[] = [...evaluationOptions];
                newEvaluationOptions[index].options.splice(si, 1);
                setEvaluationOptions(newEvaluationOptions);
            } else {
                const variables = {
                    deleteOneLayoutOptionId: subOption.id,
                }



                try {
                    const { data } = await deleteLayoutOption({
                        variables: variables
                    });
                    if (data.deleteOneLayoutOption.errors?.length > 0) {
                        console.error('Error:', data.deleteOneLayoutOption.errors);
                        const errorInex = data.deleteOneLayoutOption.errors?.length
                        enqueueSnackbar(data.deleteOneLayoutOption.errors[errorInex - 1], { variant: "error" });
                    } else {

                        enqueueSnackbar(t("Event Layout Option Deleted"), { variant: "success" });
                        const newEvaluationOptions: any[] = [...evaluationOptions];
                        newEvaluationOptions[index].options.splice(si, 1);
                        setEvaluationOptions(newEvaluationOptions);
                        // onSave();
                    }

                } catch (error) {
                    console.error('Mutation error:', error);
                }
            }

        }


    }


    const optionField = (data?: any) => {
        let options: any[] = [];
        data?.subOptions?.forEach((option: any) => {
            options.push(optionField(option));
        })
        options.sort((a, b) => parseInt(a.id) - parseInt(b.id));
        return {
            id: data?.id ?? null,
            title: data?.optionTitle ?? "",
            options: options,
        }
    }



    const handleSubmit = async (data: any, callback: Function) => {

        const variables = {
            createEventLayoutId:data.id,
            title:data.title,
            options:data.options,
            categoryId:parseInt(data.categoryId),
            subcategoryId:parseInt(data.subcategoryId)
        };

        const formData = formArrayRef?.current?.getValues();

        function hasEmptyOptionTitles(formData: any) {
            for (const option of formData) {
                if (!option.optionTitle.trim()) {
                    return true;
                }
            }
            return false;
        }

        function hasEmptySubOptionTitles(formData: any) {
            for (const option of formData) {
                for (const subOption of option.options) {
                    if (!subOption.optionTitle.trim()) {
                        return true;
                    }
                }
            }
            return false;
        }


        if (hasEmptyOptionTitles(formData)) {
            enqueueSnackbar(t("Option title cannot be empty. Please provide titles for all options."), { variant: "error" });
            return;
        }

        if (hasEmptySubOptionTitles(formData)) {
            enqueueSnackbar(t("Sub Option title cannot be empty. Please provide titles for all options."), { variant: "error" });
            return;
        }





        function hasDuplicateOptions(formData: any) {
            const optionTitles = new Set();
            for (const option of formData) {
                if (optionTitles.has(option.optionTitle)) {
                    return true;
                }
                optionTitles.add(option.optionTitle);
            }
            return false;
        }

        if (hasDuplicateOptions(formData)) {

            enqueueSnackbar(t('Option title is already used.'), { variant: "error" });
            return;
        } else {



            try {
                const { data } = await createLayout({
                    variables: variables
                });

                if (data.createEventLayout.errors?.length > 0) {
                    console.error('Error:', data.createEventLayout.errors);
                    const errorIndex = data.createEventLayout.errors.length - 1
                    enqueueSnackbar(data.createEventLayout.errors[errorIndex], { variant: "error" });
                } else {

                    enqueueSnackbar(t("Layout created successfully"), { variant: "success" });
                    onSave();

                }
            } catch (error) {

                console.error('Mutation error:', error);
            }


        }


    };


    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name: string) => resetField)} ref={formRef}>
                <Grid container spacing={12} >
                    <Grid item xs={12}>
                        <FormField name='id' value={layout?.id ?? undefined} validateOnChange={false}><></></FormField>
                        <FormField name='title' value={layout?.title ?? ""} validator={(value: string, ...data) => {
                            if (!value) return "Layout name is required."
                            return null
                        }} validateOnChange={false}>
                            <TextField id="title"
                                label={t("Layout Name")}
                                variant="outlined"
                            
                                fullWidth={true}
                            />
                        </FormField>
                    </Grid>
                    {
                        decodedTokenData.roles === 'SuperAdmin' && <Grid item xs={12}>
                            <FormField name='categoryId' value={layout?.Category?.id ?? ""} validator={(value: string) => {
                                if (!value) return "Category selection is required.";
                                return null
                            }} validateOnChange={false}>
                                <TextField id="role"
                                    label={t("Category")}
                                    select
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={(e: any) => {
                                        setSelectedCategory(e.target.value);
                                    }}
                                >
                                    {!categoryLoading && !categoryError ? categoriesData.getAllCategories.categories.map((category: any) => (
                                        <MenuItem key={"category" + category.id} value={category.id}>
                                            {category.categoryName}
                                        </MenuItem>
                                    )) : <MenuItem>{t('Loading')}</MenuItem>}
                                </TextField>
                            </FormField>
                        </Grid>
                    }

                    {
                        selectedCategory && decodedTokenData.roles === 'SuperAdmin' && <Grid item xs={12}>
                            <FormField name='subcategoryId' value={layout?.Subcategory?.id ?? ""} validator={(value: string) => {
                                if (!value) return "Category selection is required.";
                                return null
                            }} validateOnChange={false}>
                                <TextField id="role"
                                    label={t("Sub Category")}
                                    select
                                    variant="outlined"
                                
                                    fullWidth={true}
                                    onChange={(e: any) => {
                                        setSelectedSubCategory(e.target.value);
                                    }}
                                >
                                    {categoryData ? categoryData?.map((category: any) => (
                                        <MenuItem key={"subcategory" + category.id} value={category.id}>
                                            {category.subcategoryName}
                                        </MenuItem>
                                    )) : <MenuItem>{t('Loading')}</MenuItem>}
                                </TextField>
                            </FormField>
                        </Grid>
                    }


                    <Grid item xs={12} sm={8}>
                        <Typography component="p" sx={{ 'fontSize': '16px', 'marginBottom': '0px' }}>
                            {t('Evaluation Options')}
                        </Typography>
                    </Grid>


                    <Grid item xs={12} sm={4} textAlign={{ xs: 'left', sm: 'right' }} >
                        <Button variant='text' size='small' color='secondary' onClick={addOption}>+ {t('Add')}</Button>
                    </Grid>

                    <FormArray name='options' ref={formArrayRef}>
                        {evaluationOptions.map((option: any, index: number) => (
                            <FormGroup name={index.toString()}  >
                                <Grid container key={option.id} alignItems={"center"}>

                                    <Grid item xs={7} sm={9} sx={{ padding: '12px 0 0 12px' }}>
                                        <FormField name='id' value={option.id ?? undefined} validateOnChange={false}><></></FormField>
                                        <FormField name='optionTitle' value={option.title ?? ""} validator={(value: string, ...data) => {
                                            if (!value) return "optionTitle is required."
                                            return null
                                        }} validateOnChange={false}>
                                            <TextField id="optionTitle"
                                                label={t("Option Name")}
                                                variant="outlined"
                                                sx={{ mb: 10 }}

                                                fullWidth={true}
                                            />
                                        </FormField>
                                    </Grid>

                                    <Grid item xs={5} sm="auto" sx={{ marginBottom: '20px' }}>
                                        <Button variant='text' sx={{ color: '#D6D0D0' }} size='small' className='mb-3' onClick={() => removeOption(index, option)}>
                                            <RemoveCircleOutlineOutlinedIcon sx={{ height: '25px', width: '25px' }} />
                                        </Button>
                                        <Button variant='text' sx={{ color: '#D6D0D0' }} size='small' className='mb-3' onClick={() => addSubOption(index)}>
                                            <QueueOutlinedIcon sx={{ height: '25px', width: '25px' }} />
                                        </Button>
                                    </Grid>

                                </Grid>


                                {
                                    option.options && (
                                        <NestedFormArray index={index} option={option} removeSubOption={removeSubOption} />
                                    )
                                }
                            </FormGroup>

                        ))}
                    </FormArray>


                    <Divider sx={{ mx: -24, mb: 20 }} />

                    <Grid container justifyContent={"flex-end"}>
                        <Grid item xs={"auto"}>
                            <Button type="button" variant="text" color="secondary" onClick={onClose} >{t('Cancel')}</Button>
                        </Grid>
                        <Grid item xs={"auto"}>
                            <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>

                        </Grid>
                    </Grid>
                </Grid>
            </FormGroup>
        </div >
    )
}


const NestedFormArray = ({ index, option, removeSubOption }: any) => {

    const formArrayRef = useRef<FormGroupRef>(null);
    const { t } = useTranslation();

    return <>
        <FormArray name='options' ref={formArrayRef}>
            {
                option.options.map((subOption: LayoutOption, si: number) => {
                    return <FormGroup name={si.toString()} >
                        <Grid container key={subOption.id} alignItems={"center"}>
                            <Grid item xs={1} sm={1} />
                            <Grid item xs sm>
                                <FormField name='id' value={subOption.id ?? ""} validateOnChange={false}><></></FormField>
                                <FormField name='optionTitle' value={subOption.title ?? ""} validator={(value: string, ...data) => {
                                    if (!value) return "optionTitle is required."
                                    return null
                                }} validateOnChange={false}>
                                    <TextField id="optionTitle"
                                        label={t("Layout Name")}
                                        variant="outlined"
                                        sx={{ mb: 10 }}
                                        fullWidth={true}
                                    />
                                </FormField>
                            </Grid>

                            <Grid item sm="auto" sx={{ marginBottom: "20px" }} >
                                <Button variant='text' sx={{ color: '#D6D0D0' }} size='small' className='mb-3' onClick={() => removeSubOption(index, si, subOption, formArrayRef)}>
                                    <RemoveCircleOutlineOutlinedIcon sx={{ height: '25px', width: '25px' }} />
                                </Button>
                            </Grid>

                        </Grid>
                    </FormGroup>
                })
            }
        </FormArray>
    </>
}