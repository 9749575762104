import React, { useState, useRef, useContext, useEffect, useMemo } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material'
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { getWidget } from '../../../shared/widgets';
import Addassociation from '../association-management/add-association';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { addToast, startPageLoading, stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import SendEmailModal from '../../../shared/hoc/modal/send-email-modal';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString } from '../../../shared/utils/util-functions';
import FilterClub from '../clubs-list-page/FilterClub';
import { variableDeclaration } from '@babel/types';
import AssociationListModal from '../../../shared/hoc/modal/association-detail-modal';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import AssociationStepper from '../../../shared/widgets/stepper-widget/association-stepper';
import Chip from '@mui/material/Chip';


const DELETE_USER_QUERY = gql`mutation DeleteOneAssociation($deleteOneAssociationId: ID!) {
    deleteOneAssociation(id: $deleteOneAssociationId) {
      errors
      success
      association {
        associationName
      }
    }
  }`


const SEND_EMAIL_QUERY = gql`
mutation Mutation($associationIds: [Int]) {
    sendEventLinkApi(associationIds: $associationIds) {
      success
      key
      errors
      email
    }
  }
  `

type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};


function AssociationListPageTab() {
    const [page, setPage] = React.useState(1);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [sendEmailConfirmModal, setSendEmailConfirmModal] = React.useState(false);
    const [editMode, setEditMode]: any = React.useState(false);
    const [sendEmailAssociationConfirmModal, setSendEmailAssociationConfirmModal] = React.useState(false);
    const [searchText, setSearchText] = useState(null);
    const [filterValues, setFilterValues] = useState({ role: null, clubId: null, associationId: null, search: null });
    const childRef = useRef<ChildRef | null>(null);
    const { enqueueSnackbar } = useSnackbar();
    const [deleteAssociation] = useMutation(DELETE_USER_QUERY);
    const [sendEmailQuery] = useMutation(SEND_EMAIL_QUERY);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [filterData, setFilterData]: any = React.useState({});
    const [associationDetailInfo, setAssociationDetailInfo] = React.useState({});
    const [associationDetailModal, setAssociationDetailModal] = React.useState(false);
    const [stepperModalOpen, setStepperModalOpen] = React.useState(false);
    const [initialised, setInitialised] = useState(false);


    const dispatch = useDispatch();
    const { t } = useTranslation()
    const pageMeta = useContext(PageMetaContext);
    const { clubId: clubIdParams } = useParams();
    
    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Associations");
        }
    }, [pageMeta]);

    const handleRefetch = () => {

        childRef.current?.refetchData({
            search: searchText ?? "",
            clubId: decodedTokenData.roles !== 'FVRZAdmin' ? decodedTokenData.ClubId : filterData.clubId
        });
    };


    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;
        const variables = {
            deleteOneAssociationId: selectedUser?.id,
        }

        try {
            const { data } = await deleteAssociation({
                variables: variables
            });
            setSelectedUser(null);

            if (data.deleteOneAssociation.errors?.length > 0) {
                console.error('Error:', data.deleteOneAssociation.errors);
                enqueueSnackbar(data.deleteOneAssociation.errors[0], { variant: "error" });
            } else {
            
                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Association deleted successfully"), { variant: "success" });
               
            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }

    }

    const TableConfigAssociations: TableColumn[] = [
        {
            label: "Action",
            align: "left",
            width: 130,
            accessor: (row: any) => {
                const isClubAdmin = decodedTokenData.roles.includes('ClubAdmin');
                return <>
                    <ActionMenu
                        button={{
                            type: "icon-button",
                            icon: <MoreVertOutlined />,
                            size: 'small',
                            color: 'primary',
                            sx: { color: 'white' }
                        }}
                        children={
                            [
                                <MenuItem onClick={(event) => {
                                    setSelectedUser(row);
                                    setAddModalOpen(true)
                                    event.stopPropagation();
                                    setEditMode(true)
                                }}>{t('Edit')}</MenuItem>,
                                <MenuItem onClick={(event) => {
                                    setSelectedUser(row);
                                    setDeleteConfirmModal(true)
                                    event.stopPropagation();
                                }}>{t('Delete')}</MenuItem>,

                                !isClubAdmin && (
                                    <MenuItem onClick={(event) => {
                                        setSelectedUser(row);
                                        setSendEmailAssociationConfirmModal(true)
                                        event.stopPropagation();
                                    }}>{t('Send Email')}</MenuItem>
                                )

                            ]
                        }
                    />
                </>
            }
        }
        ,
        {
            label: "Association Name",
            align: "left",
            accessor: (row: any) => row.associationName
        },
        {
            label: "Club",
            align: "left",
            accessor: (row: any) => row.Club?.clubName
        }

    ]





    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }


    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)


    useEffect(() => {
        if (clubIdParams) {
            setFilterData({
                clubId: parseInt(clubIdParams)
            })
        }
        setInitialised(true);
    }, [])


    const handleEmailQueryData = (queryData: any) => {

        enqueueSnackbar(t("An email link has been sent for adding a player"), { variant: "success" });
        setSelectedUser(null)
    };


    const sendEmail = async () => {
        dispatch(startPageLoading())
        const variables = {
            associationIds: selectedUser ? [parseInt(selectedUser.id)] : null
        }
        const { data }: any = await sendEmailQuery({
            variables: variables
        });
        dispatch(stopPageLoading())
        if (data?.sendEventLinkApi?.errors?.length > 0) {
            enqueueSnackbar(data?.sendEventLinkApi?.errors[0], { variant: "error" });
        } else {
            handleEmailQueryData(data)
        }
    }

    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            setFilterData(filters.filters);
        }
    };





    const ActionBarConfig = useMemo(() => {
        return [
            ...(
                decodedTokenData?.roles === 'FVRZAdmin' ? (
                    [{
                        type: "filter-menu",
                        children: <FilterClub filterData={filterData} onFilterChange={handleFilterChange} />,
                        sx: {
                            order: 1
                        }
                    }]
                ) : []
            ),
            ,
            {
                type: "search-input",
                placeholder: "Search",
                onChange: onSearchTextChange,
                responsive: {
                    xs: 12,
                    sm: "auto"
                },
                sx: {
                    order: {
                        xs: 3,
                        sm: 1
                    }
                }
            },
            {
                type: "button",
                label: t(responsiveString({ xs: "Add", sm: "Add Association" })),
                startIcon: <Add />,
                onClick: () => {
                    setAddModalOpen(true)
                    setSelectedUser(null)
                    setEditMode(false)
                },
                sx: {
                    order: {
                        xs: 1,
                        sm: 2
                    }
                }
            },
            ...((decodedTokenData.roles === 'FVRZAdmin') ? (
                [
                    {
                        type: "button",
                        label: "Send Email",
                        // startIcon: <Add />,
                        onClick: () => setSendEmailConfirmModal(true),
                        sx: {
                            order: {
                                xs: 2,
                                sm: 3
                            }
                        }
                    },
                ]
            ) : ([]))

        ]
    }, [filterData, decodedTokenData])



    const ASSOCATIONSQUERY = `query GetAllAssociations($clubId: Int, $search: String, $page: Int, $pageSize: Int) {
        getAllAssociations(clubId: $clubId, search: $search, page: $page, pageSize: $pageSize) {
          associations {
            associationName
            Club {
              clubName
              id
            }
            id
          }
          success
          errors
          totalAssociations
        }
      }`


    const onAddUserModalClose = () => {
        setAddModalOpen(false);
        handleRefetch()
    }



    if (!initialised) {
        return(
            <div></div>
        )
       
    }

    return (
        <div>
            <TablePageHoc actions={ActionBarConfig as WidgetProps[]} columns={TableConfigAssociations} query={ASSOCATIONSQUERY} ref={childRef}
                params={{
                    search: searchText,
                    clubId: decodedTokenData.roles !== 'SuperAdmin' ? decodedTokenData.ClubId : filterData.clubId
                }} sourceAccessor={(data) => data.getAllAssociations.associations} countAccessor={(data) => data.getAllAssociations.totalAssociations}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }
                }
                onRowClick={(row) => {
                    setAssociationDetailInfo(row)
                    setAssociationDetailModal(true)
                }}
                detailPointer="pointer" />
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedUser ? "Edit Association" : "Add Association",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: selectedUser ? <Addassociation association={selectedUser} editMode={editMode} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddModalOpen(false)

                        }} /> : <AssociationStepper association={selectedUser} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddModalOpen(false)
                            handleRefetch()
                        }}
                        />,
                    })
            }
            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the association')} ${selectedUser?.associationName}? ${t('Once deleted, this action cannot be undone!')}`} />
            <ConfirmModal modalOpen={sendEmailConfirmModal} onClose={() => setSendEmailConfirmModal(false)} onConfirm={sendEmail} title={t('Are you sure?')} children={t('Do you want to send email for all the association admins?')} />
            <ConfirmModal modalOpen={sendEmailAssociationConfirmModal} onClose={() => setSendEmailAssociationConfirmModal(false)} onConfirm={sendEmail} title={t('Are you sure?')} children={`${t('Do you want to send email for the association')} ${selectedUser?.associationName}?`} />
            <AssociationListModal modalOpen={associationDetailModal} onClose={() => setAssociationDetailModal(false)} detail={associationDetailInfo} />
        </div>
    )
}

export default AssociationListPageTab