import React from 'react';
import style from './SideBar.module.scss';
import { SidebarProps } from '../../types/admin-layout';
import { List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { uid as uniqueId } from 'uid';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { closeSidebar, startPageLoading } from '../../../store/reducers/AppSlice';

const SideMenuList = styled(List)(({ theme }:any) => ({
    // ...theme.components.MuiSideMenuList.styleOverrides.root,
    padding: '20px 0px'
}));

// const SideMenuListItem = styled(ListItemButton)(({ theme }:any) => ({
//     padding: '9px 5px'
// }));

const ListItemButtonLink = ({ activeClassName, exact, ref, ...props }: any) => (
    <ListItemButton
        {...props}
        LinkComponent={NavLink}
    />
);

const StyledListItemButton = styled(ListItemButtonLink)(({ theme }) => ({
    ...theme.components.MuiListItemButton.styleOverrides.root,
    textDecoration: 'none',
    color: 'inherit',
}));


export default function SideBar({ menuLinks, isOpen, logo, logoSm }: SidebarProps) {

    const {t} = useTranslation();
    const dispatch = useDispatch();


    const handleLinkClick = () => {
        dispatch(startPageLoading());
        if(window.innerWidth <= 576) {
            dispatch(closeSidebar())
        }
    }

    return (
        <SideMenuList className={style.sidebarWrapper}
            component="nav"
            sx={{height: '100%', overflow: 'auto'}}
            aria-labelledby="nested-list-subheader"
            subheader={
                window.innerWidth <= 576 && <ListSubheader sx={{backgroundColor: 'transparent', px: 30}} component="div" id="nested-list-subheader">
                    {logo}
                </ListSubheader>
            }
        >
            {
                menuLinks.map((link) => {
                    return <StyledListItemButton key={uniqueId()} onClick={handleLinkClick} to={link.link} exact activeClassName="active">
                    <ListItemIcon>
                        {link.icon}
                    </ListItemIcon>
                    {
                        isOpen && <ListItemText primary={t(link.text)} />
                    }
                </StyledListItemButton>
                })
            }
        </SideMenuList>
    );
}


