import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import { Box, Button, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import DataTable from '../../../shared/widgets/table/table.component';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';


const CREATE_EVENT_SLOT_PLAYER_QUERY = gql`mutation CreateEventSlotPlayer($slotId: Int!, $eventId: Int!, $playerId: [Int]!) {
    createEventSlotPlayer(slotId: $slotId, eventId: $eventId, playerId: $playerId) {
      success
      errors
      message
    }
  }
  `


type ChildRef = {
    refetchData: (data: any) => void;
};



function PreviewEditSubmittedPlayersList({ data, onClose, slotId, eventId, onAddClose, handleEventPlayers }: any) {

    const [page, setPage] = React.useState(1);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [previewPlayers, setPreviewPlayers] = useState(data);
    const [addPlayersToSlot] = useMutation(CREATE_EVENT_SLOT_PLAYER_QUERY);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();


    const handleEventPlayer = (player: any) => {
        setPreviewPlayers(previewPlayers.filter((id: any) => id !== player));
        handleEventPlayers(previewPlayers.filter((id: any) => id !== player))
    }

    const handleSubmit = async () => {

        let hasError = false;

        try {

            const variables = {
                slotId: parseInt(slotId),
                eventId: parseInt(eventId),
                playerId: previewPlayers.map((player: any) => parseInt(player.id))
            };

            const { data } = await addPlayersToSlot({
                variables: variables
            });


            if (data.createEventSlotPlayer.errors?.length > 0) {
                enqueueSnackbar(t("Error adding event players"), { variant: "error" });
            } else {
                enqueueSnackbar(t("Players added successfully"), { variant: "success" });
                onClose();
                onAddClose();
            }

        } catch (error) {
            console.error('Mutation error:', error);
        }
    }





    const TableConfig: TableColumn[] =
        [
            {
                label: "Remove",
                align: "left",
                accessor: (row: any) => <RemoveCircleOutlineIcon onClick={(event: any) => handleEventPlayer(row)} style={{ 'cursor': 'pointer', 'display': 'flex', 'alignItems': 'center', 'height': '20px', 'width': '20px' }} />,

            },

            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Date Of Birth",
                align: "left",
                accessor: (row: any) => row.dateOfBirth
            },

            {
                label: "Association",
                align: "left",
                accessor: (row: any) => row.Association?.associationName
            },


            {
                label: "Goal Keeper",
                align: "left",
                accessor: (row: any) => t(row.goalKeeper)
            }
        ];


    return (
        <div>

            {
                previewPlayers && previewPlayers.length > 0 ? (
                    <>
                        <div style={{ 'height': 'calc(80vh - 300px)', overflow: 'auto' }}>
                            <DataTable dataSource={previewPlayers} columns={TableConfig} />
                        </div>


                        <Divider sx={{ mx: -24, mb: 20, mt: 20 }} />
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item xs={"auto"}>
                                <Button type="button" variant="text" color="secondary" onClick={onClose}>{t('Cancel')}</Button>
                            </Grid>
                            <Grid item xs={"auto"}>
                                <Button type="submit" variant="contained" onClick={handleSubmit} color="primary">{t('Save')}</Button>
                            </Grid>
                        </Grid>
                    </>
                ) :
                    (
                        <>
                            <Box sx={{ display: 'flex', flexDirection: 'column', 'justifyContent': 'center', alignItems: 'center', my: { xs: 30, lg: 50 } }}>
                                <Typography >{t("No players has been selected for the event slot.")}</Typography>
                                <Typography ></Typography>
                            </Box>
                        </>
                    )
            }



        </div>
    )
}

export default PreviewEditSubmittedPlayersList