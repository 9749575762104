import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, TextField } from '@mui/material'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { getWidget } from '../../widgets';
import { ModalProps, ModalPropsBase, TableColumn } from '../../types/widget-types';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux';
import ActionMenu from '../../widgets/action-menu/action-menu';
import MenuItem from '../../widgets/admin-layout/MenuItem';
import EllipsisBox from '../../widgets/EllipsisBox/EllipsisBox';
import { splitCamelCase } from '../../utils/util-functions';
import TablePageHoc from '../../hoc/table-list/table-list-hoc';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';



const GET_ASSOCIATIONS_QUERY = gql`query GetAllAssociations($clubId: Int, $search: String, $page: Int, $pageSize: Int) {
    getAllAssociations(clubId: $clubId, search: $search, page: $page, pageSize: $pageSize) {
      associations {
        associationName
        id
      }
      errors
      success
      totalAssociations
    }
  }`

const QUERY = `query Query($page: Int, $pageSize: Int, $search: String, $role: [String], $associationId: Int, $clubId: Int) {
    getAllUsers(page: $page, pageSize: $pageSize, search: $search, role: $role, associationId: $associationId, clubId: $clubId) {
      success
      totalUsers
      users {
        Association {
          associationName
        }
        Club {
          clubName
        }
        id
        lastName
        role
        firstName
        email
        contactNumber
        designation
      }
    }
  }`


const CustomDialog = (props: any) => {
    return <Dialog
        {...props}
        className={
            clsx({
                'sizeSmall': props.size == 'sm',
                'sizeLarge': props.size == 'lg',
                'sizeMedium': props.size == 'md',
                'sizeExtraLarge': props.size == 'xl'
            })
        }
    />
}

export interface ClubDetailModalProps extends ModalPropsBase {
    // onConfirm: () => void;
    // children: string;
}

type ChildRef = {
    refetchData: (data: any) => void;
};



function ClubDetailModal({ actions, size, modalOpen, onClose, detail }: ClubDetailModalProps) {

    const [clubData, setClubsData] = useState<any>(null);
    const [associationData, setAssociationData] = useState<any>(null);
    const [usersData, setUsersData] = useState<any>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [page, setPage] = React.useState(1);
    const { refetch: associationFetch } = useQuery(GET_ASSOCIATIONS_QUERY, { skip: true });
    // const { refetch: usersFetch } = useQuery(GET_USERS_QUERY, { skip: true });
    const navigate = useNavigate();

    const { clubName, clubAddress, contactNumber, id } = detail

    useEffect(() => {
        if (decodedTokenData.roles == 'SuperAdmin' && modalOpen) {
            // getClubData();
            getAssociationData();
            getUsersData();
        }
    }, [decodedTokenData, modalOpen])



    const getAssociationData = async () => {

        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId: parseInt(id)
        });
        setAssociationData(data);
        return data;
    }


    const getUsersData = async () => {

        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId: parseInt(id)
        });
        setAssociationData(data);
        return data;
    }


    const TableConfig: TableColumn[] = [

        {
            label: "First Name",
            align: "left",
            accessor: (row: any) => <EllipsisBox content={row.firstName} minWidth={250} lineClamp={2} />
        },
        {
            label: "Last Name",
            align: "left",
            accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
        },
        {
            label: "Email",
            align: "left",
            accessor: (row: any) => row.email
        },
        {
            label: "Contact Number",
            align: "left",
            accessor: (row: any) => row.contactNumber
        },
        {
            label: "Role",
            align: "left",
            accessor: (row: any) => t(splitCamelCase(row.role))
        },
        {
            label: "Club",
            align: "left",
            accessor: (row: any) => row.Club?.clubName
        }
    ]

    const onCloseClick = () => {
        onClose?.()
    }

    const onCloseButtonClick = () => {
        onClose?.()
        navigate(`/admin/association-management/${id}`);
    }



    const onConfirmClick = () => {
        // onConfirm?.();
        onClose?.();

    }


    const { t } = useTranslation();

    return (
        <>
            <CustomDialog
                open={modalOpen ?? false}
                onClose={() => onCloseClick()}
                size='xl'
                sx={{ border: 0 }}

            >
                <DialogTitle variant='h6' color={"primary"}>
                    {t("View Club")}
                </DialogTitle>
                {/* <Divider/> */}
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12} sm={4} md={4}>
                            
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("Club Name")}
                            </DialogContentText>
                            <DialogContentText sx={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500" }}>
                                {clubName}
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} >
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("Club Address")}
                            </DialogContentText>
                            <DialogContentText sx={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500" }}>
                                {clubAddress}
                            </DialogContentText>
                        </Grid>


                        <Grid item xs={12} sm={4} md={4}>
                            <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                                {t("Total No. of Associations : ")}<span style={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500", 'color': "#d6d0d0" }}> {associationData?.getAllAssociations.totalAssociations}</span>
                            </DialogContentText>
                            <Button autoFocus onClick={onCloseButtonClick} color="primary" style={{ "marginBottom": "10px" }}>
                                {t('View all')}
                            </Button>
                        </Grid>
                    </Grid>

                    <DialogContentText sx={{ "fontSize": "12px", "color": "#6b6868" }}>
                        {t("Contact Person List")}
                    </DialogContentText>
                    <div style={{ 'marginTop': '20px' }}>
                        <TablePageHoc
                            columns={TableConfig}
                            query={QUERY}
                            params={{
                                // clubId:filterData?.clubId,
                                clubId: parseInt(id),
                                role:'ClubAdmin'
                            }}
                            sourceAccessor={(data) => data.getAllUsers.users}
                            countAccessor={(data) => data.getAllUsers.totalUsers}
                            pagination={{
                                enable: true,
                                page: page,
                                limit: 5,
                                onChange: (page) => {
                                    setPage(page)
                                }
                            }} /></div>

                    {/* {clubData ? clubData.getOneClub.users.map((user:any)=>(
                        <DialogContentText>- {user.Association?.associationName}</DialogContentText>
                    )):<DialogContentText>{t('Loading')}</DialogContentText>} */}
                </DialogContent>


            </CustomDialog>
        </>
    )
}

export default ClubDetailModal