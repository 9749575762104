import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { gql, useMutation } from '@apollo/client';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { Add, MoreVertOutlined } from '@mui/icons-material';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { responsiveString } from '../../../shared/utils/util-functions';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import { getWidget } from '../../../shared/widgets';
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import AddPackage from "./add-package";
import { MenuItem } from '@mui/material';
import PackageDetail from './package-detail-modal';


type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};

const DELETE_PACKAGE_QUERY = gql`mutation DeleteOnePackage($deleteOnePackageId: ID!) {
  deleteOnePackage(id: $deleteOnePackageId) {
    errors
    success
  }
}`



const PackageList = () => {

    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [selectedPackage, setSelectedPackage]: any[] = React.useState(null);
    const [editMode, setEditMode]: any = React.useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [packageDetailInfo, setPackageDetail] = React.useState({});
    const [PackageDetailModal, setPackageDetailModal] = React.useState(false);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = React.useState(1);
    const childRef = useRef<ChildRef | null>(null);
    const { enqueueSnackbar } = useSnackbar();


    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [deletePackage] = useMutation(DELETE_PACKAGE_QUERY);

    const pageMeta = useContext(PageMetaContext);

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Package");
        }
        dispatch(stopPageLoading());
    }, [pageMeta]);


    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText ?? ""
        });
    };


    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedPackage) return;
        const variables = {
            deleteOnePackageId: selectedPackage?.id,
        }

        try {
            const { data } = await deletePackage({
                variables: variables
            });
            setSelectedPackage(null);

            if (data.deleteOnePackage.errors?.length > 0) {
                console.error('Error:', data.deleteOnePackage.errors);
                enqueueSnackbar(data.deleteOnePackage.errors[0], { variant: "error" });
            } else {

                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Package deleted successfully"), { variant: "success" });

            }
        } catch (error) {
            setSelectedPackage(null);
            console.error('Mutation error:', error);
        }
    }

    const TableConfigClubs: TableColumn[] = [
        {
            label: "Action",
            align: "left",
            width: 130,
            accessor: (row: any) => {
                return <>
                    <ActionMenu
                        button={{
                            type: "icon-button",
                            icon: <MoreVertOutlined />,
                            size: 'small',
                            color: 'primary',
                            sx: { color: 'white' }

                        }}
                        children={
                            [
                                <MenuItem onClick={(event: any) => {
                                    setSelectedPackage(row);
                                    setAddModalOpen(true)
                                    event.stopPropagation();
                                    setEditMode(true)
                                }}>{t('Edit')}</MenuItem>,
                                <MenuItem onClick={(event: any) => {
                                    setSelectedPackage(row);
                                    setDeleteConfirmModal(true)
                                    event.stopPropagation();
                                }}>{t('Delete')}</MenuItem>
                            ]
                        }
                    />
                </>
            }
        }
        ,
        {
            label: "Package Name",
            align: "left",
            accessor: (row: any) => row.packageName
        },
        {
            label: "Cost",
            align: "left",
            accessor: (row: any) => `CHF ${row?.cost}`
        },
    ]


    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {
            setPage(1)
        }
        setSearchText(event.target.value)
    }

    useUpdateEffect(() => {
        handleRefetch();
    }, [searchText], 300)

    const QUERY = `query Query($search: String, $page: Int, $pageSize: Int) {
  getAllPackages(search: $search, page: $page, pageSize: $pageSize) {
    errors
    success
    totalPackages
    packages {
      packageName
      id
      eventLimit
      adminLimit
      cost
    }
  }
}
        `

    const ActionBarConfig: WidgetProps[] = [

        {
            type: "search-input",
            placeholder: "Search",
            onChange: onSearchTextChange,
            responsive: {
                xs: 12,
                sm: "auto"
            },
            sx: {
                order: {
                    xs: 2,
                    sm: 1
                }
            }
        },
        {
            type: "button",
            label: t(responsiveString({ xs: "Add", sm: "Add Package" })),
            startIcon: <Add />,
            onClick: () => {
                setAddModalOpen(true)
                setEditMode(false)
                setSelectedPackage(null)
            },
            sx: {
                order: {
                    xs: 1,
                    sm: 2
                }
            }
        },
    ]




    const onAddUserModalClose = () => {
        setAddModalOpen(false);
        handleRefetch()
    }


    return (
        <div>
            <TablePageHoc actions={ActionBarConfig} columns={TableConfigClubs} ref={childRef} query={QUERY} params={{
                search: searchText
            }}
                sourceAccessor={(data) => data.getAllPackages.packages} countAccessor={(data) => data.getAllPackages.totalPackages}
                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }}
                onRowClick={(row) => {
                    setPackageDetail(row)
                    setPackageDetailModal(true)
                }}
                detailPointer="pointer"
            />
            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedPackage ? "Edit Package" : "Add Package",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: <AddPackage evaluaPackage={selectedPackage} editMode={editMode} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddModalOpen(false)
                        }} />,
                    })
            }
            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the package')} ${selectedPackage?.packageName}? ${t('Once deleted, this action cannot be undone!')}`} />
            <PackageDetail modalOpen={PackageDetailModal} onClose={() => setPackageDetailModal(false)} detail={packageDetailInfo} />
        </div>
    )
}


export default PackageList