import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, TextField } from '@mui/material'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { gql, useQuery } from '@apollo/client';


const QUERY = gql`query CheckSubscription {
  checkSubscription {
    isPackageExpired
    success
    adminCount
    adminLimit
    eventCount
    eventLimit
    expiryDate
    package
  }
}`


const CustomDialog = (props: any) => {
    return <Dialog
        {...props}
        className={
            clsx({
                'sizeSmall': props.size == 'sm',
                'sizeLarge': props.size == 'lg',
                'sizeMedium': props.size == 'md',
                'sizeExtraLarge': props.size == 'xl'
            })
        }
    />
}



type ChildRef = {
    refetchData: (data: any) => void;
};



function SubscriptionDetailModal({ modalOpen, onClose }: any) {

    const { refetch: packageRefetch } = useQuery(QUERY, { skip: true });
    const [packageData, setPackageData]:any = useState({});

    useEffect(()=>{
        getPackageDetails()
    },[])

    const getPackageDetails = async ()=>{
        const { data } = await packageRefetch();
        console.log(data)
        setPackageData(data.checkSubscription);
        return data;
    }


    const onCloseClick = () => {
        onClose?.()
    }

    const { t } = useTranslation();

    console.log(packageData)

    return (
        <>
            <CustomDialog
                open={modalOpen ?? false}
                onClose={() => onCloseClick()}
                size='sm'
                sx={{ border: 0 }}

            >
                <DialogTitle variant='h6' color={"primary"} sx={{ textAlign: 'center' }}>
                    {packageData?.package}
                </DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12}>
                            <DialogContentText sx={{ "fontSize": "16px", "color": "#6b6868", "marginBottom": "5px" }}>
                                {t("Event : ")}<span style={{ "fontSize": "16px", 'fontWeight': "500", 'color': "#d6d0d0" }}>{packageData?.eventCount} {t("is used out of")} {packageData?.eventLimit}</span>
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12}>
                            <DialogContentText sx={{ "fontSize": "16px", "color": "#6b6868", "marginBottom": "5px" }}>
                                {t("Admin : ")}<span style={{ "fontSize": "16px", 'fontWeight': "500", 'color': "#d6d0d0" }}>{packageData?.adminCount} {t("is used out of")} {packageData?.adminLimit}</span>
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12}>
                            <DialogContentText sx={{ "fontSize": "16px", "color": "#6b6868", "marginBottom": "5px" }}>
                                {t("Free Evaluator")}
                            </DialogContentText>
                        </Grid>

                        <Divider />

                        <Grid item xs={12}>
                            <DialogContentText sx={{ "fontSize": "16px", "color": "#6b6868", "display": "flex", "gap": "5px", "alignItems": "center", "marginBottom": "5px" }}>
                                <><CheckBoxIcon style={{ color: 'green' }} /></>
                                <>{t("Player registeration")}</>
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12}>
                            <DialogContentText sx={{ "fontSize": "16px", "color": "#6b6868", "display": "flex", "gap": "5px", "alignItems": "center", "marginBottom": "5px" }}>
                                <><CheckBoxIcon style={{ color: 'green' }} /></>
                                <> {t("Download Evaluation file")}</>
                            </DialogContentText>
                        </Grid>

                        <Grid item xs={12}>
                            <DialogContentText sx={{ "fontSize": "16px", "color": "#6b6868" }}>
                                {t("Package Expiry : ")}<span style={{ "fontSize": "16px", "marginBottom": "10px", 'fontWeight': "500", 'color': "#d6d0d0" }}>{packageData?.expiryDate} </span>
                            </DialogContentText>
                        </Grid>

                    </Grid>
                </DialogContent>


            </CustomDialog>
        </>
    )
}

export default SubscriptionDetailModal