import React, { useEffect } from 'react'
import logo from '../../../assets/images/logo.svg'
import CenterPage from '../../../shared/hoc/reset-password/center-page.hoc'
import style from "./forgotPassword.module.scss"
import { useNavigate } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { stopPageLoading } from '../../../store/reducers/AppSlice';

export default function ForgotPasswordPage() {

    const SUBMIT_EMAIL_TO_GET_OTP = gql`
    mutation Mutation($email: String!) {
        verifyEmail(email: $email) {
          success
          key
          errors
          email
        }
      }
    
    `

    const [submitEmail, { data, loading, error }] = useMutation(SUBMIT_EMAIL_TO_GET_OTP);



    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(()=>{
        dispatch(stopPageLoading())
    },[])
  


    const onEvent = (event: any, data?: any) => {
        if (event === 'sign-in') {
            navigate('/');
        } else if (event === 'send-code') {
            verifyEmail(data);
        }
    }

    const verifyEmail = async (data: any) => {
        const res = await submitEmail({ variables: { email: data.email } })
        let key = res.data.verifyEmail.key
        let email = res.data.verifyEmail.email

        if (res.data.verifyEmail.success) {
            navigate(`/reset-password/${key}/${email}/verify`)
        } else {
            enqueueSnackbar(res.data.verifyEmail.errors[0], { variant: "error" });
        }


    }

    return (<div>
        <CenterPage
            logo={<img src={logo} className={style.logo} alt='' />}
            variant='forgot-password'
            onEvent={onEvent}
        />
    </div>)
}