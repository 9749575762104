import React, { useTransition, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Button, Divider, Grid } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';



const CREATE_CATEGORY_QUERY = gql`mutation Mutation($categoryName: String!) {
  createCategory(categoryName: $categoryName) {
    category {
      categoryName
      id
    }
    errors
    success
  }
}
  `

const UPDATE_CATEGORY_QUERY = gql`mutation UpdateOneCategory($updateOneCategoryId: ID!, $categoryName: String!) {
  updateOneCategory(id: $updateOneCategoryId, categoryName: $categoryName) {
    category {
      categoryName
      id
    }
    errors
    success
  }
}`


function AddCategory({ category, onSave, onClose, handleNext, editMode }: any) {

  const [createCategory] = useMutation(CREATE_CATEGORY_QUERY);
  const [updateCategory] = useMutation(UPDATE_CATEGORY_QUERY);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [resetField, setResetField] = useState<Function | undefined>(undefined);


  const handleSubmit = async (newCategory: any, callback: Function) => {


    if (category) {

      const variables = {
        updateOneCategoryId: category.id,
        categoryName: newCategory.categoryName,
      }

      try {
        const { data } = await updateCategory({
          variables: variables
        });

        if (data.updateOneCategory.errors?.length > 0) {
          console.error('Error:', data.updateOneCategory.errors);
          enqueueSnackbar(data.updateOneCategory.errors[0], { variant: "error" });
          callback();
        } else {
          enqueueSnackbar(t("Category updated successfully"), { variant: "success" });
          onSave();
        }
      } catch (error) {
        console.error('Mutation error:', error);
        callback();
      }

    } else {

      const variables = {
        categoryName: newCategory.categoryName,
      }
      try {
        const { data } = await createCategory({
          variables: variables
        });

        if (data.createCategory.errors?.length > 0) {
          console.error('Error:', data.createCategory.errors);
          enqueueSnackbar(data.createCategory.errors[0], { variant: "error" });
        } else {
          enqueueSnackbar(t("Category created successfully"), { variant: "success" });
          onSave();
        }
      } catch (error) {
        console.error('Mutation error:', error);
      }

    }
  };

  return (
    <div>
      <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
        <FormField name='categoryName' value={category?.categoryName ?? ""} validator={(value: string) => {
          if (!value) return "Category name is required."
          return null
        }} validateOnChange={false}>
          <TextField id="categoryName"
            label={t("Category Name")}
            variant="outlined"
            sx={{ mb: 10 }}
            fullWidth={true}
          />
        </FormField>
        <Divider sx={{ mx: -24, mb: 20 }} />
        <Grid container justifyContent={"flex-end"}>
          <Grid item xs={"auto"}>
            <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
          </Grid>
          <Grid item xs={"auto"}>
            {/* {editMode ? <Button type="submit" variant="contained" color="primary">{t('Save')}</Button> : <Button type="submit" variant="contained" color="primary">{t('Next')}</Button>} */}
            <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
          </Grid>
        </Grid>
      </FormGroup>
    </div>
  )
}

export default AddCategory