import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, TextField } from '@mui/material'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Add } from '@mui/icons-material';
import { getWidget } from '../../widgets';
import { ModalProps, ModalPropsBase } from '../../types/widget-types';
import styled from '@emotion/styled';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const CustomDialog = (props:any) => {
    return <Dialog 
    {...props}
    className={
        clsx({
            'sizeSmall': props.size == 'sm',
            'sizeLarge': props.size == 'lg',
            'sizeMedium': props.size == 'md'
        })
    }
    />
}

export interface ConfirmModalProps extends ModalPropsBase {
    onConfirm: () => void;
    children: string;
}



function ConfirmModal({ title, children, actions, size, modalOpen, onClose, onConfirm }: ConfirmModalProps) {


    const onCloseClick = () => {
        onClose?.()
    }

    const onConfirmClick = () => {
        onConfirm?.();
        onClose?.();
    }

    const {t} = useTranslation();
    

    return (
        <>
            <CustomDialog
                open={modalOpen ?? false}
                onClose={() => onCloseClick()}
                size={size ?? 'sm'}
                sx={{ border: 0 }}
        
            >
                <DialogTitle sx={{padding:'10px',textAlign:'right'}}> <CancelOutlinedIcon sx={{height:'25px',width:'25px','cursor':'pointer'}} onClick={onCloseClick}/></DialogTitle>
              
                <DialogTitle variant='h6' color={"primary"} textAlign={"center"} sx={{padding:"10px 20px 20px"}}>
                    {title}
                </DialogTitle>
                {/* <Divider/> */}
                <DialogContent>
                    <DialogContentText textAlign={"center"}>
                        {children}
                    </DialogContentText>
                    
                </DialogContent>
                {/* <Divider></Divider> */}
                <DialogActions sx={{display:"flex",justifyContent:"center", padding:'20px 24px 30px'}} >
                
                <Button autoFocus onClick={onCloseClick} color="secondary">
                    {t('Cancel')}
                </Button>
                <Button onClick={onConfirmClick} variant='contained' autoFocus>
                    {t('Confirm')}
                </Button>
                </DialogActions>
            </CustomDialog>
        </>
    )
}

export default ConfirmModal