import React, { useEffect, useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';



const GET_CLUBS_QUERY = gql`query Query($page: Int, $pageSize: Int) {
    getAllClubs(page: $page, pageSize: $pageSize) {
      clubs {
        clubAddress
        clubName
        contactNumber
        id
      }
      errors
      success
      totalClubs
    }
  }
  `

const GET_ASSOCIATIONS_QUERY = gql`query GetAllAssociations($clubId: Int, $search: String, $page: Int, $pageSize: Int) {
    getAllAssociations(clubId: $clubId, search: $search, page: $page, pageSize: $pageSize) {
      associations {
        associationName
        id
      }
      errors
      success
      totalAssociations
    }
  }`


const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from 
          to
        }
        success
      }
    }
  `

function PlayerFilter({ filterData, onFilterChange, onClose }: any) {

    const [resetForm, setResetForm] = useState<any>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [clubsData, setClubsData] = useState<any>(null);
    const [associationData, setAssociationData] = useState<any>(null);
    const [seasonPlayers, setSeasonPlayers] = useState<any>(null);
    const { t } = useTranslation();


    const { refetch: clubRefetch } = useQuery(GET_CLUBS_QUERY, { skip: true });
    const { refetch: associationFetch } = useQuery(GET_ASSOCIATIONS_QUERY, { skip: true });
    const { refetch: seasonfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });

    useEffect(() => {
    
        getSeasonPlayers();
        if (decodedTokenData.roles == 'SuperAdmin') {
            getClubData();
        }
        if (decodedTokenData.roles !== 'AssociationAdmin' || filterData?.clubId) {

            let clubId = filterData?.clubId ?? decodedTokenData.ClubId;
            if (clubId) {
                getAssociationData(parseInt(clubId))
            }
        }
    }, [decodedTokenData])


    const getClubData = async () => {
        const { data } = await clubRefetch({
            search: "",
            page: null,
            pageSize: null
        });
        setClubsData(data);
        return data;
    }

    const getSeasonPlayers = async () => {
        const { data } = await seasonfetch({
            search: "",
            page: null,
            pageSize: null
        });
        setSeasonPlayers(data);
        return data;
    }



    const getAssociationData = async (clubId: any) => {
        if (!clubId) return;
        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId
        });
        setAssociationData(data);
        return data;
    }






    const handleSubmit = async (user: any) => {

        const filters = {
            clubId: parseInt(user.clubId) || null,
            associationId: parseInt(user.associationId) || null,
            requestEventId: parseInt(user.requestEventId) || null
        };

        
        onFilterChange({
            submit: true,
            filters: filters,
        });

        onClose();

    };

    const extractYear = (timestamp: any) => {
        const date = new Date(parseInt(timestamp));
        return date.getFullYear();
    };

    return (
        <div>

            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetForm((field:string, value?:string) => resetField)}>
                <Box sx={{ width: '300px', padding: '8px 16px', display: 'flex', flexDirection: 'column' }}>

                    {
                        decodedTokenData.roles === 'SuperAdmin' &&
                        <FormField name='clubId' value={filterData?.clubId ?? ""}>
                            <TextField id="clubs"
                                label={t("Club")}
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                                onChange={(e: any) => {
                                    getAssociationData(parseInt(e.target.value))
                                }}

                            >
                                {clubsData ? clubsData.getAllClubs.clubs.map((club: any) => (
                                    <MenuItem key={"club" + club.id} value={club.id}>
                                        {club.clubName}
                                    </MenuItem>
                                )) : <MenuItem></MenuItem>}
                            </TextField>
                        </FormField>
                    }
                    {
                        decodedTokenData.roles !== 'AssociationAdmin' && decodedTokenData.roles !== 'Admin' && <FormField name='associationId' value={filterData?.associationId ?? ""}>
                            <TextField id="associations"
                                label={t("Association")}
                                select
                                variant="outlined"
                                fullWidth={true}
                                sx={{ mb: 10 }}
                            // onChange={(e: any) => {
                            //     associationFetch({
                            //         clubId: parseInt(e.target.value)
                            //     })
                            // }}
                            >
                                {associationData ? associationData.getAllAssociations.associations.map((association: any) => (
                                    <MenuItem key={"assoc" + association.id} value={association.id} >
                                        {association.associationName}
                                    </MenuItem>
                                )) : <MenuItem></MenuItem>}
                            </TextField>
                        </FormField>
                    }



                    <FormField name='requestEventId' value={filterData?.requestEventId ?? ""}>
                        <TextField id="season"
                            label={t("Season")}
                            select
                            variant="outlined"
                            fullWidth={true}
                            sx={{ mb: 10 }}
                        // onChange={(e: any) => {
                        //     associationFetch({
                        //         clubId: parseInt(e.target.value)
                        //     })
                        // }}
                        >
                            {seasonPlayers ? seasonPlayers.getRequestEvents.requestEvents.map((season: any) => (
                                <MenuItem key={"assoc" + season.id} value={season.id} >
                                    {extractYear(season.from)}-{extractYear(season.to)}

                                </MenuItem>
                            )) : <MenuItem></MenuItem>}
                        </TextField>
                    </FormField>
                    <Grid sx={{ alignSelf: 'end' }}>
                        <Button type="button" variant="text" onClick={() => {
                            resetForm('clubId');
                            resetForm('associationId');

                            { decodedTokenData.roles !== "ClubAdmin" && setAssociationData(null) }
                            let reqId = "";
                            if(seasonPlayers.getRequestEvents.requestEvents.length > 0) {
                                reqId = seasonPlayers.getRequestEvents.requestEvents[0]['id'];
                            }
                            resetForm('requestEventId', reqId);
                            onFilterChange({
                                submit: true,
                                filters: {
                                    role: null,
                                    clubId: null,
                                    associationId: null,
                                    requestEventId: reqId
                                },
                            });
                            // onClose();
                        }} color="primary">{t('Clear')}</Button>
                        <Button type="submit" variant="contained" color="primary">{t('Submit')}</Button>
                    </Grid>
                </Box>
            </FormGroup>

        </div>
    )
}

export default PlayerFilter