import { createContext, useContext, useEffect, useState } from "react";
import i18n from "../../assets/i18n/config";

// Create a context for language management
const LanguageContext = createContext<{
    language: string;
    switchLanguage: () => void;
  }>({
    language: 'en',
    switchLanguage: () => {},
  });

export const LanguageProvider = ({ children }:any) => {
    const [language, setLanguage] = useState('en');


  
    const switchLanguage = () => {
      setLanguage((prevLang) => (prevLang === 'en' ? 'de' : 'en'));
      const newLanguage = i18n.language === 'en' ? 'de' : 'en';
    i18n.changeLanguage(newLanguage);
    };
  
    // Detect keyboard shortcuts
    useEffect(() => {
        
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.altKey && e.code === 'KeyL') {

          switchLanguage();
        }
      };
  
      window.addEventListener('keydown', handleKeyDown);
  
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);
  
    return (
      <LanguageContext.Provider value={{ language, switchLanguage }}>
        {children}
      </LanguageContext.Provider>
    );
  };
  
  // A hook to use language context easily
  export const useLanguage = () => {
    return useContext(LanguageContext);
  };