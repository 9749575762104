import { configureStore } from '@reduxjs/toolkit'
import adminLayoutReducer from './reducers/AppSlice'
import AuthSlice from './reducers/AuthSlice'
// export default configureStore({
//   reducer: {
//     adminLayout: adminLayoutReducer,
//   },
// })

export const store =  configureStore({
  reducer: {
    app: adminLayoutReducer,
    auth: AuthSlice
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;

