import React,{useState} from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, TextField } from '@mui/material'
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import clsx from 'clsx';
import { FormField, FormGroup } from '../../utils/form-generator';
import PasswordField from '../../widgets/password/password';
import { useTranslation } from 'react-i18next'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { checkPassword } from '../../utils/util-functions';

const CustomDialog = (props: any) => {
    return <Dialog
        {...props}
        className={
            clsx({
                'sizeSmall': props.size == 'sm',
                'sizeLarge': props.size == 'lg',
                'sizeMedium': props.size == 'md'
            })
        }
    />
}


const CHANGE_PASSWORD_QUERY = gql`mutation ChangePassword($newPassword: String!, $confirmPassword: String!) {
    changePassword(newPassword: $newPassword, confirmPassword: $confirmPassword) {
      success
      errors
    }
  }`





function RestPasswordModal({ size, modalOpen, onClose }: any) {

    const [changePassword] = useMutation(CHANGE_PASSWORD_QUERY);
    const { enqueueSnackbar } = useSnackbar();
    const [resetField, setResetField] = useState<Function | undefined>(undefined);
    const dispatch = useDispatch();

    const onCloseClick = () => {
        onClose?.()
    }

    const {t} = useTranslation();



    const handleSetPassword = async (user: any,callback: Function) => {
        const variables = {
            newPassword: user.password,
            confirmPassword: user.confirmPassword,
        }
        
        if (user.password === user.confirmPassword) {
            const { data } = await changePassword({
                variables: variables
            });
            if (data.changePassword.errors?.length > 0) {
                enqueueSnackbar(data.changePassword.errors[0], { variant: "error" });
                callback();
            } else {
                enqueueSnackbar(t("Password reset successful"), { variant: "success" });
                onClose();
            }
        } else {
            enqueueSnackbar(t("Password mismatch , please enter the correct password"), { variant: "error" });
            callback();
        }

    }



    return (
        <CustomDialog
            open={modalOpen ?? false}
            onClose={() => onCloseClick()}
            size={size ?? 'sm'}
            sx={{ border: 0 }}

        >
            <FormGroup onSubmit={handleSetPassword} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <DialogTitle variant='h6' color={"primary"}>
                    {t('Change Password')}
                </DialogTitle>
                <Divider />
                <DialogContent sx={{pb:0,pt:"25px"}}>
                    <FormField name='password' validator={(value: string) => {
                        if (!value) return "Password is required."
                        let error = checkPassword(value);
                        if(error)return error;
                        return null
                    }} validateOnChange={false}>
                        <PasswordField
                            id="password"
                            label={t("New Password")}
                            wrapperSx={{ mb: 9 }}
                            variant="outlined"
                            fullWidth={true}
                        />
                    </FormField>
                    <FormField name='confirmPassword' validator={(value: string) => {
                        if (!value) return "Password is required."
                        let error = checkPassword(value);
                        if(error)return error;
                        return null
                    }} validateOnChange={false}>
                        <PasswordField
                            id="password"
                            label={t("Confirm Password")}
                            wrapperSx={{ mb: 9 }}
                            variant="outlined"
                            fullWidth={true}
                        />
                    </FormField>

                </DialogContent>
                <Divider ></Divider>

                <DialogActions>
                    <Button autoFocus onClick={onCloseClick} color="secondary">
                        {t('Cancel')}
                    </Button>
                    <Button type='submit' variant='contained' autoFocus>
                        {t('Confirm')}
                    </Button>
                </DialogActions>
            </FormGroup>
        </CustomDialog>

    )
}

export default RestPasswordModal